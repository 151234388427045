import styled from 'styled-components';

export const TableText = styled.div`
  color: ${(props) => (props.header ? '#0071FF' : '#303030')};
  font-size: 1em;
  font-weight: ${(props) => (props.header ? '500' : '300')};
  font-family: 'Kanit';
  font-size: 12px
  line-height: 20px;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
  text-align: left;
  text-decoration: ${(props) => (props.header ? 'underline' : 'none')};
`;
