import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Layout,
  Avatar,
  Progress,
  Calendar,
  message,
  Spin,
  Button,
  Divider,
} from 'antd';

import {
  ImageService,
  ProjectService,
  TimesheetService,
} from '../services/api';
import {
  ClockCircleFilled,
  FireFilled,
  CalendarFilled,
  SmileFilled,
  LeftOutlined,
  PlusOutlined,
  CalendarOutlined,
  InsertRowLeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { Select, Text } from '../components';
import { useLocation, useHistory } from 'react-router-dom';
import TaskModal from '../components/timesheet/taskModal';
import CreateOrEditTaskModal from '../components/timesheet/createOrEditTaskModal';
import CreateOrEditOtModal from '../components/timesheet/createOrEditOtModal';
import { AppContext } from '../components/context/AppContext';
import defaultProfileImage from '../assets/icons/profileimage.svg';
import TimesheetHourTable from '../components/timesheet/timesheetHourTable';
import { Segmented } from '../components/Segmented';
import { ReactComponent as AnnualLeaveIcon } from '../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../assets/icons/sickLeave.svg';
import { Circle } from '../components/Circle';

const { Content } = Layout;

const TimesheetDetail = (props) => {
  const { userState } = useContext(AppContext);
  const { isViewMode } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [data, setData] = useState();
  const [value, setValue] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateData, setSelectedDateData] = useState();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
  const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
  const [openCreateOtModal, setOpenCreateOtModal] = useState(false);
  const [openEditOtModal, setOpenEditOtModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const [selectMonth, setSelectMonth] = useState(moment().month() + 1);
  const [selectYear, setSelectYear] = useState(moment().year());
  const [today, setToday] = useState(moment());
  const [userImage, setUserImage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [timesheetMode, setTimesheetMode] = useState('calendar');
  const [project, setProject] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const id = new URLSearchParams(location.search).get('id');

  var jwt = require('jsonwebtoken');
  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = id ? id : decode1.userId;

  const handleFetchTimesheetDetail = async () => {
    const payload = {
      year: selectYear,
      month: selectMonth,
      userId: isViewMode ? id : null,
    };
    await TimesheetService.fetchTimesheetDetail(
      payload,
      (response) => {
        setData(response.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        message.error('Failed to fetch timesheet data: ' + error);
      },
    );
  };

  const fetchProjectOfUser = async () => {
    await ProjectService.fetchProjectListByMonth(
      { userId: userId, month: selectMonth, year: selectYear },
      async ({ data }) => {
        setProject(data);
      },
      (error) => {
        message.error('Failed to fetch project of user: ' + error);
      },
    );
  };

  useEffect(() => {
    handleFetchTimesheetDetail();
    fetchProjectOfUser();
  }, [userState]);

  //Handle redirect between user role and other role
  useEffect(() => {
    if (isMounted) {
      setIsLoading(true);
      history.push('/timesheet');
    } else setIsMounted(true);
  }, [userState]);

  const onSelect = async (newValue) => {
    setValue(newValue);
    setSelectedDate(moment(newValue));
    setSelectMonth(moment(newValue).month() + 1);
    setSelectYear(moment(newValue).year());
    const handleFetchChangeMonth = async () => {
      const payload = {
        year: moment(newValue).year(),
        month: moment(newValue).month() + 1,
        userId: id ?? null,
      };
      await TimesheetService.fetchTimesheetDetail(
        payload,
        (response) => {
          setData(response.data);
        },
        (error) => {
          message.error('Failed to fetch timesheet detail: ' + error);
        },
      );
      await ProjectService.fetchProjectListByMonth(
        {
          userId: userId,
          year: moment(newValue).year(),
          month: moment(newValue).month() + 1,
        },
        async ({ data }) => {
          setProject(data);
        },
        (error) => {
          message.error('Failed to fetch project of user: ' + error);
        },
      );
    };
    if (
      moment(newValue).month() + 1 != selectMonth ||
      moment(newValue).year() != selectYear
    ) {
      handleFetchChangeMonth();
      //setOpenViewModal(true);
    }
    const d = moment(newValue).format('D');
    const dateData = data ? data[`day${d}`] : undefined;
    setSelectedDateData(dateData);
  };

  function handleOpenCreateTaskModal() {
    setOpenCreateTaskModal(!openCreateTaskModal);
  }

  function handleOpenEditTaskModal() {
    setOpenEditTaskModal(!openEditTaskModal);
  }

  function handleOpenCreateOtModal() {
    setOpenCreateOtModal(!openCreateOtModal);
  }

  function handleOpenEditOtModal() {
    setOpenEditOtModal(!openEditOtModal);
  }

  function checkHoliday(date) {
    for (let i = 0; i < data?.holidays.length; i++) {
      if (
        moment(date, 'DD/MM/YYYY').date() === data?.holidays[i].day &&
        moment(date, 'DD/MM/YYYY').month() + 1 === data?.holidays[i].month &&
        moment(date, 'DD/MM/YYYY').year() === data?.holidays[i].year
      ) {
        return true;
      }
    }
    if (
      moment(date, 'DD/MM/YYYY').day() === 0 ||
      moment(date, 'DD/MM/YYYY').day() === 6
    ) {
      return true;
    }
    return false;
  }

  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const headerCalendarRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.months(current));
    }

    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i}>
          {months[i]}
        </Select.Option>,
      );
    }

    const year = value.year();
    const month = value.month();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>,
      );
    }
    return (
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        style={{ padding: 8 }}
      >
        <Col xs="auto">
          <Row gutter={[16, 16]}>
            <Col flex>
              <Button
                type="primary"
                className="button-primary"
                onClick={() => {
                  onChange(moment());
                }}
              >
                Today
              </Button>
            </Col>

            <LeftOutlined
              style={{ marginRight: 10 }}
              onClick={() => {
                onChange(value.clone().subtract({ months: 1 }));
              }}
            />

            <RightOutlined
              onClick={() => {
                onChange(value.clone().add({ months: 1 }));
              }}
            />

            <Col flex>
              <Select
                style={{ width: '120px' }}
                value={month}
                onChange={(newMonth) => {
                  const now = value.clone().month(newMonth);
                  onChange(now);
                }}
              >
                {monthOptions}
              </Select>
            </Col>
            <Col flex>
              <Select
                style={{ width: '80px' }}
                value={year}
                onChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
              >
                {options}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col flex>
          <Row gutter={[16, 16]}>
            <Col flex>
              <Text small12 className="text-normal">
                <Text sub4>{isViewMode ? data?.user?.firstnameEn : 'You'}</Text>{' '}
                have worked in this month :{' '}
              </Text>
            </Col>
            <Col flex>
              <Text
                sub4
                style={{
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                  marginRight: '6px',
                }}
              >
                <ClockCircleFilled
                  className="text-primary"
                  style={{
                    marginRight: '4px',
                    fontSize: '18px',
                  }}
                />
                {Math.floor(data?.totalWorkingMinutes / 60 > 0)
                  ? `${Math.floor(data?.totalWorkingMinutes / 60)} h`
                  : '0 h'}
                &nbsp;
                {Math.floor(data?.totalWorkingMinutes % 60 > 0)
                  ? `${Math.floor(data?.totalWorkingMinutes % 60)} m`
                  : ''}
              </Text>
              <Text
                sub4
                style={{
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                }}
              >
                <FireFilled
                  className="text-secondary-red"
                  style={{
                    marginRight: '4px',
                    fontSize: '18px',
                  }}
                />
                {Math.floor(data?.totalOtMinutes / 60 > 0)
                  ? `${Math.floor(data?.totalOtMinutes / 60)}h`
                  : '0 h'}
                &nbsp;
                {Math.floor(data?.totalOtMinutes % 60 > 0)
                  ? `${Math.floor(data?.totalOtMinutes % 60)}m`
                  : ''}
              </Text>
            </Col>
            <Divider
              type="vertical"
              style={{ borderLeft: '2px solid black', height: '25px' }}
            />
            <Col flex>
              <Text small12 className="text-normal">
                <Text sub4>{isViewMode ? data?.user?.firstnameEn : 'You'}</Text>{' '}
                have leaved in this month :{' '}
              </Text>
            </Col>
            <Col flex>
              <Text
                sub4
                style={{
                  whiteSpace: 'nowrap',
                  verticalAlign: 'middle',
                }}
              >
                <SmileFilled
                  className="text-secondary-orange"
                  style={{
                    marginRight: '4px',
                    fontSize: '18px',
                  }}
                />
                {Math.floor(data?.totalLeaveMinutes / 60 > 0)
                  ? `${Math.floor(data?.totalLeaveMinutes / 60)}h`
                  : '0 h'}
                &nbsp;
                {Math.floor(data?.totalLeaveMinutes % 60 > 0)
                  ? `${Math.floor(data?.totalLeaveMinutes % 60)}m`
                  : ''}
              </Text>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: 0 }} />
      </Row>
    );
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateFullCellRender = (value) => {
    const d = moment(value).format('D');
    const dateData = data
      ? data[`day${d}`].filter(
          (d) => d.type != 'totalLeave' && d.type != 'totalMinutes',
        )
      : null;
    const dateInformation = data
      ? data[`day${d}`].filter(
          (d) => d.type == 'totalLeave' || d.type == 'totalMinutes',
        )
      : null;
    const dateForCellRender =
      dateData?.length > 3 ? dateData?.slice(0, 2) : dateData;
    const dayOfWeek = moment(value).day();
    const isToday =
      moment(value).date() === today.date() &&
      moment(value).month() === today.month() &&
      moment(value).year() === today.year();
    if (
      moment(value).month() + 1 == selectMonth &&
      moment(value.year()) == selectYear
    ) {
      return (
        <div style={{ padding: '0px 8px' }}>
          <div
            style={{
              height: '160px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderTop: isToday ? '2px solid #6f6f6f' : '2px solid #e8e8eb',
              backgroundColor: isToday ? 'rgb(155,155,155, 0.15)' : undefined,
              padding: '8px 4px',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'default',
              }}
            >
              <Text
                h4
                className={
                  isToday
                    ? 'text-primary'
                    : checkHoliday(moment(value))
                    ? 'text-secondary-red'
                    : 'text-normal'
                }
              >
                {d}
              </Text>
            </div>
            <div
              style={{
                flex: 1,
                overflowY: 'hidden',
                overflowX: 'hidden',
                width: '100%',
                paddingTop: '4px',
              }}
              onClick={(e) => {
                setOpenViewModal(true);
              }}
            >
              <Row
                gutter={[4, 4]}
                style={{
                  overflow: 'hidden',
                }}
              >
                {dateForCellRender?.map((item, index) => {
                  if (item.type === 'holiday') {
                    return (
                      <div
                        key={'index' + index}
                        style={{
                          padding: '4px 8px',
                          background: '#D200EB',
                          display: 'flex',
                          justifyContent: 'start',
                          width: '100%',
                          borderRadius: '8px',
                        }}
                      >
                        <CalendarFilled
                          className="text-white"
                          style={{ marginRight: '4px' }}
                        />
                        <Text
                          small12
                          className="text-white"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.dateName}
                        </Text>
                      </div>
                    );
                  } else if (item.type === 'leave') {
                    return (
                      <div
                        key={'index' + index}
                        style={{
                          padding: '4px 8px',
                          background: '#F46F36',
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          width: '100%',
                          borderRadius: '8px',
                        }}
                      >
                        {item.leaveName == 'PERSONAL' ? (
                          <PersonalLeaveIcon
                            fill="white"
                            style={{ marginRight: 5 }}
                          />
                        ) : item.leaveName == 'ANNUAL' ? (
                          <AnnualLeaveIcon
                            fill="white"
                            style={{ marginRight: 5 }}
                          />
                        ) : (
                          <SickLeaveIcon
                            fill="white"
                            style={{ marginRight: 5 }}
                          />
                        )}
                        <Text
                          small12
                          className="text-white"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.leaveName} LEAVE
                        </Text>
                      </div>
                    );
                  } else if (item.type === 'task') {
                    return (
                      <div
                        key={'index' + index}
                        style={{
                          padding: '4px 8px',
                          backgroundColor: `${
                            item.otHolidayNonWorkingMinutes +
                              item.otHolidayWorkingMinutes +
                              item.otWorkingMinutes >
                            0
                              ? 'rgba(255, 228, 232,  1)'
                              : 'rgba(215, 237, 255, 1)'
                          }`,
                          display: 'flex',
                          justifyContent: 'start',
                          width: '100%',
                          borderRadius: '8px',
                        }}
                      >
                        <Text
                          h4
                          style={{
                            color: item.projectColorCode,
                            marginRight: '4px',
                            lineHeight: '12px',
                          }}
                        >
                          ●
                        </Text>
                        <Text
                          small12
                          className="text-normal"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.projectName}
                        </Text>
                      </div>
                    );
                  }
                })}
                {dateData?.length > 3 && (
                  <div
                    style={{
                      padding: '4px 8px',
                      display: 'flex',
                      justifyContent: 'start',
                      width: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <Text sub4>{dateData?.length - 2} more</Text>
                  </div>
                )}
              </Row>
            </div>
            <div style={{ paddingTop: '4px', width: '100%' }}>
              <Row
                gutter={[4, 4]}
                justify="end"
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  cursor: 'default',
                }}
              >
                {dateInformation?.map((item, index) => {
                  if (item.type === 'totalLeave') {
                    return (
                      <Col key={'index' + index}>
                        <Row gutter={[4, 4]}>
                          {item.totalLeaveMinutes > 0 ? (
                            <Col>
                              <Text
                                small12
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <SmileFilled
                                  className="text-secondary-orange"
                                  style={{
                                    marginRight: '4px',
                                    fontSize: '10px',
                                  }}
                                />
                                {Math.floor(item.totalLeaveMinutes / 60 > 0)
                                  ? `${Math.floor(
                                      item.totalLeaveMinutes / 60,
                                    )}h`
                                  : undefined}
                                &nbsp;
                                {Math.floor(item.totalLeaveMinutes % 60 > 0)
                                  ? `${Math.floor(
                                      item.totalLeaveMinutes % 60,
                                    )}m`
                                  : undefined}
                              </Text>
                            </Col>
                          ) : undefined}
                        </Row>
                      </Col>
                    );
                  }
                  if (item.type === 'totalMinutes') {
                    return (
                      <Col key={'index' + index}>
                        <Row gutter={[4, 4]}>
                          {item.totalWorkingMinutes > 0 ? (
                            <Col>
                              <Text
                                small12
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <ClockCircleFilled
                                  className="text-primary"
                                  style={{
                                    marginRight: '4px',
                                    fontSize: '10px',
                                  }}
                                />
                                {Math.floor(item.totalWorkingMinutes / 60 > 0)
                                  ? `${Math.floor(
                                      item.totalWorkingMinutes / 60,
                                    )}h`
                                  : undefined}
                                &nbsp;
                                {Math.floor(item.totalWorkingMinutes % 60 > 0)
                                  ? `${Math.floor(
                                      item.totalWorkingMinutes % 60,
                                    )}m`
                                  : undefined}
                              </Text>
                            </Col>
                          ) : undefined}
                          {item.totalOtMinutes > 0 ? (
                            <Col>
                              <Text
                                small12
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <FireFilled
                                  className="text-secondary-red"
                                  style={{
                                    marginRight: '4px',
                                    fontSize: '10px',
                                  }}
                                />
                                {Math.floor(item.totalOtMinutes / 60 > 0)
                                  ? `${Math.floor(item.totalOtMinutes / 60)}h`
                                  : undefined}
                                &nbsp;
                                {Math.floor(item.totalOtMinutes % 60 > 0)
                                  ? `${Math.floor(item.totalOtMinutes % 60)}m`
                                  : undefined}
                              </Text>
                            </Col>
                          ) : undefined}
                        </Row>
                      </Col>
                    );
                  }
                })}
              </Row>
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
      <Spin spinning={isLoading}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]} justify="space-between">
                <Col flex>
                  <Row gutter={[16, 16]}>
                    {isViewMode && (
                      <Col
                        flex
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        <LeftOutlined />
                      </Col>
                    )}
                    <Col flex>
                      <Text h4 className="text-normal">
                        Timesheet
                      </Text>
                    </Col>
                  </Row>
                </Col>
                {!isViewMode && (
                  <Col flex>
                    <Row gutter={[16, 16]}>
                      {timesheetMode === 'calendar' && (
                        <Col style={{ paddingRight: 0 }}>
                          <Button
                            className="button-red"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              setSelectedDate(moment());
                              handleOpenCreateOtModal();
                            }}
                          >
                            Create New OT
                          </Button>
                        </Col>
                      )}

                      {timesheetMode === 'calendar' && (
                        <Col style={{ paddingRight: 0 }}>
                          <Button
                            className="button-primary"
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={(e) => {
                              setSelectedDate(moment());
                              handleOpenCreateTaskModal();
                            }}
                          >
                            Create New Task
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            {isViewMode && (
              <Col span={24}>
                <Row
                  gutter={[16, 16]}
                  className="card-container"
                  align="middle"
                >
                  <Col flex>
                    <Circle
                      style={{ width: '96px', height: '96px' }}
                      src={
                        data?.user?.picturePath
                          ? data?.user?.picturePath
                          : defaultProfileImage
                      }
                      onError={(e) => {
                        e.target.src = defaultProfileImage;
                      }}
                    />
                  </Col>
                  <Col flex="auto">
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Text sub4 className="text-secondary-red">
                          {data?.user?.empCode}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text h4 className="text-normal">
                          {data?.user?.firstnameEn} {data?.user?.lastnameEn}
                        </Text>
                      </Col>
                      <Col flex>
                        <Text sub4 color="gray">
                          Department:{' '}
                        </Text>
                        <Text small12 className="text-normal">
                          {data?.user?.department}
                        </Text>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Text sub4 color="gray">
                          Position:{' '}
                        </Text>
                        <Text small12 className="text-normal">
                          {data?.user?.position}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex="0 1 auto" style={{ paddingLeft: 0 }}>
                  <Segmented
                    size="large"
                    options={[
                      {
                        value: 'calendar',
                        label: (
                          <div
                            style={{
                              width: '70px',
                              height: '65px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CalendarOutlined style={{ fontSize: '16px' }} />
                          </div>
                        ),
                      },
                      {
                        value: 'hour',
                        label: (
                          <div
                            style={{
                              width: '70px',
                              height: '65px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <InsertRowLeftOutlined
                              style={{ fontSize: '16px' }}
                            />
                          </div>
                        ),
                      },
                    ]}
                    value={timesheetMode}
                    onChange={(value) => {
                      setTimesheetMode(value);
                    }}
                  />
                </Col>
                <Col flex="1 1 auto">
                  <Row
                    gutter={[16, 4]}
                    className="card-container"
                    style={{ padding: '8px 8px', height: '70px' }}
                  >
                    <Col span={24}>
                      <Row gutter={[8, 8]}>
                        <Col>
                          <Text sub4 className="text-normal">
                            Progress
                          </Text>
                        </Col>
                        <Col>
                          {isViewMode ? undefined : (
                            <Text small12 className="text-normal">
                              {data?.percentTimesheetComplete * 100 >= 100
                                ? 'You did it! Your timesheet is complete'
                                : data?.percentTimesheetComplete * 100 >= 75
                                ? 'You are almost done with your timesheet!'
                                : data?.percentTimesheetComplete * 100 >= 50
                                ? 'Halfway through! High five yourself and keep going'
                                : data?.percentTimesheetComplete * 100 >= 25
                                ? 'Solid progress on timesheet! Keep it up'
                                : 'Just getting started'}
                            </Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Progress
                        percent={parseInt(data?.percentTimesheetComplete * 100)}
                        strokeColor={
                          data?.percentTimesheetComplete * 100 > 100
                            ? '#E18D10'
                            : data?.percentTimesheetComplete * 100 === 100
                            ? '#37BF4D'
                            : '#32429d'
                        }
                        strokeWidth={6}
                        strokeLinecap="butt"
                        status="active"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]}>
                {timesheetMode === 'calendar' && (
                  <Calendar
                    locale={{
                      lang: {
                        locale: 'en',
                        dayFormat: moment.updateLocale('en', {
                          weekdaysMin: [
                            'SUN',
                            'MON',
                            'TUE',
                            'WED',
                            'THU',
                            'FRI',
                            'SAT',
                          ],
                        }),
                      },
                    }}
                    style={{ padding: '16px' }}
                    value={value}
                    onSelect={onSelect}
                    dateFullCellRender={dateFullCellRender}
                    monthCellRender={monthCellRender}
                    headerRender={headerCalendarRender}
                  />
                )}
                {timesheetMode === 'hour' && project && (
                  <TimesheetHourTable
                    year={selectYear}
                    month={selectMonth}
                    setSelectYear={setSelectYear}
                    setSelectMonth={setSelectMonth}
                    dataProps={data}
                    project={project}
                    handleFetchTimesheetDetail={handleFetchTimesheetDetail}
                    fetchProjectOfUser={fetchProjectOfUser}
                    isAdmin={true}
                    isViewMode={isViewMode}
                    onSelect={onSelect}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Content>

        <TaskModal
          openViewModal={openViewModal}
          setOpenViewModal={setOpenViewModal}
          checkHoliday={checkHoliday}
          selectedDateData={selectedDateData}
          setSelectedTask={setSelectedTask}
          handleOpenCreateTaskModal={handleOpenCreateTaskModal}
          handleOpenEditTaskModal={handleOpenEditTaskModal}
          handleOpenCreateOtModal={handleOpenCreateOtModal}
          handleOpenEditOtModal={handleOpenEditOtModal}
          handleFetchTimesheetDetail={handleFetchTimesheetDetail}
          selectedDate={selectedDate}
          isViewMode={isViewMode}
        />

        {!isViewMode && (
          <CreateOrEditTaskModal
            openCreateTaskModal={openCreateTaskModal}
            openEditTaskModal={openEditTaskModal}
            handleOpenCreateTaskModal={handleOpenCreateTaskModal}
            handleOpenEditTaskModal={handleOpenEditTaskModal}
            selectedTask={selectedTask}
            selectedDate={selectedDate}
            handleFetchTimesheetDetail={handleFetchTimesheetDetail}
            isViewMode={isViewMode}
          />
        )}

        {!isViewMode && (
          <CreateOrEditOtModal
            openCreateOtModal={openCreateOtModal}
            openEditOtModal={openEditOtModal}
            handleOpenCreateOtModal={handleOpenCreateOtModal}
            handleOpenEditOtModal={handleOpenEditOtModal}
            selectedTask={selectedTask}
            selectedDate={selectedDate}
            handleFetchTimesheetDetail={handleFetchTimesheetDetail}
            checkHoliday={checkHoliday}
            isViewMode={isViewMode}
          />
        )}
      </Spin>
    </Layout>
  );
};

export default TimesheetDetail;
