import React, {
  Component,
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Divider, message, Select, Button, Modal } from 'antd';
import { ReferenceLetterService } from '../../services/api';
import moment from 'moment';

import { Text, Input, DatePicker, FormInput } from '../../components';
import { AppContext } from '../context/AppContext';
import { FeatureFlags } from '../context/FeatureFlagContext';
import { useForm } from 'antd/lib/form/Form';

const RequestReferenceLetterModal = (props) => {
  const { open, onCancel, onFetch } = props;
  const [form] = useForm();

  const [referenceLetterType, setReferenceLetterType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReferenceLetterId, setSelectedReferenceLetterId] = useState();
  const [remark, setRemark] = useState();

  useEffect(() => {
    fetchReferenceLetterType();
  }, []);

  const fetchReferenceLetterType = () => {
    ReferenceLetterService.getReferenceLetterType(
      ({ data }) => {
        setReferenceLetterType(data);
        setIsLoading(false);
      },
      (response) => {
        setIsLoading(false);
        if (response) message.error(`Error: ${response.data.message}`);
      },
    );
  };

  const onChangeReferenceLetterType = (index) => {
    const record = referenceLetterType[index];
    setSelectedReferenceLetterId(record.id);
    setRemark(record.remark);
  };

  const onFinishReferenceLetterRequest = (value) => {
    setIsLoading(true);
    value.payslipTypeId = selectedReferenceLetterId;
    if (value.dateRange) {
      value.startDate = moment(value.dateRange[0]).format('YYYY-MM-DD');
      value.endDate = moment(value.dateRange[1]).format('YYYY-MM-DD');
    }
    ReferenceLetterService.addReferenceLetterRequest(
      value,
      ({ data }) => {
        setIsLoading(false);
        message.success(
          'Reference letter request has been created successfully.',
        );
        setTimeout(() => {
          onFetch();
        }, 1000);
        form.resetFields();
        onCancel();
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(
            `Failed to create reference letter request: ${response.data.message}`,
          );
      },
    );
  };

  return (
    <Modal
      width="450px"
      bodyStyle={{
        maxHeight: '800px',
        overflowY: 'auto',
      }}
      open={open}
      footer={
        <Row gutter={[16, 16]} style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isLoading}
            className="button-outlined"
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            className="button-primary"
            type="primary"
            onClick={() => form.submit()}
          >
            Submit
          </Button>
        </Row>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      maskClosable
    >
      <Form form={form} onFinish={onFinishReferenceLetterRequest}>
        <Row>
          <Text h4 className="text-normal">
            Create Request
          </Text>
        </Row>
        <Divider style={{ margin: '12px 0' }} />
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FormInput
              title="Reference Letter"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="index"
                  rules={[
                    {
                      required: true,
                      message: 'Please select reference letter',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select reference letter"
                    onChange={onChangeReferenceLetterType}
                  >
                    {referenceLetterType.map((type, index) => (
                      <Select.Option value={index}>
                        {type.payslipName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
            />
          </Col>
          {remark === 'VISA' && (
            <Col span={24}>
              <FormInput
                title="Country"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your country',
                      },
                    ]}
                  >
                    <Input placeholder="Country" />
                  </Form.Item>
                }
              />
            </Col>
          )}
          {remark === 'VISA' && (
            <Col span={24}>
              <FormInput
                title="Pick Date"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="dateRange"
                    rules={[
                      {
                        required: true,
                        message: 'Please input leave date.',
                      },
                    ]}
                  >
                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                  </Form.Item>
                }
              />
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default RequestReferenceLetterModal;
