import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { ProfileService } from '../../services/api';
import { message } from 'antd';
import { FormInput, Text } from '../../components';

const ChangePasswordModal = ({ visible, closeModal }) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePasswordSubmit = (payload) => {
    setIsLoading(true);
    ProfileService.personalDetail.changePassword(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('Your password has been changed successfully');
        form.resetFields();
        closeModal();
      },
      (response) => {
        setIsLoading(false);
        if (response && response.status === 400) {
          message.error('Failed to change password: ' + response.data);
        } else {
          message.error('Failed to change password');
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={visible}
      onCancel={closeModal}
      centered
      footer={
        <Col span={24}>
          <Row gutter={[16, 16]} justify="end">
            <Col>
              <Button
                className="button-outlined"
                onClick={() => {
                  form.resetFields();
                  closeModal();
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      }
    >
      <Form
        form={form}
        name="changePasswordForm"
        onFinish={handleChangePasswordSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              Change Password
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 16]} style={{ width: '100%', margin: 0 }}>
            <Col span={24}>
              <FormInput
                title="Old Password"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Enter your old password',
                      },
                    ]}
                  >
                    <Input.Password id="oldPassword" />
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="New Password"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Enter your new password',
                      },
                      {
                        min: 8,
                        message: 'Password must be at least 8 characters.',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password id="newPassword" />
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Confirm Password"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Confirm your new password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('newPassword') === value
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error('The passwords do not match'),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password id="confirmPassword" />
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
