import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { Table } from '../Table';
import { Button, Col, Row } from 'antd';
import { Text } from '../Text';
import moment from 'moment';
import TrainingCourseModal from './modal/trainingCourseModal';
import { AppContext } from '../context/AppContext';

const TrainingCourseInformation = (props) => {
  const { trainingCourse, userId, getTrainingCourses } = props;

  const { userState } = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [record, setRecord] = useState();

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const openTrainingCourseModal = (record) => {
    setRecord(record);
    setModalVisible(true);
  };

  const closeTrainingCourseModal = () => {
    setRecord(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Course Name',
      index: 'courseName',
      dataIndex: 'courseName',
      width: '200px',
      sorter: {
        compare: (a, b) => a.courseName.localeCompare(b.courseName),
      },
      render: (courseName) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {courseName}
          </Text>
        </div>
      ),
    },
    {
      title: 'Institution/Instructor',
      index: 'institution',
      dataIndex: 'institution',
      width: '200px',
      sorter: {
        compare: (a, b) => a.institution.localeCompare(b.institution),
      },
      render: (institution) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {institution}
          </Text>
        </div>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: '140px',
      sorter: defaultStringCompare('startDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: '140px',
      sorter: defaultStringCompare('endDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) => (
        <a onClick={() => openTrainingCourseModal(record)}>
          {userState.currentRoleId === 2 ? (
            <EditOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]} className="card-container">
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Text sub2 className="text-normal">
                Training Courses
              </Text>
              {userState.currentRoleId === 2 && (
                <Button
                  type="primary"
                  className="button-primary"
                  width="149px"
                  icon={<PlusOutlined />}
                  onClick={openTrainingCourseModal}
                >
                  Create Training Course
                </Button>
              )}
            </Row>
          </Col>
          <Col span={24} style={{ padding: 0 }}>
            <Table
              columns={columns}
              dataSource={trainingCourse.trainingCourses}
              rowKey="courseName"
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Col>
      <TrainingCourseModal
        getTrainingCourses={getTrainingCourses}
        userId={userId}
        modalVisible={modalVisible}
        closeModal={closeTrainingCourseModal}
        record={record}
      />
    </Row>
  );
};
export default TrainingCourseInformation;
