import { Layout, Row, Col, message, Button, Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Table, Text } from '../../components';
import { RoleService } from '../../services/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../../components/context/AppContext';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';

const { Content } = Layout;

const UserRole = () => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [userCurrentRoleId, setUserCurrentRoleId] = useState(
    userState.currentRoleId,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [userRoleData, setUserRoleData] = useState();
  const history = useHistory();

  const fetchAllUserRole = () => {
    setIsLoading(true);
    RoleService.getAllRole(
      ({ data }) => {
        setUserRoleData(data);
        setIsLoading(false);
      },
      (response) => {
        message.error(
          'Failed to fetch user role data:' + response.data.message,
        );
        setIsLoading(false);
      },
    );
  };

  const handleDeleteRole = (roleId) => {
    setIsLoading(true);
    RoleService.deleteRole(
      roleId,
      ({ data }) => {
        message.success('Role has been deleted successfully.');
        fetchAllUserRole();
        setIsLoading(false);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Failed to delete role: ' + response.data.message);
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    fetchAllUserRole();
  }, [userCurrentRoleId]);

  useEffect(() => {
    setUserCurrentRoleId(userState.currentRoleId);
  }, [userState]);

  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      index: 'name',
      width: '300px',
      render: (value, record) => {
        if (features?.roleEdit) {
          return (
            <a
              onClick={() =>
                history.push('/role/roleForm/?roleId=' + record.id)
              }
            >
              <Text sub4 capitalize underline className={'text-primary'}>
                {value}
              </Text>
            </a>
          );
        } else {
          return (
            <Text sub4 capitalize className={'text-primary'}>
              {value}
            </Text>
          );
        }
      },
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) =>
        features?.roleDelete &&
        ![1, 2].includes(record.id) && (
          <>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this role? This action can't be undone."
              okButtonProps={{
                style: {
                  backgroundColor: '#004368',
                  borderColor: '#2d95a8',
                },
              }}
              onConfirm={() => {
                handleDeleteRole(record.id);
              }}
              onCancel={() => {}}
            >
              <DeleteOutlined className="text-secondary-red" />
            </Popconfirm>
          </>
        ),
    },
  ];

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Role & Permission
                  </Text>
                </Col>
                {features?.roleCreate && (
                  <Col flex="auto">
                    <Row gutter={[8, 8]} justify="end">
                      <Col flex>
                        <NavLink to={`./role/roleForm`}>
                          <Button
                            type="primary"
                            width="96px"
                            className="button-primary"
                            icon={<PlusOutlined />}
                          >
                            Create Role
                          </Button>
                        </NavLink>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={userRoleData}
                scroll={{ x: 500, y: 'calc(100vh - 240px)' }}
                rowKey="id"
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};
export default UserRole;
