import styled from 'styled-components';

export const Circle = styled.img`
  margin-top: ${(props) => (props.small ? '0' : '-2.563rem;')};
  margin-top: auto;
  background: ${(props) =>
    props.small ? (props.src ? 'url(' + props.src + ')' : 'grey') : 'white'};
  background-repeat: no-repeat;
  background-size: 9.313rem;
  background-size: 96px;
  border-radius: 50%;
  height: ${(props) => (props.small ? '9.313rem' : '10.188rem')};
  width: ${(props) => (props.small ? '9.313rem' : '10.188rem')};
  height: 96px;
  width: 96px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
