import { ImageService } from '../services/api';
import profileimage from '../assets/icons/profileimage.svg';

export const getUserPic = async (record) => {
  try {
    // User Profile Image
    await ImageService.image.getImage(record.userInfo.picturePath, (res) => {
      if (!res.status) {
        const url = URL.createObjectURL(res.data);
        record.userImage = url;
      } else {
        record.userImage = profileimage;
      }
    });

    // Admin Profile Image
    await ImageService.image.getImage(
      record.updateUserInfo.picturePath,
      (res) => {
        if (!res.status) {
          const url = URL.createObjectURL(res.data);
          record.updateUserImage = url;
        } else {
          record.updateUserImage = profileimage;
        }
        return record;
      },
    );
  } catch (err) {
    console.log(err);
    return record;
  }
};

export const getCertificatePic = async (record) => {
  ImageService.image.getImage(
    record.certificatePicturePath,
    (res) => {
      if (!res.status) {
        const url = URL.createObjectURL(res.data);
        record.certificatePictureUrl = url;
      } else {
        record.certificatePictureUrl = profileimage;
      }
      return record;
    },
    (response) => {
      console.log(response);
      return record;
    },
  );
};

export const getMedicalClaimPic = async (path) => {
  try {
    let url;
    await ImageService.image.getImage(
      path,
      (res) => {
        if (!res.status) {
          url = URL.createObjectURL(res.data);
        }
      },
      (response) => {
        console.log(response);
      },
    );
    return url;
  } catch (err) {
    console.log(err);
    return;
  }
};
