import React, { Component } from 'react';
import { Row, Col } from 'antd';

import { Text } from './';

function FormInput(props) {
  const { title, required, component, white } = props;

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        {white ? (
          <Text sub4 className="text-white">
            {title}
          </Text>
        ) : (
          <Text sub4 className="text-normal">
            {title}
          </Text>
        )}
        {required && (
          <Text sub4 className="text-require">
            *
          </Text>
        )}
      </Col>
      <Col span={24}>{component}</Col>
    </Row>
  );
}

export default FormInput;
