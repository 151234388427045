import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { Table } from '../Table';
import { Button, Col, Row } from 'antd';
import { Text } from '../Text';
import moment from 'moment';
import SkillModal from './modal/skillModal';
import { AppContext } from '../context/AppContext';

const SkillInformation = (props) => {
  const { skill, userId, getSkills } = props;

  const { userState } = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [record, setRecord] = useState();

  const openSkillModal = (record) => {
    setRecord(record);
    setModalVisible(true);
  };

  const closeSkillModal = () => {
    setRecord(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Skill',
      index: 'skillType',
      dataIndex: 'skillType',
      sorter: {
        compare: (a, b) => a.skillType.localeCompare(b.skillType),
      },
      render: (skillType) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text small12 className="text-normal">
            {skillType}
          </Text>
        </div>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) => (
        <a onClick={() => openSkillModal(record)}>
          {userState.currentRoleId === 2 ? (
            <EditOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]} className="card-container">
          <Col span={24}>
            <Row gutter={[16, 16]} justify="space-between">
              <Text sub2 className="text-normal">
                Skills
              </Text>
              {userState.currentRoleId === 2 && (
                <Button
                  type="primary"
                  className="button-primary"
                  width="149px"
                  icon={<PlusOutlined />}
                  onClick={openSkillModal}
                >
                  Create Skill
                </Button>
              )}
            </Row>
          </Col>
          <Col span={24} style={{ padding: 0 }}>
            <Table
              columns={columns}
              dataSource={skill.computerSkills}
              rowKey="skillType"
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Col>
      <SkillModal
        getSkills={getSkills}
        userId={userId}
        modalVisible={modalVisible}
        closeModal={closeSkillModal}
        record={record}
      />
    </Row>
  );
};
export default SkillInformation;
