import styled from 'styled-components';
import { Table as AntdTable } from 'antd';

export const Table = styled(AntdTable)`
  .ant-table-thead .ant-table-cell {
    font-family: Figtree, ' Noto Sans Thai ', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }

  .ant-pagination-item-active {
    font-family: Figtree, ' Noto Sans Thai ', sans-serif;
    font-weight: 400;
    font-size: 12px;
    border: 1px solid #f4364c;
  }

  .ant-pagination-item-active a {
    font-family: Figtree, ' Noto Sans Thai ', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #f4364c;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 5px 8px;
  }
`;
