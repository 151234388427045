import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Card,
  Modal,
  Image,
} from 'antd';
import fileImage from '../../assets/images/fileimg.png';
import { LoadingOutlined, PlusOutlined, ApiFilled } from '@ant-design/icons';
import Delete from '../../assets/icons/delete.svg';
import Uploader from '../components/uploader.js';
import { ProfileService } from '../../services/api';
import { ImageService } from '../../services/api';
import NoImage from '../../assets/images/nonuploadimage.png';
import { Document } from 'react-pdf';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';

const MainBox = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
const Subbox = styled.div`
  // height: 6.1875rem;
  // border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Flexbox = styled.div`
  height: 100%;
  width: 100%;
  // background: ${(props) =>
    props.one ? 'blue' : props.two ? 'red' : 'yellow'};
  display: ${(props) => (props.one ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-content: ${(props) => (props.one ? 'center' : 'none')};
  justify-content: ${(props) => (props.one ? 'none' : 'center')};
  box-sizing: border-box;
  border-bottom: ${(props) => (props.one ? 'none' : '1px solid #ECECEC')};
  flex-direction: ${(props) => (props.one ? 'row' : 'column')};
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  position: relative;
`;
const Bluecircle = styled.div`
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) =>
    props.small
      ? 'white'
      : 'linear-gradient(90deg, #013640 -69.09%, #2A8B9D 111.79%)'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;
const Greycircle = styled.div`
  height: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  width: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  border-radius: 50%;
  background: ${(props) => (props.small ? 'white' : '#D2D2D2')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.small ? 'none' : '0.1921875rem')};
`;
const Verticalline = styled.div`
  position: absolute;
  height: 5rem;
  margin-top: -2.3rem;
  margin-left: 0.9375rem;
  width: 0.125rem;
  background: #e1e1e1;
`;

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.card ? 'row' : 'column')};
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 712px) {
    flex-direction: column;
  }
`;
const Flexformbox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Label = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 12px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Formitem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Headdetail = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
`;
const Text = styled.div`
  font-size: ${(props) => (props.big ? '1rem' : '0.8750rem')};
  font-weight: ${(props) => (props.big ? '500' : '300')};
  color: ${(props) =>
    props.big ? '#232323' : props.medium ? '#888888' : '#737373'};
`;

const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 12px;
`;

const CurrentWork = () => {
  return (
    <Subbox>
      <Flexbox one>
        <Bluecircle>
          <Bluecircle small></Bluecircle>
        </Bluecircle>
      </Flexbox>
      <Flexbox two></Flexbox>
      <Flexbox three></Flexbox>
    </Subbox>
  );
};

const Workex = () => {
  return (
    <div>
      <Verticalline></Verticalline>
      <Subbox>
        <Flexbox one>
          <Greycircle>
            <Greycircle small></Greycircle>
          </Greycircle>
        </Flexbox>
        <Flexbox two></Flexbox>
        <Flexbox three></Flexbox>
      </Subbox>
    </div>
  );
};

class Certificate extends Component {
  state = {
    certList: this.props.data.certificate?.certificates,
    certListForShow: this.props.data.certificate?.certificates,
    deletedList: [],
    // isInEditMode: false,
    fd: {},
  };
  formRef = React.createRef();
  getImage = async (res) => {
    const data = new Uint8Array(res.data);

    const rawChunks = [];
    const chunkSize = 0x8000;
    for (let i = 0; i < data.length; i += chunkSize) {
      rawChunks.push(
        String.fromCharCode.apply(null, data.subarray(i, i + chunkSize)),
      );
    }

    const raw = rawChunks.join('');
    const base64 = btoa(raw);
    return 'data:image;base64,' + base64;
  };

  addNewRow = () => {
    this.setState((prevState) => ({
      certList: prevState.certList
        ? [
            ...prevState.certList,
            {
              certificateName: '',
              certificateDate: '',
              certificateImage: null,
            },
          ]
        : [],
    }));
  };

  clickOnDelete(record) {
    this.setState({
      certList: this.state.certList.filter((r) => r !== record),
      deletedList: [...this.state.deletedList, record.id],
    });
  }

  onFinish = (values) => {
    this.props.onSubmit(values, this.state.certList, this.state.deletedList);
    this.props.changeEditMode();
    this.setState({ fd: [] });
  };
  handlePicture = (fd, index) => {
    let new_fd = [...this.state.certList];
    let form = this.formRef.current.getFieldsValue();
    form.certificateList[index].certificatePicturePath = fd;
    new_fd[index].certificatePicturePath = fd;
    this.formRef.current.setFieldsValue(form);
    this.setState({ certList: new_fd });
  };
  componentDidMount = () => {
    if (this.props.data.certificate?.certificates) {
      // this.fetchImage();
      // console.log(this.props.data.certificate?.certificates);
    }
  };
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.data.certificate === this.props.data.certificate?.certificates
    )
      return;
    if (this.props.data.certificate?.certificates) {
      //   this.fetchImage();
    }
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        certList: this.props.data.certificate?.certificates,
        certListForShow: this.props.data.certificate?.certificates,
      });
    }
  };
  // fetchImage = async () => {
  //   let arr = this.props.data.certificate?.certificates;
  //   for (let i = 0; i < arr.length; i++) {
  //     if (arr[i].certificatePicturePath) {;
  //       ImageService.image.getImage(
  //         arr[i].certificatePicturePath,
  //         (res, index) => {
  //           if (res.status) return false;
  //           const blobUrl = URL.createObjectURL(res.data);
  //           let new_fd = [...this.state.picList];
  //           new_fd[index] = blobUrl;
  //           this.setState({ picList: new_fd });
  //         }
  //       );
  //     }
  //   }
  // };
  onShowFullImage = async (event, picturePath) => {
    if (picturePath.search('.pdf') >= 0) {
      await ImageService.image.getImage(picturePath, (res) => {
        if (res.status) return false;
        const url = URL.createObjectURL(res.data);
        this.setState({
          fullImageUrl: url,
          fullImageVisible: true,
          isFullImagePdf: true,
        });
      });
    } else
      this.setState({
        fullImageUrl: event.target.src,
        fullImageVisible: true,
        isFullImagePdf: false,
      });
  };
  onCloseImage = () => {
    this.setState({
      fullImageUrl: '',
      fullImageVisible: false,
      isFullImagePdf: false,
    });
  };
  renderEditView = () => {
    return (
      <MainBox>
        <Form
          id="certificates"
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.List name="certificateList">
            {(fields, { add, remove }) => {
              fields = [];
              let array = this.state.certList ? this.state.certList : [];
              if (array.length >= 0) {
                for (var i = 0; i < array.length; i++) {
                  fields.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                  });
                }
              }
              let removefunc = (a, b) => {
                this.clickOnDelete(a);
                remove(b);
              };
              return (
                <div>
                  {fields?.map((field) => (
                    <MainBox>
                      <Box>
                        <Row gutter={24} style={{ marginBottom: '1rem' }}>
                          <Col span={12}>
                            <div
                              style={{ color: 'black', fontSize: '1.125em' }}
                            >
                              {field.fieldKey + 1}.
                            </div>
                          </Col>

                          <Col span={12} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '0',
                                display: 'flex',
                              }}
                            >
                              <Flexformbox style={{ width: '100%' }}>
                                <Button
                                  style={{
                                    color: '#EB5757',
                                    fontSize: '0.8750em',
                                    border: 'none',
                                  }}
                                  onClick={() => {
                                    removefunc(
                                      array[field.fieldKey],
                                      field.name,
                                    );
                                  }}
                                >
                                  <img src={Delete}></img>&nbsp;Delete
                                </Button>
                              </Flexformbox>
                            </div>
                          </Col>
                        </Row>

                        <Formitem
                          {...field}
                          name={[field.name, 'index']}
                          fieldKey={[field.fieldKey, 'index']}
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          initialValue={array[field.fieldKey].index}
                        ></Formitem>

                        <Horizontalbox>
                          <Formitem
                            hidden
                            {...field}
                            name={[field.name, 'certificateId']}
                            fieldKey={[field.fieldKey, 'id']}
                            initialValue={array[field.fieldKey].id}
                          ></Formitem>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Certificate Name</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'certificateName']}
                              fieldKey={[field.fieldKey, 'certificateName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Certificate Name',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].certificateName
                              }
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Certificate Date</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'certificateDate']}
                              fieldKey={[field.fieldKey, 'certificateDate']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Certificate Date',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].certificateDate == ''
                                  ? ''
                                  : moment(
                                      array[field.fieldKey].certificateDate,
                                      'YYYY-MM-DD',
                                    )
                              }
                            >
                              <Datepicker format="DD/MM/YYYY" />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox right>
                            <Label>Certificate Image</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'certificatePicturePath']}
                              fieldKey={[
                                field.fieldKey,
                                'certificatePicturePath',
                              ]}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Certificate Image',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].certificatePicturePath
                              }
                            >
                              <Uploader
                                name="certificatePicturePath"
                                initialPath={
                                  this.state.certList[field.fieldKey]
                                    .certificatePicturePath
                                }
                                callbackUpload={this.handlePicture}
                                index={field.fieldKey}
                              />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>
                        <Line></Line>
                      </Box>
                    </MainBox>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={this.addNewRow}>
                      Create Certificates
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    return (
      <MainBox>
        <Box card>
          {this.props.data.certificate?.certificates?.map((val, idx) => {
            return (
              <div
                style={{ display: !!val.certificateName ? 'block' : 'none' }}
              >
                <Card
                  hoverable
                  style={{
                    width: '15rem',
                    marginLeft: '1rem',
                    marginBottom: '1.5rem',
                    borderRadius: '0.5rem',
                  }}
                  cover={
                    <CardCover src={val.certificatePicturePath || NoImage} />
                  }
                  onClick={(event) =>
                    this.onShowFullImage(event, val.certificatePicturePath)
                  }
                >
                  <Card.Meta
                    style={{ overflow: 'visible', whiteSpace: 'inherit' }}
                    title={<Text big>{val.certificateName}</Text>}
                    description={
                      <Text>
                        {' '}
                        {moment(val.certificateDate, 'YYYY-MM-DD').format(
                          'DD MMM YYYY',
                        )}
                      </Text>
                    }
                  ></Card.Meta>
                </Card>
              </div>
            );
          })}
        </Box>
        {this.props.data.certificate?.certificates?.length > 0 ? (
          <Line style={{ marginTop: '1rem' }}></Line>
        ) : (
          <></>
        )}

        <Horizontalbox /* style={{ marginTop: '1rem' }} */>
          <FlexBox Left style={{ marginBottom: 0 }}>
            <Label>Update Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateDatetime"
            >
              <Text>
                {this.props.data.certificate?.updateUserInfo?.updateDatetime
                  ? moment(
                      this.props.data.certificate?.updateUserInfo
                        ?.updateDatetime,
                      'YYYY-MM-DD',
                    ).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
          <FlexBox style={{ marginBottom: 0 }}>
            <Label>Update By</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateUserInfo"
            >
              <Text>
                {this.props.data.certificate?.updateUserInfo?.updateDatetime
                  ? `${this.props.data.certificate?.updateUserInfo?.firstnameEn} ${this.props.data.certificate?.updateUserInfo?.lastnameEn}`
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
        </Horizontalbox>
        <Modal
          visible={this.state.fullImageVisible}
          onCancel={this.onCloseImage}
          footer={false}
          bodyStyle={{
            width: '500px',
            height: '500px',
          }}
          width={'500px'}
          height={'auto'}
        >
          {this.state.isFullImagePdf ? (
            <iframe
              width={'100%'}
              height={'100%'}
              src={this.state.fullImageUrl}
            />
          ) : (
            <Image width={'100%'} src={this.state.fullImageUrl}></Image>
          )}
        </Modal>
      </MainBox>
    );
  };

  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}

class CardCover extends Component {
  state = { url: null };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.src != prevProps.src) {
      this.fetchImage();
    }
  };
  componentDidMount = () => {
    if (this.props.src) {
      this.fetchImage();
    }
  };
  fetchImage = async () => {
    let path = this.props.src;
    if (path?.search('.pdf') >= 0) {
      const url = fileImage;
      this.setState({ url });
    } else if (path) {
      ImageService.image.getImage(path, (res) => {
        if (res.status) return false;
        const url = URL.createObjectURL(res.data);
        this.setState({ url });
      });
    } else {
      this.setState({ path: null });
    }
  };

  render() {
    return (
      <>
        {this.state.url ? (
          <img
            src={this.state.url}
            alt="example"
            style={{
              height: '10rem',
              backgroundColor: 'grey',
              border: 'none',
            }}
          />
        ) : (
          <div style={{ height: '10rem', backgroundColor: 'grey' }}></div>
        )}
      </>
    );
  }
}
export default Certificate;
