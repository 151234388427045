import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, message, Popover, Popconfirm, Layout } from 'antd';
import { MasterDataService } from '../../services/api';
import { PlusOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';

import CreateOrEditSettingsModal from '../../components/settings/createOrEditSettingModal';

import { Table, Select, Tag, Text } from '../../components';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';
const { Content } = Layout;

const Settings = () => {
  const { features } = useContext(FeatureFlags);
  const [createOrEditModalVisible, setCreateOrEditModalVisible] =
    useState(false);
  const [data, setData] = useState();
  const [modalMode, setModalMode] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSettingTypeIndex, setSelectedSettingTypeIndex] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState();

  useEffect(() => {
    fetchDataSource(0);
  }, []);

  // Definition:
  // Label - display in dropdown
  // Title - display in head of table
  // dataIndex - use in columns table
  // primaryKey - use for delete
  // payloadPrimaryKey - use in send primary key(id) in payload
  // payloadFields - what fields do you want to add or edit in database
  //               -- label - display title
  //               -- key   - use in payload

  //Institution
  //EducationMajor
  //Department
  //Section
  //Position
  //Level
  //Skill
  //Task
  //Project Type
  const settingTypeList = [
    {
      label: 'University / Institution',
      title: 'Institution Name',
      dataIndex: 'institutionName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchInstitutionList,
      addingApi: MasterDataService.addInstitution,
      updateApi: MasterDataService.updateInstitution,
      deleteApi: MasterDataService.deleteInstitution,
      payloadPrimaryKey: 'institutionId',
      payloadFields: [{ label: 'Institution Name', key: 'institutionName' }],
    },
    {
      label: 'Education Major',
      title: 'Education Major Name',
      dataIndex: 'educationMajorName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchEducationMajorList,
      addingApi: MasterDataService.addEducationMajor,
      updateApi: MasterDataService.updateEducationMajor,
      deleteApi: MasterDataService.deleteEducationMajor,
      payloadPrimaryKey: 'educationMajorId',
      payloadFields: [
        { label: 'Education Major Name', key: 'educationMajorName' },
      ],
    },
    {
      label: 'Department',
      title: 'Department Name',
      dataIndex: 'name',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchDepartmentList,
      addingApi: MasterDataService.addDepartment,
      updateApi: MasterDataService.updateDepartment,
      deleteApi: MasterDataService.deleteDepartment,
      payloadPrimaryKey: 'departmentId',
      payloadFields: [{ label: 'Department Name', key: 'name' }],
    },
    {
      label: 'Section',
      title: 'Section Name',
      dataIndex: 'sectionName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchSectionList,
      addingApi: MasterDataService.addSection,
      updateApi: MasterDataService.updateSection,
      deleteApi: MasterDataService.deleteSection,
      payloadPrimaryKey: 'sectionId',
      payloadFields: [{ label: 'Section Name', key: 'sectionName' }],
    },
    {
      label: 'Position',
      title: 'Position Name',
      dataIndex: 'positionName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchPositionList,
      addingApi: MasterDataService.addPosition,
      updateApi: MasterDataService.updatePosition,
      deleteApi: MasterDataService.deletePosition,
      payloadPrimaryKey: 'positionId',
      payloadFields: [{ label: 'Position Name', key: 'positionName' }],
    },
    {
      label: 'Level',
      title: 'Level Name',
      dataIndex: 'levelName',
      primaryKey: 'levelNo',
      fetchApi: MasterDataService.fetchLevelList,
      addingApi: MasterDataService.addLevel,
      updateApi: MasterDataService.updateLevel,
      deleteApi: MasterDataService.deleteLevel,
      payloadPrimaryKey: 'levelNo',
      payloadFields: [
        { label: 'Level No', key: 'levelNo', uneditable: true },
        { label: 'Level Name', key: 'levelName' },
        { label: 'Annual Leave Per Year', key: 'maxHrPerYear' },
      ],
    },
    {
      label: 'Skill',
      title: 'Skill Type Name',
      dataIndex: 'skillType',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchSkillList,
      addingApi: MasterDataService.addSkill,
      updateApi: MasterDataService.updateSkill,
      deleteApi: MasterDataService.deleteSkill,
      payloadPrimaryKey: 'computerSkillId',
      payloadFields: [{ label: 'Skill Type Name', key: 'skillType' }],
    },
    {
      label: 'Task',
      title: 'Task Type Name',
      dataIndex: 'taskTypeName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchTaskList,
      addingApi: MasterDataService.addTask,
      updateApi: MasterDataService.updateTask,
      deleteApi: MasterDataService.deleteTask,
      payloadPrimaryKey: 'taskTypeId',
      payloadFields: [{ label: 'Task Type Name', key: 'taskTypeName' }],
    },
    {
      label: 'Project Type',
      title: 'Project Type Name',
      dataIndex: 'projectTypeName',
      primaryKey: 'id',
      fetchApi: MasterDataService.fetchProjectTypeList,
      addingApi: MasterDataService.addProjectType,
      updateApi: MasterDataService.updateProjectType,
      deleteApi: MasterDataService.deleteProjectType,
      payloadPrimaryKey: 'projectTypeId',
      payloadFields: [{ label: 'Project Type Name', key: 'projectTypeName' }],
    },
  ];

  const defaultColumns = (index) => {
    const setting = settingTypeList[index];
    const defaultStringCompare = (property) => (a, b) =>
      a[property].localeCompare(b[property]);
    return [
      {
        title: setting.title,
        dataIndex: setting.dataIndex,
        fixed: 'left',
        sorter: defaultStringCompare(setting.dataIndex),
        render: (value) => (
          <Text small12 className="text-normal">
            {value}
          </Text>
        ),
      },
      {
        title: 'Status',
        index: 'isActived',
        dataIndex: 'isActived',
        width: '10%',
        render: (isActived) => (
          <Popover
            content={<span>{isActived ? 'Active' : 'Inactive'}</span>}
            arrowPointAtCenter
          >
            {isActived ? <Tag green>Active</Tag> : <Tag grey>Inactive</Tag>}
          </Popover>
        ),
      },

      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <div style={{ textAlign: '' }}>
            {features?.settingsEditRecord && (
              <a
                style={{ marginRight: '16px' }}
                onClick={() => handleClickEditRecord(record)}
              >
                <FormOutlined className="text-primary-blue" />
              </a>
            )}
            {record.isDeletable && features?.settingsDeleteRecord ? (
              <Popconfirm
                title="Are you sure you want to delete？"
                okText="OK"
                cancelText="Cancel"
                okButtonProps={{
                  style: {
                    backgroundColor: '#004368',
                    borderColor: '##004368',
                  },
                }}
                onConfirm={() => {
                  handleConfirmDeleteRecord(record);
                }}
                onCancel={() => {}}
              >
                <DeleteOutlined className="text-secondary-red" />
              </Popconfirm>
            ) : (
              <div style={{ width: '24px', display: 'inline-block' }}></div>
            )}
          </div>
        ),
      },
    ];
  };

  const fetchDataSource = (index) => {
    const setting = settingTypeList[index];
    setIsLoading(true);
    setting.fetchApi(
      undefined,
      ({ data }) => {
        setIsLoading(false);
        setData(data);
        setTableColumns([...defaultColumns(index)]);
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(
            `Failed to fetch data source: ${response.data.message}`,
          );
      },
    );
  };

  const handleCancelModal = () => {
    setCreateOrEditModalVisible(false);
    setModalMode(null);
    setSelectedRecord(null);
  };

  const handleChangeSettingType = (value) => {
    fetchDataSource(value);
    setSelectedSettingTypeIndex(value);
  };

  const handleClickAddRecord = () => {
    setCreateOrEditModalVisible(true);
    setModalMode('create');
    setSelectedRecord(null);
  };

  const handleClickEditRecord = (record) => {
    setCreateOrEditModalVisible(true);
    setModalMode('edit');
    setSelectedRecord(record);
  };

  const handleConfirmDeleteRecord = (record) => {
    setIsLoading(true);
    const setting = settingTypeList[selectedSettingTypeIndex];
    const params = record[setting.primaryKey];
    setting.deleteApi(
      params,
      ({ data }) => {
        setIsLoading(false);
        message.success('Record has been deleted successfully.');
        fetchDataSource(selectedSettingTypeIndex);
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(`Failed to delete record: ${response.data.message}`);
      },
    );
  };
  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Settings
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col>
                      <Select
                        defaultValue={0}
                        style={{ width: '200px' }}
                        onChange={handleChangeSettingType}
                        listHeight={32 * settingTypeList.length}
                      >
                        {settingTypeList.map((item, index) => (
                          <Select.Option value={index}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    {features?.settingsCreateRecord && (
                      <Col>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          onClick={handleClickAddRecord}
                          icon={<PlusOutlined />}
                        >
                          Create Record
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={tableColumns}
                scroll={{ x: 500, y: 'calc(100vh - 240px)' }}
                dataSource={data}
                rowKey="id"
                size="small"
                loading={isLoading}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: [10, 20, 30, 40],
                  locale: { items_per_page: '/ page' },
                  position: ['bottomRight'],
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>

      <CreateOrEditSettingsModal
        createOrEditModalVisible={createOrEditModalVisible}
        modalMode={modalMode}
        handleCancelModal={handleCancelModal}
        settingType={settingTypeList[selectedSettingTypeIndex]}
        selectedRecord={selectedRecord}
        fetchDataSource={() => fetchDataSource(selectedSettingTypeIndex)}
      />
    </div>
  );
};

export default Settings;
