import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

export const Checkbox = styled(AntdCheckbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f4364c;
    border-color: #f4364c;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f4364c;
  }

  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
`;
