import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  Table,
} from 'antd';
import { MasterDataService } from '../../services/api';
import { validateSearch } from 'rc-mentions/lib/util';
import Delete from '../../assets/icons/delete.svg';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';

const MainBox = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
const Subbox = styled.div`
  // height: 6.1875rem;
  // border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Selectitem = styled(Select)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Selectoption = styled(Select.Option)``;
const Textdata = styled.div`
  width: 100%;
  font-size: 12px;
`;
const Flexbox = styled.div`
  height: 100%;
  width: 100%;
  // background: ${(props) =>
    props.one ? 'blue' : props.two ? 'red' : 'yellow'};
  display: ${(props) => (props.one ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-content: ${(props) => (props.one ? 'center' : 'none')};
  justify-content: ${(props) => (props.one ? 'none' : 'center')};
  box-sizing: border-box;
  border-bottom: ${(props) => (props.one ? 'none' : '1px solid #ECECEC')};
  flex-direction: ${(props) => (props.one ? 'row' : 'column')};
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  position: relative;
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 712px) {
    flex-direction: column;
  }
`;
const Flexformbox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Label = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 12px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Formitem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Headdetail = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
`;
const Text = styled.div`
  font-size: ${(props) => (props.big ? '1rem' : '0.8750rem')};
  font-weight: ${(props) => (props.big ? '500' : '300')};
  color: ${(props) =>
    props.big ? '#232323' : props.medium ? '#888888' : '#636262'};
`;

const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;

class ComputerSkills extends Component {
  state = {
    computerSkillsList: this.props.data.computerSkills?.computerSkills,
    computerSkillsListForShow: this.props.data.computerSkills?.computerSkills,
    deletedList: [],
    // isInEditMode: false,
  };

  addNewRow = () => {
    this.setState((prevState) => ({
      computerSkillsList: prevState.computerSkillsList
        ? [
            ...prevState.computerSkillsList,
            {
              skillId: '',
              description: '',
            },
          ]
        : [],
    }));
  };

  clickOnDelete(record) {
    this.setState({
      computerSkillsList: this.state.computerSkillsList.filter(
        (r) => r !== record,
      ),
      deletedList: [...this.state.deletedList, record.id],
    });
  }

  onFinish = (values) => {
    console.log(values);
    this.props.onSubmit(values, this.state.deletedList);
    this.props.changeEditMode();
  };

  componentDidMount = async () => {
    MasterDataService.fetchComputerSkillList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      this.setState({ skillList: data });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        computerSkillsList: this.props.data.computerSkills?.computerSkills,
        computerSkillsListForShow:
          this.props.data.computerSkills?.computerSkills,
      });
    }
  }

  renderEditView = () => {
    return (
      <MainBox>
        <Form id="skills" onFinish={this.onFinish} autoComplete="off">
          <Form.List name="computerSkillsList">
            {(fields, { add, remove }) => {
              fields = [];
              let array = this.state.computerSkillsList
                ? this.state.computerSkillsList
                : [];
              if (array.length > 0) {
                for (var i = 0; i < array.length; i++) {
                  fields.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                  });
                }
              }
              let removefunc = (a, b) => {
                this.clickOnDelete(a);
                remove(b);
              };
              return (
                <div>
                  {fields?.map((field) => (
                    <MainBox>
                      <Box>
                        <Row gutter={24} style={{ marginBottom: '1rem' }}>
                          <Col span={12}>
                            <div
                              style={{ color: 'black', fontSize: '1.125em' }}
                            >
                              {field.fieldKey + 1}.
                            </div>
                          </Col>

                          <Col span={12} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '0',
                                display: 'flex',
                              }}
                            >
                              <Flexformbox style={{ width: '100%' }}>
                                <Button
                                  style={{
                                    color: '#EB5757',
                                    fontSize: '0.8750em',
                                    border: 'none',
                                  }}
                                  onClick={() => {
                                    removefunc(
                                      array[field.fieldKey],
                                      field.name,
                                    );
                                  }}
                                >
                                  <img src={Delete}></img>&nbsp;Delete
                                </Button>
                              </Flexformbox>
                            </div>
                          </Col>
                        </Row>

                        <Horizontalbox>
                          <Formitem
                            hidden
                            {...field}
                            name={[field.name, 'computerSkillId']}
                            fieldKey={[field.fieldKey, 'id']}
                            initialValue={array[field.fieldKey].id}
                          ></Formitem>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Skill</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'skillId']}
                              fieldKey={[field.fieldKey, 'skillId']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input skill',
                                },
                              ]}
                              initialValue={array[field.fieldKey].skillId}
                            >
                              <Selectitem id="skillId">
                                {this.state.skillList
                                  ?.filter((ele) => ele.isActived)
                                  .map((value) => {
                                    return (
                                      <Selectoption value={value.id}>
                                        {value.skillType}
                                      </Selectoption>
                                    );
                                  })}
                              </Selectitem>
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Description</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'description']}
                              fieldKey={[field.fieldKey, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Description',
                                },
                              ]}
                              initialValue={array[field.fieldKey].description}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>
                      </Box>
                    </MainBox>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={this.addNewRow}>
                      Create Skills
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    const columns = [
      {
        title: 'Skill',
        dataIndex: 'skillType',
        index: 'skillType',
        width: '30%',
        render: (skillType) => <Text>{skillType}</Text>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        index: 'description',
        width: '70%',
        render: (description) => <Text>{description}</Text>,
      },
    ];
    return (
      <MainBox>
        {this.props.data.computerSkills?.computerSkills?.length > 0 ? (
          <>
            <Box>
              <Table
                columns={columns}
                dataSource={this.props.data.computerSkills?.computerSkills}
                style={{ width: '100%' }}
                pagination={false}
              ></Table>
            </Box>
          </>
        ) : (
          <></>
        )}
        <Horizontalbox>
          <FlexBox Left style={{ marginBottom: 0 }}>
            <Label>Update Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateDatetime"
            >
              <Text>
                {this.props.data.computerSkills?.updateUserInfo?.updateDatetime
                  ? moment(
                      this.props.data.computerSkills?.updateUserInfo
                        ?.updateDatetime,
                      'YYYY-MM-DDTHH:mm:ss.SSSZ',
                    ).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
          <FlexBox style={{ marginBottom: 0 }}>
            <Label>Update By</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateUserInfo"
            >
              <Text>
                {this.props.data.computerSkills?.updateUserInfo?.updateDatetime
                  ? `${this.props.data.computerSkills?.updateUserInfo?.firstnameEn} ${this.props.data.computerSkills?.updateUserInfo?.lastnameEn}`
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
        </Horizontalbox>
      </MainBox>
    );
  };

  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}
export default ComputerSkills;
