import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Thai from '../../assets/icons/thai.svg';
import UK from '../../assets/icons/uk.svg';
import AddressList from '../../assets/address.json';
import CountryList from '../../assets/countries.json';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
  AutoComplete,
} from 'antd';
import {
  searchAddressByDistrict,
  searchAddressByProvince,
} from 'thai-address-database';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';

const MainBox = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
const Checkboxdata = styled(Checkbox)`
  // width:100rem;
`;
const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 712px) {
    flex-direction: column;
  }
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Formitem = styled(Form.Item)`
  // border: 2px solid red;
  &.ant-form-item {
    // background:blue;
  }
`;

const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Selectitem = styled(Select)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Selectoption = styled(Select.Option)``;
const Breakline = styled.br``;
const Formdata = styled(Form)`
  &.ant-form label {
    font-size: 16px;
    width: 10rem;
    padding-right: 60px;
  }
  layout: 'vertical';
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Headdetail = styled.div`
  font-family: 'Kanit';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
  margin-bottom: 2rem;
`;
const Label = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 12px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 300;
`;
const Textemer = styled.div`
  color: black;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2rem;
`;

class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registeredCountryOptions: [],
    };
  }

  formRef = React.createRef();

  onFinish = (values) => {
    this.props.onSubmit(values);
    this.props.changeEditMode();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        // isInEditMode: !this.state.isInEditMode,
        selectedRegistrationCountry: this.props.data.registrationCountry
          ? this.props.data.registrationCountry
          : 'ไทย',
        selectedRegistrationProvince: this.props.data.registrationProvince,
        selectedRegistrationSubdistrict:
          this.props.data.registrationSubdistrict,
        selectedRegistrationDistrict: this.props.data.registrationDistrict,
        selectedRegistrationPostcode:
          this.props.data.selectedRegistrationPostcode,
        selectedCurrentCountry: this.props.data.currentCountry,
        selectedCurrentProvince: this.props.data.currentProvince,
        selectedCurrentSubdistrict: this.props.data.currentSubdistrict,
        selectedCurrentDistrict: this.props.data.currentDistrict,
        selectedCurrentPostcode: this.props.data.selectedCurrentPostcode,
        isSameAddress: false,
      });
    }
  }
  // registered field
  getRegisteredCountryOptions = async (search) => {
    const searchStr = search ? search : '';
    const filtered = CountryList.filter(
      (country) =>
        country.name
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const registeredCountryOptions = await filtered?.map((country) => {
      return { value: country.name };
    });
    this.setState({
      registeredCountryOptions,
    });
  };
  setRegisteredCountry = async (country) => {
    this.setState({
      selectedRegistrationCountry: country,
      selectedRegistrationProvince: '',
      selectedRegistrationSubdistrict: '',
      selectedRegistrationDistrict: '',
      selectedRegistrationPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      registrationProvince: '',
      registrationSubdistrict: '',
      registrationDistrict: '',
      registrationZipCode: '',
    });
  };
  getRegisteredProvinceOptions = async (search) => {
    const searchStr = search ? search : '';
    const filtered = AddressList.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const registeredProvinceOptions = await filtered?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      registeredProvinceOptions,
    });
  };
  setRegisteredProvince = async (province) => {
    this.setState({
      selectedRegistrationProvince: province,
      selectedRegistrationSubdistrict: '',
      selectedRegistrationDistrict: '',
      selectedRegistrationPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      registrationSubdistrict: '',
      registrationDistrict: '',
      registrationZipCode: '',
    });
  };
  getRegisteredSubdistrictOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedRegistrationProvince;
    const district = this.state.selectedRegistrationDistrict;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) => address.name_th === district,
    );
    const filtered3 = filtered2[0]?.tambon.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const registeredSubdistrictOptions = await filtered3?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      registeredSubdistrictOptions,
    });
  };
  setRegisteredSubdistrict = async (subdistrict) => {
    this.setState({
      selectedRegistrationSubdistrict: subdistrict,
      selectedRegistrationPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      registrationZipCode: '',
    });
  };
  getRegisteredDistrictOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedRegistrationProvince;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const registeredDistrictOptions = await filtered2?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      registeredDistrictOptions,
    });
  };
  setRegisteredDistrict = async (district) => {
    this.setState({
      selectedRegistrationDistrict: district,
      selectedRegistrationSubdistrict: '',
      selectedRegistrationPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      registrationSubdistrict: '',
      registrationZipCode: '',
    });
  };
  getRegisteredPostcodeOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedRegistrationProvince;
    const subdistrict = this.state.selectedRegistrationSubdistrict;
    const district = this.state.selectedRegistrationDistrict;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) => address.name_th === district,
    );
    const filtered3 = filtered2[0]?.tambon.filter(
      (address) => address.name_th === subdistrict,
    );
    const filtered4 = filtered3.filter(
      (address) =>
        address.zip_code
          .toString()
          .trim()
          .search(searchStr.toString().trim().toLowerCase()) >= 0,
    );
    const registeredPostcodeOptions = await filtered4?.map((address) => {
      return { value: address.zip_code.toString() };
    });
    this.setState({
      registeredPostcodeOptions,
    });
  };
  setRegisteredPostcode = async (postcode) => {
    this.setState({
      selectedRegistrationPostcode: postcode,
    });
  };

  // current field
  getCurrentCountryOptions = async (search) => {
    const searchStr = search ? search : '';
    const filtered = CountryList.filter(
      (country) =>
        country.name
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const currentCountryOptions = await filtered?.map((country) => {
      return { value: country.name };
    });
    this.setState({
      currentCountryOptions,
    });
  };
  setCurrentCountry = async (country) => {
    this.setState({
      selectedCurrentCountry: country,
      selectedCurrentProvince: '',
      selectedCurrentSubdistrict: '',
      selectedCurrentDistrict: '',
      selectedCurrentPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      currentProvince: '',
      currentSubdistrict: '',
      currentDistrict: '',
      currentZipCode: '',
    });
  };
  getCurrentProvinceOptions = async (search) => {
    const searchStr = search ? search : '';
    const filtered = AddressList.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const currentProvinceOptions = await filtered?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      currentProvinceOptions,
    });
  };
  setCurrentProvince = async (province) => {
    this.setState({
      selectedCurrentProvince: province,
      selectedCurrentSubdistrict: '',
      selectedCurrentDistrict: '',
      selectedCurrentPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      currentSubdistrict: '',
      currentDistrict: '',
      currentZipCode: '',
    });
  };
  getCurrentSubdistrictOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedCurrentProvince;
    const district = this.state.selectedCurrentDistrict;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) => address.name_th === district,
    );
    const filtered3 = filtered2[0]?.tambon.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const currentSubdistrictOptions = await filtered3?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      currentSubdistrictOptions,
    });
  };
  setCurrentSubdistrict = async (subdistrict) => {
    this.setState({
      selectedCurrentSubdistrict: subdistrict,
      selectedCurrentPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      currentZipCode: '',
    });
  };
  getCurrentDistrictOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedCurrentProvince;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const currentDistrictOptions = await filtered2?.map((address) => {
      return { value: address.name_th };
    });
    this.setState({
      currentDistrictOptions,
    });
  };
  setCurrentDistrict = async (district) => {
    this.setState({
      selectedCurrentDistrict: district,
      selectedCurrentSubdistrict: '',
      selectedCurrentPostcode: '',
    });
    this.formRef.current.setFieldsValue({
      currentSubdistrict: '',
      currentZipCode: '',
    });
  };
  getCurrentPostcodeOptions = async (search) => {
    const searchStr = search ? search : '';
    const province = this.state.selectedCurrentProvince;
    const subdistrict = this.state.selectedCurrentSubdistrict;
    const district = this.state.selectedCurrentDistrict;
    const filtered = AddressList.filter(
      (address) => address.name_th === province,
    );
    const filtered2 = filtered[0]?.amphure.filter(
      (address) => address.name_th === district,
    );
    const filtered3 = filtered2[0]?.tambon.filter(
      (address) => address.name_th === subdistrict,
    );
    const filtered4 = filtered3.filter(
      (address) =>
        address.zip_code
          .toString()
          .trim()
          .search(searchStr.toString().trim().toLowerCase()) >= 0,
    );
    const currentPostcodeOptions = await filtered4?.map((address) => {
      return { value: address.zip_code.toString() };
    });
    this.setState({
      currentPostcodeOptions,
    });
  };
  setCurrentPostcode = async (postcode) => {
    this.setState({
      selectedCurrentPostcode: postcode,
    });
  };
  setSameAddress = (e) => {
    if (e.target.checked) {
      const currentAddress = {
        currentAddress1: this.formRef.current.getFieldValue(
          'registrationAddress1',
        ),
        currentAddress2: this.formRef.current.getFieldValue(
          'registrationAddress2',
        ),
        currentSubdistrict: this.formRef.current.getFieldValue(
          'registrationSubdistrict',
        ),
        currentDistrict: this.formRef.current.getFieldValue(
          'registrationDistrict',
        ),
        currentProvince: this.formRef.current.getFieldValue(
          'registrationProvince',
        ),
        currentCountry: this.formRef.current.getFieldValue(
          'registrationCountry',
        ),
        currentZipCode: this.formRef.current.getFieldValue(
          'registrationZipCode',
        ),
      };
      this.formRef.current.setFieldsValue(currentAddress);
      this.setState({
        isSameAddress: true,
        selectedCurrentCountry: this.formRef.current.getFieldValue(
          'registrationCountry',
        ),
      });
    } else {
      this.setState({ isSameAddress: false });
    }
  };

  renderEditView = () => {
    return (
      <MainBox>
        <Box>
          {/* <FlexBox left> */}
          <Form
            id="address"
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              firstnameEn: this.props.data.firstnameEn,
              lastnameEn: this.props.data.lastnameEn,
              firstnameTh: this.props.data.firstnameTh,
              lastnameTh: this.props.data.lastnameTh,
              registrationAddress1: this.props.data.registrationAddress1,
              registrationAddress2: this.props.data.registrationAddress2,
              registrationSubdistrict: this.props.data.registrationSubdistrict,
              registrationDistrict: this.props.data.registrationDistrict,
              registrationProvince: this.props.data.registrationProvince,
              registrationCountry: this.props.data.registrationCountry
                ? this.props.data.registrationCountry
                : 'ไทย',
              registrationZipCode: this.props.data.registrationZipCode,
              currentAddress1: this.props.data.currentAddress1,
              currentAddress2: this.props.data.currentAddress2,
              currentSubdistrict: this.props.data.currentSubdistrict,
              currentDistrict: this.props.data.currentDistrict,
              currentProvince: this.props.data.currentProvince,
              currentCountry: this.props.data.currentCountry,
              currentZipCode: this.props.data.currentZipCode,
              isSameAddress: false,
              // index: this.props.data.index,
              // emp_index: this.props.data.emp_index,
              emp_index: 0,
            }}
          >
            {/* <Form.Item style={{ display: 'none' }} name="index" /> */}
            <Form.Item style={{ display: 'none' }} name="emp_index" />
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Textemer>Registration Address</Textemer>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '300',
                  marginLeft: '2rem',
                  color: 'red',
                  marginBottom: '2rem',
                }}
              >
                * กรุณากรอกเป็นภาษาไทย
              </div>
            </span>
            <Horizontalbox>
              <FlexBox style={{ width: '100%' }}>
                <Label>Address Line 1</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="registrationAddress1"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox style={{ width: '100%' }}>
                <Label>Address Line 2</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="registrationAddress2"
                >
                  <Inputdata />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>Country</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="registrationCountry"
                >
                  <AutoComplete
                    /* defaultValue='ไทย' */
                    showArrow={true}
                    placeholder="search country"
                    options={this.state.registeredCountryOptions}
                    onSelect={this.setRegisteredCountry}
                    onSearch={this.getRegisteredCountryOptions}
                    onClick={() =>
                      this.getRegisteredCountryOptions(
                        this.state.selectedRegistrationCountry,
                      )
                    }
                  ></AutoComplete>
                </Form.Item>
              </FlexBox>
              {this.state.selectedRegistrationCountry === 'ไทย' && (
                <FlexBox>
                  <Label>Province</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="registrationProvince"
                  >
                    <AutoComplete
                      options={this.state.registeredProvinceOptions}
                      onSelect={this.setRegisteredProvince}
                      onSearch={this.getRegisteredProvinceOptions}
                      onClick={() =>
                        this.getRegisteredProvinceOptions(
                          this.state.selectedRegistrationProvince,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
              )}
            </Horizontalbox>
            {this.state.selectedRegistrationCountry === 'ไทย' && (
              <Horizontalbox>
                <FlexBox Left>
                  <Label>District</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="registrationDistrict"
                  >
                    <AutoComplete
                      disabled={!this.state.selectedRegistrationProvince}
                      options={this.state.registeredDistrictOptions}
                      onSelect={this.setRegisteredDistrict}
                      onSearch={this.getRegisteredDistrictOptions}
                      onClick={() =>
                        this.getRegisteredDistrictOptions(
                          this.state.selectedRegistrationDistrict,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
                <FlexBox Left>
                  <Label>Sub-District</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="registrationSubdistrict"
                  >
                    <AutoComplete
                      disabled={!this.state.selectedRegistrationDistrict}
                      options={this.state.registeredSubdistrictOptions}
                      onSelect={this.setRegisteredSubdistrict}
                      onSearch={this.getRegisteredSubdistrictOptions}
                      onClick={() =>
                        this.getRegisteredSubdistrictOptions(
                          this.state.selectedRegistrationSubdistrict,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
              </Horizontalbox>
            )}

            <Horizontalbox>
              <FlexBox>
                <Label>Postcode</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="registrationZipCode"
                >
                  {this.state.selectedRegistrationCountry !== 'ไทย' ? (
                    <Input />
                  ) : (
                    <AutoComplete
                      disabled={!this.state.selectedRegistrationSubdistrict}
                      options={this.state.registeredPostcodeOptions}
                      onSelect={this.setRegisteredPostcode}
                      onSearch={this.getRegisteredPostcodeOptions}
                      onClick={() =>
                        this.getRegisteredPostcodeOptions(
                          this.state.selectedRegistrationPostcode,
                        )
                      }
                    ></AutoComplete>
                  )}
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <span style={{ display: 'flex' }}>
              <Textemer>Current Address</Textemer>
              <Form.Item
                style={{ margin: '0' }}
                name="isSameAddress"
                valuePropName="checked"
              >
                <Checkbox
                  style={{ marginLeft: '20px' }}
                  onChange={this.setSameAddress}
                >
                  Same Registration
                </Checkbox>
              </Form.Item>
            </span>

            <Horizontalbox>
              <FlexBox style={{ width: '100%' }}>
                <Label>Address Line 1</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="currentAddress1"
                >
                  <Inputdata disabled={this.state.isSameAddress} />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
            <Horizontalbox>
              <FlexBox style={{ width: '100%' }}>
                <Label>Address Line 2</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="currentAddress2"
                >
                  <Inputdata disabled={this.state.isSameAddress} />
                </Form.Item>
              </FlexBox>
            </Horizontalbox>

            <Horizontalbox>
              <FlexBox Left>
                <Label>Country</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="currentCountry"
                >
                  <AutoComplete
                    disabled={this.state.isSameAddress}
                    options={this.state.currentCountryOptions}
                    onSelect={this.setCurrentCountry}
                    onSearch={this.getCurrentCountryOptions}
                    onClick={() =>
                      this.getCurrentCountryOptions(
                        this.state.selectedCurrentCountry,
                      )
                    }
                  ></AutoComplete>
                </Form.Item>
              </FlexBox>
              {this.state.selectedCurrentCountry === 'ไทย' ? (
                <FlexBox>
                  <Label>Province</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="currentProvince"
                  >
                    <AutoComplete
                      disabled={this.state.isSameAddress}
                      options={this.state.currentProvinceOptions}
                      onSelect={this.setCurrentProvince}
                      onSearch={this.getCurrentProvinceOptions}
                      onClick={() =>
                        this.getCurrentProvinceOptions(
                          this.state.selectedCurrentProvince,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
              ) : null}
            </Horizontalbox>
            {this.state.selectedCurrentCountry === 'ไทย' ? (
              <Horizontalbox>
                <FlexBox Left>
                  <Label>District</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="currentDistrict"
                  >
                    <AutoComplete
                      disabled={
                        !this.state.selectedCurrentProvince ||
                        this.state.isSameAddress
                      }
                      options={this.state.currentDistrictOptions}
                      onSelect={this.setCurrentDistrict}
                      onSearch={this.getCurrentDistrictOptions}
                      onClick={() =>
                        this.getCurrentDistrictOptions(
                          this.state.selectedCurrentDistrict,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
                <FlexBox>
                  <Label>Sub-District</Label>
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="currentSubdistrict"
                  >
                    <AutoComplete
                      disabled={
                        !this.state.selectedCurrentDistrict ||
                        this.state.isSameAddress
                      }
                      options={this.state.currentSubdistrictOptions}
                      onSelect={this.setCurrentSubdistrict}
                      onSearch={this.getCurrentSubdistrictOptions}
                      onClick={() =>
                        this.getCurrentSubdistrictOptions(
                          this.state.selectedCurrentSubdistrict,
                        )
                      }
                    ></AutoComplete>
                  </Form.Item>
                </FlexBox>
              </Horizontalbox>
            ) : null}
            <Horizontalbox>
              <FlexBox Left>
                <Label>Postcode</Label>
                <Form.Item
                  style={{ width: '100%', margin: '0' }}
                  name="currentZipCode"
                >
                  {this.state.selectedCurrentCountry !== 'ไทย' ? (
                    <Input disabled={this.state.isSameAddress} />
                  ) : (
                    <AutoComplete
                      disabled={
                        !this.state.selectedCurrentSubdistrict ||
                        this.state.isSameAddress
                      }
                      options={this.state.currentPostcodeOptions}
                      onSelect={this.setCurrentPostcode}
                      onSearch={this.getCurrentPostcodeOptions}
                      onClick={() =>
                        this.getCurrentPostcodeOptions(
                          this.state.selectedCurrentDistrict,
                        )
                      }
                    ></AutoComplete>
                  )}
                </Form.Item>
              </FlexBox>
            </Horizontalbox>
          </Form>
        </Box>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    const { data } = this.props;
    return (
      <MainBox>
        <Box>
          <Textemer>Registration Address</Textemer>
          <Horizontalbox>
            <FlexBox style={{ width: '100%' }}>
              <Label>Address Line 1</Label>
              <Textdata>{this.props.data.registrationAddress1}</Textdata>
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox style={{ width: '100%' }}>
              <Label>Address Line 2</Label>
              <Textdata>{this.props.data.registrationAddress2}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Country</Label>
              <Textdata>{this.props.data.registrationCountry}</Textdata>
            </FlexBox>
            {this.props.data.registrationCountry === 'ไทย' && (
              <FlexBox>
                <Label>Province</Label>
                <Textdata>{this.props.data.registrationProvince}</Textdata>
              </FlexBox>
            )}
          </Horizontalbox>
          {this.props.data.registrationCountry === 'ไทย' && (
            <Horizontalbox>
              <FlexBox Left>
                <Label>District</Label>
                <Textdata>{this.props.data.registrationDistrict}</Textdata>
              </FlexBox>
              <FlexBox>
                <Label>Sub-District</Label>
                <Textdata>{this.props.data.registrationSubdistrict}</Textdata>
              </FlexBox>
            </Horizontalbox>
          )}
          <Horizontalbox>
            <FlexBox Left>
              <Label>Postcode</Label>
              <Textdata>{this.props.data.registrationZipCode}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Textemer>Current Address</Textemer>
          <Horizontalbox>
            <FlexBox style={{ width: '100%' }}>
              <Label>Address Line 1</Label>
              <Textdata>{this.props.data.currentAddress1}</Textdata>
            </FlexBox>
          </Horizontalbox>
          <Horizontalbox>
            <FlexBox style={{ width: '100%' }}>
              <Label>Address Line 2</Label>
              <Textdata>{this.props.data.currentAddress2}</Textdata>
            </FlexBox>
          </Horizontalbox>

          <Horizontalbox>
            <FlexBox Left>
              <Label>Country</Label>
              <Textdata>{this.props.data.currentCountry}</Textdata>
            </FlexBox>
            {this.props.data.currentCountry === 'ไทย' && (
              <FlexBox>
                <Label>Province</Label>
                <Textdata>{this.props.data.currentProvince}</Textdata>
              </FlexBox>
            )}
          </Horizontalbox>
          {this.props.data.currentCountry === 'ไทย' && (
            <Horizontalbox>
              <FlexBox Left>
                <Label>District</Label>
                <Textdata>{this.props.data.currentDistrict}</Textdata>
              </FlexBox>
              <FlexBox>
                <Label>Sub-District</Label>
                <Textdata>{this.props.data.currentSubdistrict}</Textdata>
              </FlexBox>
            </Horizontalbox>
          )}
          <Horizontalbox>
            <FlexBox Left>
              <Label>Postcode</Label>
              <Textdata>{this.props.data.currentZipCode}</Textdata>
            </FlexBox>
          </Horizontalbox>
          <Line style={{ marginTop: '1rem' }}></Line>
          <Horizontalbox>
            <FlexBox Left style={{ marginBottom: 0 }}>
              <Label>Update Date</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="updateDatetime"
              >
                <Textdata>
                  {this.props.data.updateUserInfo
                    ? moment(
                        this.props.data.updateUserInfo?.updateDatetime,
                        'YYYY-MM-DD',
                      ).format('DD MMM YYYY')
                    : '-'}
                </Textdata>
              </Form.Item>
            </FlexBox>
            <FlexBox style={{ marginBottom: 0 }}>
              <Label>Update By</Label>
              <Form.Item
                style={{ width: '100%', margin: '0' }}
                name="updateUserInfo"
              >
                <Textdata>
                  {this.props.data.updateUserInfo
                    ? `${this.props.data.updateUserInfo?.firstnameEn} ${this.props.data.updateUserInfo?.lastnameEn}`
                    : '-'}
                </Textdata>
              </Form.Item>
            </FlexBox>
          </Horizontalbox>
        </Box>
      </MainBox>
    );
  };
  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}
export default Address;
