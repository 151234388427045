import React, { useState, useContext, useEffect } from 'react';
import { redirectTo } from '../services/redirect';
import {
  Authentication,
  ImageService,
  ProfileService as api,
} from '../services/api';

import { AppContext } from './context/AppContext';

import {
  message,
  Layout,
  Row,
  Col,
  Dropdown,
  Menu,
  Avatar,
  Space,
  Badge,
} from 'antd';
import { IdcardFilled } from '@ant-design/icons';
import {
  SyncOutlined,
  CheckOutlined,
  LogoutOutlined,
  SoundFilled,
  ClockCircleFilled,
  SmileFilled,
  SmileOutlined,
  FilePptFilled,
  SafetyCertificateFilled,
  MedicineBoxFilled,
  FileTextFilled,
  ProjectFilled,
  CalendarFilled,
  ContactsFilled,
  SettingFilled,
  BugFilled,
} from '@ant-design/icons';
import defaultProfileImage from '../assets/icons/profileimage.svg';
import dragdownIcon from '../assets/icons/dragdown.svg';
import notificationIcon from '../assets/icons/notification.svg';

import { Tag, Text } from './';

const { Header } = Layout;

const NewHeader = ({ labelHeader, collapsed }) => {
  const { userState, setUserState } = useContext(AppContext);
  const [fullname, setFullname] = useState('');
  const [profileImage, setprofileImage] = useState(null);
  const [notifs, setNotifs] = useState();

  const handleLogout = () => {
    sessionStorage.clear();
    let secondsToGo = 1;
    message.loading('Logout');
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      redirectTo(`/login`);
    }, secondsToGo * 1000);
  };

  const handleRedirect = (path) => {
    redirectTo(path);
  };

  var jwt = require('jsonwebtoken');
  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);

  const notificationDropdownMenu = (
    <Menu style={{ fontFamily: "Figtree, 'Noto Sans Thai', sans-serif" }}>
      <Menu.Item key="notificationLabelDropdown">
        <Space>notification</Space>
      </Menu.Item>
      <Menu.Item type="divider">{/* <Divider /> */}</Menu.Item>
    </Menu>
  );

  const switchRole = (roleId, roleName) => {
    const payload = {
      refreshToken: sessionStorage.getItem('refresh-token'),
      newRole: roleName,
      newRoleId: roleId,
    };
    Authentication.getNewTokenOnSwitchRole(
      payload,
      ({ data }) => {
        sessionStorage.setItem('access-token', data.accessToken);
        sessionStorage.setItem('refresh-token', data.refreshToken);
        setUserState({
          ...userState,
          currentRoleId: roleId,
          currentRoleName: roleName,
        });
      },
      (response) => {
        if (response)
          message.error(`Failed to switch role: ${response.data.message}`);
      },
    );
  };

  const checked = (roleId) => {
    return userState.currentRoleId == roleId ? (
      <CheckOutlined style={{ color: 'red' }} />
    ) : (
      <CheckOutlined style={{ visibility: 'hidden' }} />
    );
  };

  const profileDropdownMenu = (
    <Menu style={{ fontFamily: "Figtree, 'Noto Sans Thai', sans-serif" }}>
      {userState.currentRoleId === 2 && (
        <Menu.Item
          key="profileDropdown"
          icon={<SmileOutlined />}
          onClick={() => handleRedirect('/profile/?id=' + decode1.userId)}
          label="My Profile"
        >
          <Text small12 className="text-normal">
            My Profile
          </Text>
        </Menu.Item>
      )}
      {userState.roleId !== 2 && (
        <Menu.SubMenu
          title={<Text small12>Switch Role</Text>}
          key="switchRoleDropdown"
          icon={<SyncOutlined />}
        >
          {userState.roleId !== 2 && (
            <Menu.Item
              key={userState.role}
              icon={checked(userState.roleId)}
              onClick={() => switchRole(userState.roleId, userState.role)}
            >
              {userState.currentRoleId == userState.roleId ? (
                <Text sub4 className="text-normal">
                  {userState.role}
                </Text>
              ) : (
                <Text small12 className="text-normal">
                  {userState.role}
                </Text>
              )}
            </Menu.Item>
          )}
          <Menu.Item
            key="User"
            icon={checked(2)}
            onClick={() => switchRole(2, 'User')}
          >
            {userState.currentRoleId == 2 ? (
              <Text sub4 className="text-normal">
                USER
              </Text>
            ) : (
              <Text small12 className="text-normal">
                USER
              </Text>
            )}
          </Menu.Item>
        </Menu.SubMenu>
      )}
      <Menu.Item
        key="logoutDropdown"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        className="text-secondary-red"
      >
        <Text small12 className="text-secondary-red">
          Logout
        </Text>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const getUserData = async () => {
      if (decode1 && decode1.userId) {
        const emp_index = decode1.userId;
        await api.personalDetail.getPersonalDetail(
          emp_index,
          async ({ data }) => {
            const { firstnameEn, lastnameEn, picturePath } = data;
            const name =
              firstnameEn && lastnameEn ? `${firstnameEn} ${lastnameEn}` : '';
            setFullname(name);
            if (!!picturePath) {
              setUserState({
                ...userState,
                fullName: name,
                profileImage: picturePath,
              });
            } else {
              setUserState({
                ...userState,
                fullName: name,
                profileImage: defaultProfileImage,
              });
            }
          },
          (response) => {
            console.log(`Failed to get personal detail: ${response.error}`);
          },
        );
      }
    };
    getUserData();
  }, []);

  return (
    <Header
      className="header-container"
      style={{
        position: 'fixed',
        zIndex: '999',
        left: collapsed ? '80px' : '160px',
        right: 0,
        padding: '0 20px',
      }}
    >
      <Row align="middle" gutter={[8, 8]}>
        <Col className="header-topic">
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              {labelHeader === 'Announce' ? (
                <SoundFilled className="text-secondary-red" />
              ) : labelHeader === 'Timesheet' ? (
                <ClockCircleFilled className="text-secondary-red" />
              ) : labelHeader === 'Leave Request' ? (
                <SmileFilled className="text-secondary-red" />
              ) : labelHeader === 'Pay Slip' ? (
                <FilePptFilled className="text-secondary-red" />
              ) : labelHeader === 'Reference Letter' ? (
                <SafetyCertificateFilled className="text-secondary-red" />
              ) : labelHeader === 'Medical Claim' ? (
                <MedicineBoxFilled className="text-secondary-red" />
              ) : labelHeader === 'Report' ? (
                <FileTextFilled className="text-secondary-red" />
              ) : labelHeader === 'Project' ? (
                <ProjectFilled className="text-secondary-red" />
              ) : labelHeader === 'Holidays' ? (
                <CalendarFilled className="text-secondary-red" />
              ) : labelHeader === 'User' ? (
                <ContactsFilled className="text-secondary-red" />
              ) : labelHeader === 'User Profile' ||
                labelHeader === 'My Profile' ? (
                <ContactsFilled className="text-secondary-red" />
              ) : labelHeader === 'Role & Permission' ? (
                <IdcardFilled className="text-secondary-red" />
              ) : labelHeader === 'Settings' ? (
                <SettingFilled className="text-secondary-red" />
              ) : labelHeader === 'Help Desk' ? (
                <BugFilled className="text-secondary-red" />
              ) : undefined}
            </Col>
            {/* <Col>
              <Text small12 className="text-placeholder">
                /
              </Text>
            </Col> */}
            <Col>
              <Text sub3 className="text-normal">
                {labelHeader}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col flex="auto"></Col>
        <Space size="small">
          {userState.currentRoleId !== 2 && (
            <Col>
              <Text sub3 className="text-normal">
                <Tag blue>{userState.currentRoleName.toUpperCase()}</Tag>
              </Text>
            </Col>
          )}

          <Col>
            <Dropdown
              overlay={profileDropdownMenu}
              placement="bottomRight"
              arrow
              overlayStyle={{ width: '240px' }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    style={{ margin: '0 3%' }}
                    size={35}
                    src={
                      userState.profileImage
                        ? userState.profileImage
                        : defaultProfileImage
                    }
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      padding: '0px 20px 0px 5px',
                    }}
                  >
                    <Text sub4 className="text-primary-black">
                      {userState.fullName}
                    </Text>
                    <Text small12 className="text-normal">
                      {userState.position}
                    </Text>
                  </div>
                  <img src={dragdownIcon} alt="dropdown icon" />
                </div>
              </a>
            </Dropdown>
          </Col>
        </Space>
      </Row>
    </Header>
  );
};

export default NewHeader;
