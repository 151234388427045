import { Avatar, Button, Col, Divider, Modal, Row, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../Text';
import { Tag } from '../Tag';
import moment from 'moment';
import { LeaveService } from '../../services/api';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { ClockCircleOutlined, ScheduleOutlined } from '@ant-design/icons';
import { AppContext } from '../context/AppContext';
import { FeatureFlags } from '../context/FeatureFlagContext';
import { formatDaysAndHours } from '../../services/formatDate';

import { ReactComponent as AnnualLeaveIcon } from '../../assets/icons/annualLeave.svg';
import { ReactComponent as PersonalLeaveIcon } from '../../assets/icons/personalLeave.svg';
import { ReactComponent as SickLeaveIcon } from '../../assets/icons/sickLeave.svg';
import { Circle } from '../Circle';
import defaultProfileImage from '../../assets/icons/profileimage.svg';
import systemProfileImage from '../../assets/images/system_profile.jpg';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeaveRequestInformationModal = (props) => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const { leaveInformationModalVisible, modalClose, leaveId, onFetch, userId } =
    props;

  const [isLoading, setIsLoading] = useState(true);
  const [remarkText, setRemarkText] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [record, setRecord] = useState();

  const fetchLeaveByLeaveId = async () => {
    if (leaveId) {
      LeaveService.getLeaveByLeaveId(
        leaveId,
        ({ data }) => {
          setRecord(data);
          setIsLoading(false);
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(
              `Failed to fetch leave information by leaveId: ${response.data.message}`,
            );
          } else {
            //message.error(response);
          }
        },
      );
    }
  };

  useEffect(() => {
    fetchLeaveByLeaveId();
  }, [leaveId]);

  const handelCancelRequest = () => {
    setIsLoading(true);
    LeaveService.editLeave(
      {
        leaveId: record.leaveId,
        leaveStatusId: 400,
      },
      (call) => {
        setIsLoading(false);
        message.success('Leave request has been canceled successfully');
        onFetch();
        setRecord(null);
        modalClose();
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to cancel leave request: ${response.data.message}`,
          );
        }
      },
    );
  };

  const handleRejectApprove = () => {
    setIsLoading(true);
    LeaveService.editLeave(
      {
        leaveId: record.leaveId,
        leaveStatusId: 401,
        remark: remarkText,
      },
      (call) => {
        setIsLoading(false);
        onFetch();
        setRecord(null);
        modalClose();
        message.success('Leave request has been updated successfully.');
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to update leave request: ${response.data.message}`,
          );
        }
      },
    );
  };

  const handleApprove = () => {
    setIsLoading(true);
    LeaveService.editLeave(
      {
        leaveId: record.leaveId,
        leaveStatusId: 200,
        remark: remarkText,
      },
      (call) => {
        setIsLoading(false);
        onFetch();
        setRecord(null);
        modalClose();
        message.success('Leave request has been approved successfully.');
      },
      (response) => {
        // TODO there is another good way for handle error message in one place, in api service.
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to approve leave request: ${response.data.message}`,
          );
        }
      },
    );
  };

  const handleReject = () => {
    setIsLoading(true);
    LeaveService.editLeave(
      {
        leaveId: record.leaveId,
        leaveStatusId: 300,
        remark: remarkText,
      },
      (call) => {
        setIsLoading(false);
        onFetch();
        setRecord(null);
        modalClose();
        message.success('Leave request has been rejected successfully.');
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(
            `Failed to reject leave request - ${response.data.message}`,
          );
        }
      },
    );
  };

  return (
    <Modal
      width="540px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={leaveInformationModalVisible}
      centered
      footer={
        <Row gutter={[16, 16]}>
          {record?.leaveStatus !== 'Pending' && (
            <Col span={24}>
              <Row gutter={[8, 8]} justify="space-between" align="middle">
                <Col>
                  <Text small12 className="text-normal">
                    Description
                  </Text>
                </Col>
                <Col>
                  <Text sub4 className="text-normal">
                    {record?.approveInfo && record?.approveInfo?.remark != ''
                      ? record?.approveInfo?.remark
                      : record?.remark && record?.remark != ''
                      ? record?.remark
                      : `Updated by ${record?.lastUpdateUserInfo.firstnameEn} ${record?.lastUpdateUserInfo.lastnameEn}`}
                  </Text>
                </Col>
              </Row>
            </Col>
          )}
          {record?.leaveStatus === 'Pending' &&
          record?.leaveStatus !== 'Approved' &&
          userId !== record?.supervisorId &&
          userState.currentRoleId === 2 ? (
            <Col span={24}>
              {moment(record?.startDate).isBefore(moment(), 'day') ? (
                <Row gutter={[16, 16]} justify="start">
                  <Col span={24}>
                    <Text small12 className="text-normal">
                      Start Leave Date in the Past. Contact Admin.
                    </Text>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[16, 16]} justify="space-between">
                  <Col span={24}>
                    <Row gutter={[16, 16]} justify="start">
                      <Col>
                        <Text small12 className="text-normal">
                          Waiting for <Text sub4>{record?.supervisorName}</Text>{' '}
                          to approve/reject
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Button
                      loading={isLoading}
                      className="button-red"
                      onClick={handelCancelRequest}
                    >
                      Cancel Request
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          ) : record?.leaveStatus === 'Approved' &&
            userId === record?.supervisorId &&
            !isEditable ? (
            <Col span={24}>
              <Row gutter={[16, 16]} justify="end">
                <Col>
                  <Button
                    loading={isLoading}
                    className="button-red"
                    onClick={() => setIsEditable(true)}
                  >
                    Cancel Request
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : record?.leaveStatus === 'Approved' &&
            userId === record?.supervisorId &&
            isEditable ? (
            <>
              <Col span={24}>
                <Row gutter={[8, 8]} style={{ justifyContent: 'end' }}>
                  <Col
                    span={24}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Text small12 className="text-normal">
                      {record?.leaveStatus == 'Approved'
                        ? 'Reasons for cancel request'
                        : 'Reasons for approve/reject'}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <TextArea
                      maxLength={255}
                      value={remarkText}
                      onChange={(e) => {
                        setRemarkText(e.target.value);
                      }}
                      placeholder="Description"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                    />
                  </Col>
                  <Button
                    loading={isLoading}
                    className="button-outlined"
                    onClick={() => {
                      setIsEditable(false);
                      setRecord(null);
                      modalClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={isLoading}
                    className="button-primary"
                    type="primary"
                    onClick={handleRejectApprove}
                    disabled={!remarkText}
                  >
                    Confirm
                  </Button>
                </Row>
              </Col>
            </>
          ) : record?.leaveStatus === 'Pending' &&
            record?.supervisorId === userId ? (
            <>
              <Col span={24}>
                <Row gutter={[8, 8]} style={{ justifyContent: 'end' }}>
                  <Col
                    span={24}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Text small12 className="text-normal">
                      {record?.leaveStatus == 'Approved'
                        ? 'Reasons for cancel request'
                        : 'Reasons for approve/reject'}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <TextArea
                      maxLength={255}
                      value={remarkText}
                      onChange={(e) => {
                        setRemarkText(e.target.value);
                      }}
                      placeholder="Description"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                    />
                  </Col>
                  <Button
                    loading={isLoading}
                    className="button-red"
                    onClick={handleReject}
                    disabled={!remarkText}
                  >
                    Reject
                  </Button>
                  <Button
                    loading={isLoading}
                    className="button-primary"
                    type="primary"
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                </Row>
              </Col>
            </>
          ) : record?.leaveStatus === 'Pending' &&
            record?.supervisorId !== userId &&
            record?.supervisorName ? (
            <Col span={24}>
              <Row gutter={[16, 16]} justify="start">
                <Col>
                  <Text small12 className="text-normal">
                    Waiting for <Text sub4>{record?.supervisorName}</Text> to
                    approve/reject
                  </Text>
                </Col>
              </Row>
            </Col>
          ) : record?.leaveStatus === 'Pending' && !record?.supervisorId ? (
            <Col span={24}>
              <Row gutter={[16, 16]} justify="start">
                <Col span={24}>
                  <Text small12 className="text-normal">
                    No supervisor found
                  </Text>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      }
      onCancel={() => {
        setRecord(null);
        modalClose();
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]} justify="center" align="middle">
            <Col span={4} style={{ padding: '0 0 0 8px' }}>
              <Circle
                style={{ width: '56px', height: '56px' }}
                src={
                  record?.userInfo.picturePath
                    ? record?.userInfo.picturePath
                    : defaultProfileImage
                }
                onError={(e) => {
                  e.target.src = defaultProfileImage;
                }}
              />
            </Col>
            <Col span={20}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Text h4 className="text-normal">
                    {record?.userInfo?.firstnameEn}{' '}
                    {record?.userInfo?.lastnameEn}
                  </Text>
                </Col>
                <Col flex>
                  <Text sub4 color="gray">
                    Position:{' '}
                  </Text>
                  <Text small12 className="text-normal">
                    {record?.userInfo?.position}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Row gutter={[16, 8]} style={{ overflowY: 'auto', maxHeight: '500px' }}>
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                {record?.leaveName == 'PERSONAL' ? (
                  <PersonalLeaveIcon
                    fill="#F4364C"
                    style={{ marginRight: 5 }}
                  />
                ) : record?.leaveName == 'ANNUAL' ? (
                  <AnnualLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
                ) : (
                  <SickLeaveIcon fill="#F4364C" style={{ marginRight: 5 }} />
                )}
                <Text sub4 className="text-normal">
                  {record?.leaveName == 'PERSONAL'
                    ? 'Personal Leave'
                    : record?.leaveName == 'ANNUAL'
                    ? 'Annual Leave'
                    : 'Sick Leave'}
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-secondary-red">
                  {' '}
                  LEV-{moment(record?.createDatetime).format('YY')}
                  {record?.leaveId.toString().padStart(4, '0')}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Duration
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {formatDaysAndHours(record?.leaveHour)}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Date/Time
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {record ? (
                    record.leaveHour > 0 &&
                    Math.floor(record.leaveHour / 8) == 0 ? (
                      <>
                        {moment(record.startTime).format('DD MMMM YYYY HH.mm')}
                        {' - '}
                        {moment(record.endTime).format(' HH.mm')}
                      </>
                    ) : (record.leaveHour > 0 &&
                        Math.floor(record.leaveHour / 8) == 1) ||
                      record.leaveHour == 0 ? (
                      <>{moment(record.startDate).format('DD MMMM YYYY')}</>
                    ) : (
                      <>
                        {moment(record.startDate).format('DD MMMM')}
                        {' - '}
                        {moment(record.endDate).format('DD MMMM YYYY')}
                      </>
                    )
                  ) : (
                    ''
                  )}
                </Text>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Status
                </Text>
              </Col>
              <Col>
                {record?.leaveStatus == 'Pending' ? (
                  <Tag yellow>
                    <Text small12>Waiting For Approval</Text>
                  </Tag>
                ) : record?.leaveStatus == 'Approved' ? (
                  <Tag green>
                    <Text small12>Approved</Text>
                  </Tag>
                ) : record?.leaveStatus == 'Rejected' ? (
                  <Tag red>
                    <Text small12>Rejected</Text>
                  </Tag>
                ) : record?.leaveStatus == 'Canceled by Approver' ? (
                  <Tag grey>
                    <Text small12>Canceled by Approver</Text>
                  </Tag>
                ) : (
                  <Tag grey>
                    <Text small12>Canceled by User</Text>
                  </Tag>
                )}
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Text small12 className="text-normal">
                  Purpose
                </Text>
              </Col>
              <Col span={24}>
                <Text sub4 className="text-normal">
                  {record?.purpose ? record?.purpose : '-'}
                </Text>
              </Col>
            </Row>
          </Col>
          {userState.currentRoleId !== 2 && (
            <>
              <Divider style={{ margin: 0 }} />
              <Col span={24}>
                {record?.leaveRemain && (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row
                        gutter={[8, 8]}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <AnnualLeaveIcon
                            fill="#F4364C"
                            style={{ marginRight: 5 }}
                          />
                          <Text small12 className="text-normal">
                            {moment().year()} Annual Leave
                          </Text>
                        </Col>
                        <Col>
                          <Row gutter={[8, 8]}>
                            <Col>
                              <Text small12 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Used{' '}
                                    <Text className="text-normal">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.annual.maxHour -
                                          record?.leaveRemain.annual.hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Remains{' '}
                                    <Text className="text-secondary-red">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.annual.hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row
                        gutter={[8, 8]}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <PersonalLeaveIcon
                            fill="#F4364C"
                            style={{ marginRight: 5 }}
                          />
                          <Text small12 className="text-normal">
                            {moment().year()} Personal Leave
                          </Text>
                        </Col>
                        <Col>
                          <Row gutter={[8, 8]}>
                            <Col>
                              <Text small12 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Used{' '}
                                    <Text className="text-normal">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.personal.maxHour -
                                          record?.leaveRemain.personal
                                            .hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Remains{' '}
                                    <Text className="text-secondary-red">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.personal.hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row
                        gutter={[8, 8]}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <SickLeaveIcon
                            fill="#F4364C"
                            style={{ marginRight: 5 }}
                          />
                          <Text small12 className="text-normal">
                            {moment().year()} Sick Leave
                          </Text>
                        </Col>
                        <Col>
                          <Row gutter={[8, 8]}>
                            <Col>
                              <Text small12 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Used{' '}
                                    <Text className="text-normal">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.sick.maxHour -
                                          record?.leaveRemain.sick.hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                            <Col>
                              <Text sub4 className="text-normal">
                                {record?.leaveRemain && (
                                  <>
                                    Remains{' '}
                                    <Text className="text-secondary-red">
                                      {formatDaysAndHours(
                                        record?.leaveRemain.sick.hourRemain,
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </>
          )}
          {record?.leaveStatus !== 'Pending' && (
            <>
              <Divider style={{ margin: 0 }} />
              <Col span={24}>
                <Row justify="space-between" align="middle" gutter={[8, 8]}>
                  <Col>
                    <Text small12 className="text-normal">
                      {record
                        ? record.leaveStatus == 'Approved'
                          ? 'Approved by'
                          : record.leaveStatus == 'Canceled by Approver'
                          ? 'Canceled by'
                          : record.leaveStatus == 'Rejected'
                          ? 'Rejected by'
                          : 'Updated by'
                        : ''}
                    </Text>
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[8, 8]}>
                      <Col>
                        <Circle
                          style={{
                            height: '16px',
                            width: '16px',
                          }}
                          onError={(e) => {
                            e.target.src = defaultProfileImage;
                          }}
                          src={
                            record?.lastUpdateUserInfo.picturePath
                              ? record?.lastUpdateUserInfo.picturePath
                              : !record?.lastUpdateUserInfo?.firstnameEn &&
                                !record?.lastUpdateUserInfo?.lastnameEn
                              ? systemProfileImage
                              : defaultProfileImage
                          }
                        />
                      </Col>
                      <Col>
                        <Text sub4 className="text-normal">
                          {record?.lastUpdateUserInfo?.firstnameEn ||
                          record?.lastUpdateUserInfo?.lastnameEn
                            ? record?.lastUpdateUserInfo?.firstnameEn +
                              ' ' +
                              record?.lastUpdateUserInfo?.lastnameEn
                            : 'System Auto-Approval'}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="space-between" align="middle" gutter={[8, 8]}>
                  <Col>
                    <Text small12 className="text-normal">
                      Status Date
                    </Text>
                  </Col>
                  <Col>
                    <Text sub4 className="text-normal">
                      {moment(record?.updateDatetime).format(
                        'DD MMMM YYYY, HH:mm',
                      )}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Row>
    </Modal>
  );
};

export default LeaveRequestInformationModal;
