import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
} from 'antd';
import { Text } from '../../components';

function TaxReduction(props) {
  const {} = props;
  return <Text sub4>Coming soon...</Text>;
}

export default TaxReduction;
