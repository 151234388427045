import React, { Component } from 'react';
import { SalaryService } from '../../services/api';
import { Col, Row, Space, message } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Select, Switch, Table, Text } from '../../components';

const ContentBox = styled.div`
  background: ${(props) => (props.nc ? 'none' : '#ffffff')};
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  width: auto;
  min-height: calc(100vh - 200px);
`;

const TableText = styled.div`
  color: ${(props) => (props.name ? '#004368' : '#737373')};
  font-size: 1em;
  font-weight: ${(props) =>
    props.title ? '600' : props.isKey ? '500' : '300'};
  font-family: Kanit;
  line-height: 22px;
  text-align: left;
`;

class SalarySettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salaryItems: [],
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoading: true,
    });
    SalaryService.getSalaryItem(
      ({ data }) => {
        console.log(data);
        this.setState({
          salaryItems: data,
          isLoading: false,
        });
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  onChangeSalaryItemStatus = (value, record) => {
    const payload = { id: record.id, isUse: value };
    SalaryService.updateSalaryItem(
      payload,
      ({ data }) => {
        console.log(data);
        message.success('Update success');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  onChangeSalaryItemClass = (value, record) => {
    const payload = { id: record.id, classification: value.join(',') };
    SalaryService.updateSalaryItemClass(
      payload,
      ({ data }) => {
        console.log(data);
        message.success('Update success');
      },
      (response) => {
        if (response) message.error(`Error: ${response.data.message}`);
        this.setState({
          isLoading: false,
        });
      },
    );
  };

  render() {
    const defaultStringCompare = (property) => (a, b) =>
      a[property].localeCompare(b[property]);

    const columns = [
      {
        title: 'DATE',
        dataIndex: 'createDatetime',
        width: '250px',
        align: 'center',
        sorter: defaultStringCompare('createDatetime'),
        render: (value) => (
          <Text small12 className="text-normal">
            {moment(value).format('DD/MM/YYYY HH:mm')}
          </Text>
        ),
      },
      {
        title: 'NAME',
        dataIndex: 'name',
        align: 'center',
        width: '40%',
        sorter: defaultStringCompare('name'),
        render: (value) => (
          <Text small12 className="text-normal">
            {value}
          </Text>
        ),
      },
      {
        title: 'CATEGORY',
        dataIndex: 'category',
        align: 'center',
        width: '30%',
        render: (key, record) => (
          <Space style={{ width: '100%' }} direction="vertical">
            <Select
              mode="multiple"
              disabled={!record.isUse}
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={record.classification
                .split(',')
                .filter((c) => c !== '')}
              onChange={(value) => this.onChangeSalaryItemClass(value, record)}
              options={[
                { label: 'None', value: 'none' },
                { label: 'Income', value: 'income' },
                { label: 'Deduction', value: 'deduction' },
                { label: 'Acc. Tax Income', value: 'accTaxIncome' },
                { label: 'Acc. Tax', value: 'accTax' },
                { label: 'Acc. SSO', value: 'accSso' },
              ]}
            />
          </Space>
        ),
      },
      {
        title: 'ACTION',
        dataIndex: 'isUse',
        align: 'center',
        width: '10%',
        render: (key, record) => (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={record.isUse ? true : false}
            onChange={(value) => this.onChangeSalaryItemStatus(value, record)}
          />
        ),
      },
    ];
    return (
      <div style={{ width: 'auto' }}>
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: '100%',
            padding: '20px 3%',
            backgroundColor: '#ffffff',
          }}
        >
          <Row justify="space-betwenen" gutter={[16, 16]}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text h4 className="text-normal">
                All Pay Slip Item
              </Text>
            </Col>
          </Row>
        </Space>
        <ContentBox>
          <Table
            className="table-striped-rows"
            columns={columns}
            scroll={{ x: 500, y: 'calc(100vh - 240px)' }}
            dataSource={this.state.salaryItems}
            style={{ width: '100%' }}
            size="small"
            loading={this.state.isLoading}
            pagination={{
              position: ['bottomRight'],
              pageSizeOptions: [20, 30, 40],
              showSizeChanger: true,
              defaultPageSize: 20,
            }}
          />
        </ContentBox>
      </div>
    );
  }
}

export default SalarySettingPage;
