import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
} from 'antd';
import { validateSearch } from 'rc-mentions/lib/util';
import Delete from '../../assets/icons/delete.svg';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';
import { MasterDataService } from '../../services/api';

const MainBox = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
const Subbox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Flexbox = styled.div`
  width: ${(props) => (props.one ? '4rem' : '100%')};
  // background: ${(props) => (props.one ? 'blue' : 'yellow')};
  flex-wrap: wrap;
  align-content: ${(props) => (props.one ? 'center' : 'none')};
  justify-content: ${(props) => (props.one ? 'none' : 'center')};
  box-sizing: border-box;
  border-top: ${(props) =>
    props.one ? 'none' : props.top ? 'none' : '1px solid #ECECEC'};
  position: relative;
  padding-top: ${(props) =>
    props.one ? 'none' : props.top ? 'none' : '0.75rem'};
  padding-bottom: ${(props) => (props.one ? 'none' : '0.75rem')};
`;
const Bluecircle = styled.div`
  position: absolute;
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) =>
    props.small
      ? 'white'
      : 'linear-gradient(90deg, #013640 -69.09%, #2A8B9D 111.79%)'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: ${(props) => (props.small ? 'none' : '1em')};
  transform: ${(props) => (props.small ? 'none' : 'translate(0, -50%)')};
`;
const Greycircle = styled.div`
  position: absolute;
  height: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  width: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  border-radius: 50%;
  background: ${(props) => (props.small ? 'white' : '#D2D2D2')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.small ? 'none' : '0.1921875rem')};
  z-index: 99;
  top: ${(props) => (props.small ? 'none' : 'calc(0.75rem + 1em)')};
  transform: ${(props) => (props.small ? 'none' : 'translate(0, -50%)')};
`;
const Verticalline = styled.div`
  height: 100%;
  margin-left: 0.9375rem;
  width: 0.125rem;
  background: #e1e1e1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 712px) {
    flex-direction: column;
  }
`;
const Flexformbox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Label = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 12px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Formitem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Headdetail = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
`;
const Text = styled.div`
  font-size: ${(props) => (props.big ? '1.125rem' : '0.875rem')};
  color: ${(props) =>
    props.big ? '#232323;' : props.medium ? '#737373' : '#565656'};
  font-weight: ${(props) => (props.big ? '500' : '300')};
`;

const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;
const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 12px;
`;

class Education extends Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
    this.formRef = React.createRef();
  }
  state = {
    eduList: this.props.data.education?.educations,
    eduListForShow: this.props.data.education?.educations,
    deletedList: [],
    req: true,
  };

  addNewRow = () => {
    this.setState((prevState) => ({
      eduList: prevState.eduList
        ? [
            ...prevState.eduList,
            {
              major: '',
              institutionId: '',
              degree: '',
              country: '',
              grade: '',
              remark: '',
              startDate: '',
              graduateDate: null,
              isStudying: false,
            },
          ]
        : [],
    }));
  };

  clickOnDelete(record) {
    this.setState({
      eduList: this.state.eduList.filter((r) => r !== record),
      deletedList: [...this.state.deletedList, record.id],
    });
  }

  onFinish = (values) => {
    this.props.onSubmit(values, this.state.deletedList);
    this.props.changeEditMode();
  };

  handleCheck = (e) => {
    this.state.eduList[e.target.id.split('_')[1]].isStudying =
      !this.state.eduList[e.target.id.split('_')[1]].isStudying;
    this.setState({
      eduList: this.state.eduList,
    });
  };

  componentDidMount() {
    MasterDataService.fetchInstitutionList(
      undefined,
      (resp) => {
        resp.data = resp.data.filter((value) => {
          return value.isActived;
        });
        this.setState({ institutionList: resp.data });
      },
      (err) => {
        console.error('fetch inst failed: ', err);
      },
    );

    MasterDataService.fetchEducationMajorList(
      undefined,
      (resp) => {
        resp.data = resp.data.filter((value) => {
          return value.isActived;
        });
        this.setState({ eduMajors: resp.data });
      },
      (err) => {
        console.error('fetch inst failed: ', err);
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        eduList: this.props.data.education?.educations,
        eduListForShow: this.props.data.education?.educations,
      });
    }
  }

  renderEditView = () => {
    return (
      <MainBox>
        <Form
          id="education"
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.List name="eduList">
            {(fields, { add, remove }) => {
              fields = [];
              let array = this.state.eduList ? this.state.eduList : [];
              if (array.length >= 0) {
                for (var i = 0; i < array.length; i++) {
                  fields.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                  });
                }
              }
              let removefunc = (a, b) => {
                this.clickOnDelete(a);
                remove(b);
              };

              return (
                <div>
                  {fields?.map((field) => (
                    <MainBox>
                      <Box>
                        <Row gutter={24} style={{ marginBottom: '1rem' }}>
                          <Col span={12}>
                            <div
                              style={{ color: 'black', fontSize: '1.125em' }}
                            >
                              {field.fieldKey + 1}.
                            </div>
                          </Col>

                          <Col span={12} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '0',
                                display: 'flex',
                              }}
                            >
                              <Flexformbox style={{ width: '100%' }}>
                                <Button
                                  style={{
                                    color: '#EB5757',
                                    fontSize: '0.8750em',
                                    border: 'none',
                                  }}
                                  onClick={() => {
                                    removefunc(
                                      array[field.fieldKey],
                                      field.name,
                                    );
                                  }}
                                >
                                  <img src={Delete}></img>&nbsp;Delete
                                </Button>
                              </Flexformbox>
                            </div>
                          </Col>
                        </Row>

                        <Horizontalbox>
                          <Formitem
                            hidden
                            {...field}
                            name={[field.name, 'educationId']}
                            fieldKey={[field.fieldKey, 'id']}
                            initialValue={array[field.fieldKey].id}
                          ></Formitem>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Major Name</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'majorId']}
                              fieldKey={[field.fieldKey, 'id']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Major Name',
                                },
                              ]}
                              initialValue={array[field.fieldKey].majorId}
                            >
                              {/* <Inputdata /> */}
                              <Select>
                                {this.state.eduMajors
                                  .filter((ele) => ele.isActived)
                                  .map((item) => {
                                    return (
                                      <Select.Option value={item.id}>
                                        {item.educationMajorName}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Institution Name</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'institutionId']}
                              fieldKey={[field.fieldKey, 'id']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Institution Name',
                                },
                              ]}
                              initialValue={array[field.fieldKey].institutionId}
                            >
                              {/* <Inputdata /> */}
                              <Select>
                                {this.state.institutionList
                                  .filter((ele) => ele.isActived)
                                  .map((item) => {
                                    return (
                                      <Select.Option value={item.id}>
                                        {item.institutionName}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Degree</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'degree']}
                              fieldKey={[field.fieldKey, 'degree']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Degree',
                                },
                              ]}
                              initialValue={array[field.fieldKey].degree}
                            >
                              <Select>
                                <Select.Option value="High School">
                                  High School
                                </Select.Option>
                                <Select.Option value="Bachelor’s Degree">
                                  Bachelor’s Degree
                                </Select.Option>
                                <Select.Option value="Master’s Degree">
                                  Master’s Degree
                                </Select.Option>
                                <Select.Option value="Doctoral Degree">
                                  Doctoral Degree
                                </Select.Option>
                                <Select.Option value="Certificate / Degree Obtained">
                                  Certificate / Degree Obtained
                                </Select.Option>
                              </Select>
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>Country</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'country']}
                              fieldKey={[field.fieldKey, 'country']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Country',
                                },
                              ]}
                              initialValue={array[field.fieldKey].country}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Grade</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'grade']}
                              fieldKey={[field.fieldKey, 'grade']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Grade',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      isNaN(value) ||
                                      isNaN(parseFloat(value))
                                    ) {
                                      return Promise.reject(
                                        'Please input only number',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              initialValue={array[field.fieldKey].grade}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>Remark</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'remark']}
                              fieldKey={[field.fieldKey, 'remark']}
                              // rules={[{ required:true,  message: 'Please input Country' }]}
                              initialValue={array[field.fieldKey].remark}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Start Date</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'startDate']}
                              fieldKey={[field.fieldKey, 'startDate']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Start Date',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].startDate == ''
                                  ? ''
                                  : moment(
                                      array[field.fieldKey].startDate,
                                      'YYYY-MM-DD',
                                    )
                              }
                            >
                              <Datepicker format="DD/MM/YYYY" />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>Graduate Date</Label>
                            <div style={{ display: 'block', width: '100%' }}>
                              <Formitem
                                {...field}
                                name={[field.name, 'graduateDate']}
                                fieldKey={[field.fieldKey, 'graduateDate']}
                                dependencies={[
                                  ['eduList', field.fieldKey, 'startDate'],
                                  ['eduList', field.fieldKey, 'isStudying'],
                                ]}
                                rules={[
                                  {
                                    required: !array[field.fieldKey].isStudying,
                                    message: 'Please input Graduate Date',
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, graduateDate) {
                                      if (
                                        getFieldValue([
                                          'eduList',
                                          field.fieldKey,
                                          'isStudying',
                                        ]) === true ||
                                        getFieldValue([
                                          'eduList',
                                          field.fieldKey,
                                          'startDate',
                                        ]) <= graduateDate
                                      ) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(
                                        new Error(
                                          'Graduate date must be after start date',
                                        ),
                                      );
                                    },
                                  }),
                                ]}
                                initialValue={
                                  array[field.fieldKey].graduateDate == null
                                    ? null
                                    : moment(
                                        array[field.fieldKey].graduateDate,
                                        'YYYY-MM-DD',
                                      )
                                }
                              >
                                <Datepicker
                                  format="DD/MM/YYYY"
                                  disabledDate={(current) => {
                                    return (
                                      current <
                                      this.formRef?.current.getFieldValue([
                                        'eduList',
                                        field.name,
                                        'startDate',
                                      ])
                                    );
                                  }}
                                  disabled={array[field.fieldKey].isStudying}
                                />
                              </Formitem>

                              <Formitem
                                {...field}
                                name={[field.name, 'isStudying']}
                                fieldKey={[field.fieldKey, 'isStudying']}
                                initialValue={array[field.fieldKey].isStudying}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  style={{ width: '100%' }}
                                  onChange={this.handleCheck}
                                >
                                  Current Studying
                                </Checkbox>
                              </Formitem>
                            </div>
                          </Flexformbox>
                        </Horizontalbox>

                        <Line></Line>
                      </Box>
                    </MainBox>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={this.addNewRow}>
                      Create Education
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    return (
      <MainBox>
        <Box>
          {this.props.data.education?.educations?.map((val, idx) => (
            <div style={{ display: !!val.major ? 'block' : 'none' }}>
              <Subbox>
                <Flexbox one>
                  {idx === 0 ? (
                    <Bluecircle>
                      <Bluecircle small></Bluecircle>
                    </Bluecircle>
                  ) : (
                    <Greycircle>
                      <Greycircle small></Greycircle>
                    </Greycircle>
                  )}
                  <Verticalline
                    style={{
                      position: 'absolute',
                      top: idx === 0 ? '1em' : 'none',
                      height:
                        idx === 0
                          ? 'calc(100% - 1em)'
                          : idx ===
                            this.props.data.education?.educations.length - 1
                          ? '1em'
                          : '100%',
                      display:
                        this.props.data.education?.educations.length === 1
                          ? 'none'
                          : 'block',
                    }}
                  ></Verticalline>
                </Flexbox>
                <Flexbox two top={idx === 0}>
                  <Text big style={{ color: idx === 0 && '#004368' }}>
                    {val.major}
                  </Text>
                  <div style={{ display: 'flex' }}>
                    <Text medium>{val.institution}</Text>
                    {/* <Text medium>{val.degree}</Text> */}
                    <Text
                      style={{
                        color: idx != 0 ? '#565656' : '#004368',
                        position: 'absolute',
                        right: '0',
                      }}
                    >
                      {(val.degree === 'Bachelor’s Degree' ||
                        val.degree === 'Master’s Degree' ||
                        val.degree === `Doctoral degree`) & !val.isStudying
                        ? 'Graduated in'
                        : moment(val.startDate, 'YYYY-MM-DD').format(
                            'MMM YYYY',
                          ) + ' -'}
                      &nbsp;
                      {val.isStudying
                        ? 'Currently'
                        : moment(val.graduateDate, 'YYYY-MM-DD').format(
                            'MMM YYYY',
                          )}
                    </Text>
                  </div>
                </Flexbox>
              </Subbox>
            </div>
          ))}
        </Box>
        {this.props.data.education?.educations?.length > 0 ? (
          <Line style={{ marginTop: '1rem' }}></Line>
        ) : (
          <></>
        )}
        <Horizontalbox>
          <FlexBox Left style={{ marginBottom: 0 }}>
            <Label>Update Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateDatetime"
            >
              <Text medium>
                {this.props.data.education?.updateUserInfo?.updateDatetime
                  ? moment(
                      this.props.data.education?.updateUserInfo?.updateDatetime,
                      'YYYY-MM-DD',
                    ).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
          <FlexBox style={{ marginBottom: 0 }}>
            <Label>Update By</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateUserInfo"
            >
              <Text medium>
                {this.props.data.education?.updateUserInfo?.updateDatetime
                  ? `${this.props.data.education?.updateUserInfo?.firstnameEn} ${this.props.data.education?.updateUserInfo?.lastnameEn}`
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
        </Horizontalbox>
      </MainBox>
    );
  };

  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}
export default Education;
