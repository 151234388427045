import React, {
  Component,
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  Row,
  Form,
  Divider,
  message,
  Select,
  Button,
  Modal,
  Col,
  Space,
  Avatar,
} from 'antd';
import { ReferenceLetterService } from '../../services/api';
import { AppContext } from '../context/AppContext';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

import { SolutionOutlined } from '@ant-design/icons';
import { Text, Input, DatePicker, FormInput, Tag } from '../../components';
import { FeatureFlags } from '../context/FeatureFlagContext';
import { Circle } from '../Circle';
import defaultProfileImage from '../../assets/icons/profileimage.svg';
import systemProfileImage from '../../assets/images/system_profile.jpg';

const EditReferenceLetterModal = (props) => {
  const { open, onCancel, onFetch, record } = props;
  const payslipFormRef = createRef();
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [payslipType, setReferenceLetterType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [remarkText, setRemarkText] = useState();

  useEffect(() => {
    if (userState.currentRoleId === 2) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [userState]);

  const onCancelReferenceLetterEdit = () => {
    setRemarkText(null);
    props.onCancel();
  };

  const onFinishReferenceLetterByUser = () => {
    setIsLoading(true);
    const payload = {
      payslipRequestId: record.payslipRequestId,
    };
    ReferenceLetterService.updateReferenceLetterByUser(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('ReferenceLetter has been updated successfully.');
        setTimeout(() => {
          onFetch();
        }, 1000);
        onCancelReferenceLetterEdit();
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(`Failed to update payslip: ${response.data.message}`);
      },
    );
  };

  const onFinishReferenceLetter = (requestStatusId) => {
    setIsLoading(true);
    const payload = {
      payslipRequestId: record.payslipRequestId,
      requestStatusId: requestStatusId,
      remark: remarkText,
    };
    ReferenceLetterService.updateReferenceLetter(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('ReferenceLetter has been updated successfully.');
        setTimeout(() => {
          onFetch();
        }, 1000);
        onCancelReferenceLetterEdit();
      },
      (response) => {
        setIsLoading(false);
        if (response)
          message.error(`Failed to update payslip:: ${response.data.message}`);
      },
    );
  };

  return (
    <Modal
      width="600px"
      bodyStyle={{
        maxHeight: '800px',
        overflowY: 'auto',
      }}
      open={open}
      centered
      footer={null}
      onCancel={onCancelReferenceLetterEdit}
      maskClosable
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col span={3} style={{}}>
              <Circle
                style={{ width: '56px', height: '56px' }}
                src={
                  record?.userInfo.picturePath
                    ? record?.userInfo.picturePath
                    : defaultProfileImage
                }
                onError={(e) => {
                  e.target.src = defaultProfileImage;
                }}
              />
            </Col>
            <Col span={21}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Text h4 className="text-normal">
                    {record?.userInfo?.firstnameEn}{' '}
                    {record?.userInfo?.lastnameEn}
                  </Text>
                </Col>
                <Col flex>
                  <Text sub4 color="gray">
                    Department:{' '}
                  </Text>
                  <Text small12 className="text-normal">
                    {record?.userInfo?.department}
                  </Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text sub4 color="gray">
                    Position:{' '}
                  </Text>
                  <Text small12 className="text-normal">
                    {record?.userInfo?.position}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col>
              <SolutionOutlined
                className="text-secondary-red"
                style={{ marginRight: 5 }}
              />
              <Text sub4 className="text-normal">
                {record?.payslipName}
              </Text>
            </Col>
            <Col>
              <Text sub4 className="text-secondary-red">
                REL-{moment(record?.createDatetime).format('YY')}
                {record?.payslipRequestId.toString().padStart(4, '0')}
              </Text>
            </Col>
          </Row>
        </Col>
        {record?.country && (
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
              <Col>
                <Text small12 className="text-normal">
                  Country
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {record?.country}
                </Text>
              </Col>
            </Row>
          </Col>
        )}
        {record?.country && (
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
              <Col>
                <Text small12 className="text-normal">
                  Date
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {moment(record?.startDate).format('DD MMMM YYYY')}
                  {' - '}
                  {moment(record?.endDate).format('DD MMMM YYYY')}
                </Text>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Text small12 className="text-normal">
                Status
              </Text>
            </Col>
            <Col>
              {record?.requestStatusName === 'Pending' ? (
                <Tag yellow>
                  <Text small12>Waiting For Approval</Text>
                </Tag>
              ) : record?.requestStatusName === 'Approved' ? (
                <Tag green>
                  <Text small12>Approved</Text>
                </Tag>
              ) : record?.requestStatusName === 'Rejected' ? (
                <Tag red>
                  <Text small12>Rejected</Text>
                </Tag>
              ) : record?.requestStatusName === 'Canceled by Approver' ? (
                <Tag grey>
                  <Text small12>Canceled by Approver</Text>
                </Tag>
              ) : (
                <Tag grey>
                  <Text small12>Canceled by User</Text>
                </Tag>
              )}
            </Col>
          </Row>
        </Col>
        {record?.requestStatusName === 'Pending' ||
        record?.requestStatusName !== 'Canceled by User' ? (
          <Divider style={{ margin: 0 }} />
        ) : undefined}
        {record?.requestStatusName === 'Pending' ? (
          !isUser && features?.referenceLetterApproveOrReject ? (
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <TextArea
                    value={remarkText}
                    onChange={(e) => {
                      setRemarkText(e.target.value);
                    }}
                    placeholder="Description"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[8, 8]}>
                    <Col>
                      <Button
                        loading={isLoading}
                        className="button-red"
                        onClick={() => onFinishReferenceLetter(300)}
                        disabled={!remarkText}
                      >
                        Reject
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        loading={isLoading}
                        className="button-primary"
                        type="primary"
                        onClick={() => onFinishReferenceLetter(200)}
                      >
                        Approve
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : (
            isUser && (
              <Col span={24}>
                <Row justify="end" gutter={[8, 8]}>
                  <Col>
                    <Button
                      loading={isLoading}
                      className="button-red"
                      onClick={onFinishReferenceLetterByUser}
                    >
                      Cancel Request
                    </Button>
                  </Col>
                </Row>
              </Col>
            )
          )
        ) : record?.requestStatusName !== 'Canceled by User' ? (
          <>
            <Col span={24}>
              <Row justify="space-between" align="middle" gutter={[8, 8]}>
                <Col>
                  <Text small12 className="text-normal">
                    {record
                      ? record.requestStatusName === 'Approved'
                        ? 'Approved by'
                        : record.requestStatusName === 'Canceled by Approver'
                        ? 'Canceled by'
                        : record.requestStatusName === 'Rejected'
                        ? 'Rejected by'
                        : 'Updated by'
                      : ''}
                  </Text>
                </Col>
                <Col>
                  <Row align="middle" gutter={[8, 8]}>
                    <Col>
                      <Circle
                        style={{
                          height: '16px',
                          width: '16px',
                        }}
                        onError={(e) => {
                          e.target.src = defaultProfileImage;
                        }}
                        src={
                          record?.updateUserInfo.picturePath
                            ? record?.updateUserInfo.picturePath
                            : !record?.updateUserInfo?.firstnameEn &&
                              !record?.updateUserInfo?.lastnameEn
                            ? systemProfileImage
                            : defaultProfileImage
                        }
                      />
                    </Col>
                    <Col>
                      <Text sub4 className="text-normal">
                        {record?.updateUserInfo?.firstnameEn ||
                        record?.updateUserInfo?.lastnameEn
                          ? record?.updateUserInfo?.firstnameEn +
                            ' ' +
                            record?.updateUserInfo?.lastnameEn
                          : 'System Auto-Approval'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="middle" gutter={[8, 8]}>
                <Col>
                  <Text small12 className="text-normal">
                    Status Date
                  </Text>
                </Col>
                <Col>
                  <Text sub4 className="text-normal">
                    {moment(record?.updateDatetime).format(
                      'DD MMMM YYYY, HH:mm',
                    )}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Divider style={{ margin: 0 }} />
            <Col span={24}>
              <Row gutter={[8, 8]} justify="space-between" align="middle">
                <Col>
                  <Text small12 className="text-normal">
                    Description
                  </Text>
                </Col>
                <Col>
                  <Text sub4 className="text-normal">
                    {record?.remark && record?.remark != ''
                      ? record?.remark
                      : `Updated by ${record?.updateUserInfo.firstnameEn} ${record?.updateUserInfo.lastnameEn}`}
                  </Text>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
    </Modal>
  );
};

export default EditReferenceLetterModal;
