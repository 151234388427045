import { Row, Col, Space, message, Layout, Button } from 'antd';
import React, { Component, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import actionIcon from '../assets/icons/actiona.svg';
import moment from 'moment';
import { ReferenceLetterService } from '../services/api';
import eyeIcon from '../assets/icons/eye.svg';
import { getUserPic } from '../services/image';

import { FormOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Select, Tag, DatePicker } from '../components';
import { Text } from '../components';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { AppContext } from '../components/context/AppContext';
import EditReferenceLetterModal from '../components/referenceLetter/editReferenceLetterModal';
import RequestReferenceLetterModal from '../components/referenceLetter/requestReferenceLetterModal';

const { Content } = Layout;

const SelectData = styled(Select)`
  border-radius: 5px;
`;

const ReferenceLetter = ({ props }) => {
  const { userState } = useContext(AppContext);
  const [data, setData] = useState();
  const [mainData, setMainData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [record, setRecord] = useState();
  const [year, setYear] = useState(moment().year());

  useEffect(() => {
    fetchReferenceLetterHistory();
  }, [userState, year]);

  const editModalClose = () => {
    setEditModalVisible(false);
    setRecord(null);
  };

  const requestModalClose = () => {
    setRequestModalVisible(false);
    setRecord(null);
  };

  const editModalOpen = async (record) => {
    setEditModalVisible(true);
    setRecord(record);
  };

  const fetchReferenceLetterHistory = () => {
    setIsLoading(true);
    if (userState.currentRoleId === 2) {
      ReferenceLetterService.getReferenceLetterHistory(
        { year: year },
        ({ data }) => {
          setData(data);
          setMainData(data);
          setIsLoading(false);
        },
        (response) => {
          setIsLoading(false);
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else {
      ReferenceLetterService.getAllReferenceLetterHistory(
        { year: year },
        ({ data }) => {
          setData(data);
          setMainData(data);
          setIsLoading(false);
        },
        (response) => {
          setIsLoading(false);

          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    }
  };

  const onStatusTypeFilterChange = (value) => {
    if (value === 'All') {
      setData(mainData);
    } else {
      setData(mainData.filter((record) => record.requestStatusName === value));
    }
  };

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const columns = [
    {
      title: 'Doc No.',
      dataIndex: 'payslipRequestId',
      width: '100px',
      sorter: (a, b) => a.payslipRequestId - b.payslipRequestId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          REL-{moment(record.createDatetime).format('YY')}
          {record.payslipRequestId.toString().padStart(4, '0')}
        </Text>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createDatetime',
      width: '100px',
      sorter: defaultStringCompare('createDatetime'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    userState.currentRoleId !== 2 && {
      title: 'Name',
      dataIndex: 'firstnameEn',
      width: '180px',
      sorter: defaultStringCompare('firstnameEn'),
      render: (key, record) => (
        <Text
          small12
          className="text-normal"
        >{`${record.firstnameEn} ${record.lastnameEn}`}</Text>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'payslipName',
      width: '300px',
      sorter: defaultStringCompare('payslipName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'requestStatusName',
      width: '160px',
      sorter: defaultStringCompare('requestStatusName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'Pending' ? (
            <Tag yellow>Waiting For Approval</Tag>
          ) : value === 'Approved' ? (
            <Tag green>Approved</Tag>
          ) : value === 'Rejected' ? (
            <Tag red>Rejected</Tag>
          ) : value === 'Canceled by Approver' ? (
            <Tag grey>Canceled by Approver</Tag>
          ) : (
            <Tag grey>Canceled by User</Tag>
          )}
        </Text>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (key, record) => (
        <a onClick={() => editModalOpen(record)}>
          {record.requestStatusName === 'Pending' ? (
            <FormOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ].filter(Boolean);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Reference Letter
                  </Text>
                </Col>

                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <DatePicker
                        defaultValue={moment()}
                        picker="year"
                        format="YYYY"
                        disabledDate={(current) =>
                          current > moment().endOf('year')
                        }
                        allowClear={false}
                        onChange={(value) => {
                          setYear(moment(value).year());
                        }}
                      />
                    </Col>
                    <Col flex>
                      <SelectData
                        defaultValue={'All'}
                        style={{ width: '160px' }}
                        onChange={onStatusTypeFilterChange}
                      >
                        <Select.Option value="All">All Status</Select.Option>
                        <Select.Option value="Approved">Approved</Select.Option>
                        <Select.Option value="Pending">
                          Waiting For Approval
                        </Select.Option>
                        <Select.Option value="Rejected">Rejected</Select.Option>
                        <Select.Option value="Canceled by User">
                          Canceled by User
                        </Select.Option>
                        <Select.Option value="Canceled by Approver">
                          Canceled by Approver
                        </Select.Option>
                      </SelectData>
                    </Col>
                    {userState?.currentRoleId === 2 && (
                      <Col>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          onClick={() => {
                            setRequestModalVisible(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Create Request
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                className="table-striped-rows"
                columns={columns}
                scroll={{ x: 500, y: 'calc(100vh - 240px)' }}
                dataSource={data}
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
      {userState?.currentRoleId === 2 && (
        <RequestReferenceLetterModal
          open={requestModalVisible}
          onCancel={requestModalClose}
          onFetch={fetchReferenceLetterHistory}
        />
      )}
      <EditReferenceLetterModal
        open={editModalVisible}
        onCancel={editModalClose}
        onFetch={fetchReferenceLetterHistory}
        record={record}
      />
    </div>
  );
};

export default ReferenceLetter;
