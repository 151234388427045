import { Col, Form, Row, message, Layout, Spin, Button, Collapse } from 'antd';
import { Table, FormInput, Text, Input, Switch } from '../../components';
import React, { useEffect, useState } from 'react';
import { RoleService } from '../../services/api';
import { useForm } from 'antd/lib/form/Form';
import { useLocation, useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Panel } = Collapse;

const UserRoleForm = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roleId = searchParams.get('roleId');
  const unEditedRole = roleId == 1 || roleId == 2;
  const isEditMode = Boolean(roleId);
  const isAddMode = !isEditMode;
  const [form] = useForm();
  const history = useHistory();

  const [rolePermissionData, setRolePermissionData] = useState();
  const [isEnableAll, setIsEnableAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: 'Action',
      align: 'left',
      dataIndex: 'actionName',
      index: 'actionName',
      render: (value) => {
        //Todo: May be redesign flag to handle flag with secondary action
        const valueWithConditions = value.split('$');
        return (
          <>
            <Text small12 className="text-normal">
              {valueWithConditions[0]}
            </Text>
            <Text sub4 className="text-secondary-red">
              {valueWithConditions[1]}
            </Text>
          </>
        );
      },
    },
    {
      title: 'Allowed',
      align: 'right',
      dataIndex: 'isActived',
      index: 'isActived',
      width: '160px',
      render: (active, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={active}
          onChange={(checked) => handleSwitchChange(record, checked)}
          disabled={unEditedRole}
        />
      ),
    },
  ];

  const fetchRolePermission = async () => {
    if (!isEditMode) {
      RoleService.getAllRolePermission(
        ({ data }) => {
          setRolePermissionData({ role: null, allActions: data });
          setIsLoading(false);
        },
        (response) => {
          message.error(
            'Failed to fetch role permission: ' + response.data.message,
          );
          setIsLoading(false);
        },
      );
    } else {
      RoleService.getRolePermissionById(
        roleId,
        ({ data }) => {
          setRolePermissionData(data);
          setIsLoading(false);
        },
        (response) => {
          message.error(
            'Failed to fetch role permission: ' + response.data.message,
          );
          setIsLoading(false);
        },
      );
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let role = form.getFieldsValue();
    role = { ...role, roleId: roleId };
    const payload = {
      role: role,
      allActions: rolePermissionData.allActions,
    };
    if (isAddMode) {
      const messageKey = 'adding-role';
      RoleService.addRole(
        payload,
        ({ data }) => {
          fetchRolePermission();
          setIsLoading(false);
          message.success({
            key: messageKey,
            content:
              'The role and its permissions has been created successfully.',
          });
          history.push('/role');
        },
        (response) => {
          if (response && response.status === 400) {
            message.error({
              key: messageKey,
              content: 'Failed to add role: ' + response.data.message,
            });
          }
          setIsLoading(false);
        },
      );
    } else if (isEditMode) {
      const messageKey = 'updating-role';
      RoleService.updateRole(
        payload,
        ({ data }) => {
          fetchRolePermission();
          setIsLoading(false);
          message.success({
            key: messageKey,
            content:
              'The role and its permissions has been updated successfully.',
          });
          history.push('/role');
        },
        (response) => {
          if (response && response.status === 400) {
            message.error({
              key: messageKey,
              content: 'Failed to update role: ' + response.data.message,
            });
          }
          setIsLoading(false);
        },
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleSwitchChange = (record, checked) => {
    const updatedAllActions = rolePermissionData.allActions.map((actions) => {
      if (actions.actions) {
        const updatedActions = actions.actions.map((item) => {
          if (item.id === record.id) {
            return { ...item, isActived: checked };
          }
          return item;
        });
        return { ...actions, actions: updatedActions };
      }
      return actions;
    });
    setRolePermissionData({
      role: rolePermissionData.role,
      allActions: updatedAllActions,
    });
  };

  const handlePrimarySwitchChange = (actions, checked) => {
    const primaryAction = actions.primaryActions.map((item) => {
      return { ...item, isActived: checked };
    });
    const action = actions.actions?.map((item) => {
      return { ...item, isActived: !checked ? checked : item.isActived };
    });

    const updatedAllActions = rolePermissionData.allActions.map((item) => {
      if (item.frontendPageName === actions.frontendPageName) {
        return {
          frontendPageName: item.frontendPageName,
          actions: action,
          primaryActions: primaryAction,
        };
      }
      return item;
    });

    setRolePermissionData({
      role: rolePermissionData.role,
      allActions: updatedAllActions,
    });
  };

  useEffect(() => {
    fetchRolePermission();
  }, []);

  return (
    <div style={{ width: 'auto' }}>
      <Spin spinning={isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px' }}>
          <Content
            style={{
              margin: '32px 20px 20px 20px',
              overflow: 'initial',
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col
                    flex
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push('/role');
                    }}
                  >
                    <LeftOutlined className="text-normal" />
                  </Col>
                  <Col flex>
                    <Text h4 className="text-normal">
                      Role & Permissions
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {rolePermissionData && (
                  <Form
                    initialValues={{
                      name: rolePermissionData?.role?.name ?? null,
                      description:
                        rolePermissionData?.role?.description ?? null,
                    }}
                    requiredMark={true}
                    form={form}
                    onFinish={handleSubmit}
                  >
                    <Row className="card-container" gutter={[16, 16]}>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <FormInput
                          title="Role Name"
                          required
                          component={
                            <Form.Item
                              name="name"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input role name',
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter role name"
                                id="name"
                                disabled={unEditedRole}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <FormInput
                          title="Description"
                          component={
                            <Form.Item name="description" style={{ margin: 0 }}>
                              <Input
                                placeholder="Enter description"
                                id="description"
                                disabled={unEditedRole}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="card-container"
                      gutter={[16, 16]}
                      style={{ marginTop: '1rem' }}
                    >
                      <Col span={24}>
                        <FormInput
                          title="Permission"
                          component={
                            <Collapse expandIconPosition="end">
                              {rolePermissionData?.allActions?.map(
                                (actions) => (
                                  <Panel
                                    header={actions.frontendPageName}
                                    collapsible="icon"
                                    extra={
                                      <Switch
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                        checked={
                                          actions.primaryActions[0].isActived
                                        }
                                        disabled={unEditedRole}
                                        onChange={(checked) =>
                                          handlePrimarySwitchChange(
                                            actions,
                                            checked,
                                          )
                                        }
                                      />
                                    }
                                  >
                                    <Form.Item>
                                      {rolePermissionData &&
                                      actions.primaryActions[0].isActived ? (
                                        <Table
                                          columns={columns}
                                          dataSource={actions.actions}
                                          size="small"
                                          pagination={false}
                                        />
                                      ) : (
                                        <Row justify="center">
                                          <Text small12 className="text-normal">
                                            This page is currently disabled.
                                          </Text>
                                        </Row>
                                      )}
                                    </Form.Item>
                                  </Panel>
                                ),
                              )}
                            </Collapse>
                          }
                        />
                      </Col>
                    </Row>

                    {!unEditedRole && (
                      <Row
                        gutter={[8, 8]}
                        justify="end"
                        style={{ marginTop: 10, marginRight: -8 }}
                      >
                        <Col flex>
                          <Button
                            className="button-outlined"
                            onClick={() => {
                              form.resetFields();
                              history.push('/role');
                            }}
                            loading={isLoading}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col flex>
                          <Button
                            type="primary"
                            className="button-primary"
                            onClick={() => form.submit()}
                            loading={isLoading}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form>
                )}
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default UserRoleForm;
