import React from 'react';
import moment from 'moment';
import { Form, Modal, Col, Button, Row, Divider } from 'antd';
import FormInput from '../FormInput';
import { DatePicker } from '../DatePicker';
import { Text } from '../Text';

const CreateOtModal = (props) => {
  const { otFormRef, submitAddOt, otModalVisible, closeOtModal } = props;

  return (
    <Modal
      width={400}
      open={otModalVisible}
      onCancel={closeOtModal}
      footer={
        <Col span={24}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col flex>
              <Button
                className="button-outlined"
                width="96px"
                onClick={closeOtModal}
              >
                Cancel
              </Button>
            </Col>
            <Col flex>
              <Button
                className="button-primary"
                type="primary"
                width="96px"
                onClick={() => otFormRef.submit()}
              >
                Create
              </Button>
            </Col>
          </Row>
        </Col>
      }
      centered
    >
      <Form form={otFormRef} onFinish={submitAddOt} requiredMark={true}>
        <Row>
          <Text h4 className="text-normal">
            Create OT date
          </Text>
        </Row>
        <Divider style={{ margin: '12px 0' }} />
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FormInput
              title="Start Date"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', height: 'auto' }}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Start Date.',
                    },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      otFormRef.setFieldsValue({
                        endDate: value,
                      });
                    }}
                  />
                </Form.Item>
              }
            />
          </Col>
          <Col span={24}>
            <FormInput
              title="End Date"
              required
              component={
                <Form.Item
                  style={{ width: '100%', margin: '0', height: 'auto' }}
                  name="endDate"
                  dependencies={['startDate', 'endDate']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select End Date',
                    },
                    () => ({
                      validator: (_, value) => {
                        if (
                          moment(otFormRef.getFieldValue('startDate')) >
                          moment(value)
                        ) {
                          return Promise.reject(
                            'End Date must be greater than Start Date.',
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              }
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateOtModal;
