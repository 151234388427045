import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';
import {
  Layout,
  Form,
  Button,
  message,
  Spin,
  //Input,
  //DatePicker,
  Select,
  Row,
  Col,
  Popconfirm,
} from 'antd';

import { AnnounceService, ImageService } from '../services/api';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import { insertNewUnstyledBlock } from 'draftjs-utils';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import htmlParser from 'html-react-parser';
import moment from 'moment';
import { unstable_batchedUpdates } from 'react-dom';
import { subDomain } from '../services/redirect';

import { EditOutlined, LeftOutlined } from '@ant-design/icons';

import { Text, Input, DatePicker, FormInput } from '../components';
import { useContext } from 'react';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { useForm } from 'antd/lib/form/Form';
import { useLocation, useHistory } from 'react-router-dom';
import { AppContext } from '../components/context/AppContext';

// #region Component Styles

const { Header, Content, Footer, Sider } = Layout;

const ShowAnnounceContent = styled.div`
  padding: 1rem;
  height: 2.5em;
  align-items: center;
  display: flex;
  min-width: 11rem;
  height: auto;
`;
const SelectData = styled(Select)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const ToolbarStyle = {
  border: '1px solid #e8e8eb',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  //height: '40px',
};
const EditorStyle = {
  border: '1px solid #e8e8eb',
  minHeight: '20vw',
  //marginBottom: '5rem',
};

const Box = styled.div`
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 4.9375rem;
  padding-bottom: 5%;
  background: #f6f6f6;
`;

const AnnounceDetail = ({ props }) => {
  const [form] = useForm();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get('id');
  const isEditMode = Boolean(id);
  const isAddMode = !isEditMode;

  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isFormMode, setIsFormMode] = useState(Boolean(id) ? false : true);
  const [data, setData] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastPublishDate, setLastPublishDate] = useState();

  const history = useHistory();

  useEffect(() => {
    getAnnounceDetail();
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  const onUnload = (e) => {
    if (isChanged) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const getAnnounceDetail = () => {
    const announcementId = id;
    if (!announcementId) return false;
    AnnounceService.getAnnouncement(
      announcementId,
      ({ data }) => {
        if (data[0].endDate !== undefined) {
          //reset endDate api 9999 to undefined
          const endDate = data[0].endDate;
          if (moment(endDate).year() === 9999) {
            data[0].endDate = undefined;
          }
        }
        setData(data[0]);
        setLastPublishDate(data[0].lastPublishDate);

        const contentBlock = data[0]?.announcementDetail
          ? htmlToDraft(data[0].announcementDetail)
          : undefined;
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
          form.setFieldsValue({
            announcementDetail: editorState,
          });
        }
        form.setFieldsValue({
          announcementName: data[0]?.announcementName,
          startDate: data[0]?.startDate
            ? moment(data[0].startDate).add(7, 'hours')
            : null,
          endDate: data[0]?.endDate
            ? moment(data[0]?.endDate).add(7, 'hours')
            : null,
          type: data[0]?.type,
        });
        setData({
          ...data[0],
          startDate: data[0]?.startDate
            ? moment(data[0].startDate).add(7, 'hours')
            : null,
          endDate: data[0]?.endDate
            ? moment(data[0]?.endDate).add(7, 'hours')
            : null,
          type: data[0]?.type,
        });
        setIsLoading(false);
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      announcementId: id ? id : undefined,
      announcementName: values.announcementName,
      announcementDetail: draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ),
      type: values.type,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: values.endDate
        ? moment(values.endDate).format('YYYY-MM-DD')
        : moment('31/12/9999', 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
    setIsChanged(false); //to not trigger reload confirmation
    if (isAddMode) {
      handleAddAnnounce(data);
    } else {
      handleUpdateAnnounce(data);
    }
  };

  const handleAddAnnounce = (payload) => {
    const messageKey = 'adding-announce';
    AnnounceService.addAnnouncement(
      payload,
      ({ data }) => {
        setIsLoading(false);
        message.success('The announcement has been created successfully.');
        setTimeout(() => {
          history.push('/announce');
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          setIsLoading(false);
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  const handleUpdateAnnounce = (payload) => {
    const messageKey = 'updating-announce';
    AnnounceService.updateAnnouncement(
      payload,
      ({ data }) => {
        setTimeout(() => {
          setIsLoading(false);
          message.success('The announcement has been updated successfully.');
          setIsFormMode(false);
          unstable_batchedUpdates(() => {
            getAnnounceDetail();
          });
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error({
            key: messageKey,
            content: 'Error: ' + response.data.message,
          });
        } else {
          setIsLoading(false);
          message.error({
            key: messageKey,
            content: 'Error',
          });
        }
      },
    );
  };

  const uploadImageCallback = (file) => {
    var formData = new FormData();
    formData.append('file', file);

    return ImageService.image.uploadImage(formData, ({ data }) => {
      const url = process.env.REACT_APP_API_URL + `/image/getImage/${data}`;
      return new Promise((resolve, reject) => {
        resolve({ data: { link: url } });
      });
    });
  };

  const onPublish = () => {
    const linkUrl = `${window.location.origin}${subDomain}/login?type=announcement&redirect=announce-${id}`;
    const payload = {
      announcementId: id,
      head: data.announcementName,
      linkUrl,
    };
    setIsLoading(true);
    AnnounceService.notifyAnnouncementToLineGroup(
      payload,
      ({ data }) => {
        setLastPublishDate(data[0].lastPublishDate);
        setIsLoading(false);
        message.success('Announcement has been published successfully.');
      },
      (response) => {
        if (response) {
          setIsLoading(false);
          message.error(`Failed to publish : ${response.data.message}`);
        }
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Prompt
        when={isChanged}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Spin spinning={isEditMode && isLoading}>
        <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Content
            style={{
              margin: '32px 20px 0 20px',
              overflow: 'initial',
            }}
          >
            <Form
              form={form}
              onFinish={handleSubmit}
              initialValues={{
                announcementName: data?.announcementName,
              }}
              requiredMark={true}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row
                    gutter={[16, 16]}
                    align="middle"
                    style={{ height: '100%' }}
                    wrap={false}
                  >
                    <Col
                      flex
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/announce');
                      }}
                    >
                      <LeftOutlined />
                    </Col>
                    <Col
                      flex
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Text h4 className="text-normal">
                        {isAddMode
                          ? 'Create New Announce'
                          : data?.announcementName}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                {(isFormMode || isAddMode) && userState.currentRoleId !== 2 ? (
                  <Col span={24}>
                    <Row className="card-container" gutter={[16, 16]}>
                      <Col span={24}>
                        <Text sub2 className="text-normal">
                          Announce Information
                        </Text>
                      </Col>

                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <FormInput
                              title="Announce Name"
                              required
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="announcementName"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Announce Topic',
                                    },
                                  ]}
                                >
                                  <Input
                                    id="announcementName"
                                    placeholder="Enter announce name"
                                    onChange={() => {
                                      setIsChanged(true);
                                    }}
                                  />
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <FormInput
                              title="Type"
                              required
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="type"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Type.',
                                    },
                                  ]}
                                  initialValue={data?.type ? data.type : null}
                                >
                                  <SelectData
                                    placeholder="Select type"
                                    onChange={() => {
                                      setIsChanged(true);
                                    }}
                                  >
                                    <Select.Option value="news">
                                      News
                                    </Select.Option>
                                    <Select.Option value="social security">
                                      Social security
                                    </Select.Option>
                                    <Select.Option value="other">
                                      Other
                                    </Select.Option>
                                  </SelectData>
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <FormInput
                              title="Start Date"
                              required
                              component={
                                <Form.Item
                                  style={{ width: '100%', margin: '0' }}
                                  name="startDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Start Date.',
                                    },
                                  ]}
                                  initialValue={
                                    data?.startDate
                                      ? moment(data?.startDate, 'DD/MM/YYYY')
                                      : null
                                  }
                                >
                                  <DatePicker
                                    format="DD/MM/YYYY"
                                    onChange={() => {
                                      setIsChanged(true);
                                    }}
                                  />
                                </Form.Item>
                              }
                            />
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <FormInput
                              title="End Date"
                              required
                              component={
                                <Form.Item
                                  dependencies={['startDate', 'endDate']}
                                  noStyle
                                >
                                  {() => {
                                    return (
                                      <Form.Item
                                        style={{ width: '100%', margin: '0' }}
                                        name="endDate"
                                        fieldKey="endDate"
                                        dependencies={['startDate', 'endDate']}
                                        rules={[
                                          () => ({
                                            validator: (_, value) => {
                                              if (
                                                moment(
                                                  form.getFieldValue(
                                                    'startDate',
                                                  ),
                                                ) > moment(value)
                                              ) {
                                                return Promise.reject(
                                                  'End Date must be greater than Start Date.',
                                                );
                                              }
                                              return Promise.resolve();
                                            },
                                          }),
                                        ]}
                                        initialValue={
                                          data?.endDate
                                            ? moment(
                                                data?.endDate,
                                                'DD/MM/YYYY',
                                              )
                                            : null
                                        }
                                      >
                                        <DatePicker
                                          format="DD/MM/YYYY"
                                          onChange={() => {
                                            setIsChanged(true);
                                          }}
                                        />
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  userState.currentRoleId !== 2 && (
                    <Col span={24}>
                      <Row className="card-container" gutter={[16, 16]}>
                        <Col span={24}>
                          <Text sub2 className="text-normal">
                            Announce Information
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <FormInput
                                title="Announce Name"
                                component={
                                  <Text
                                    small12
                                    className="text-normal"
                                    style={{ width: '100%', margin: '0' }}
                                  >
                                    {data?.announcementName}
                                  </Text>
                                }
                              />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <FormInput
                                title="Type"
                                component={
                                  <Text
                                    small12
                                    capitalize
                                    className="text-normal"
                                  >
                                    {data?.type}
                                  </Text>
                                }
                              />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <FormInput
                                title="Start Date"
                                component={
                                  <Text small12 className="text-normal">
                                    {data
                                      ? moment(data?.startDate).format(
                                          'DD/MM/YYYY',
                                        )
                                      : null}
                                  </Text>
                                }
                              />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <FormInput
                                title="End Date"
                                component={
                                  <Text small12 className="text-normal">
                                    {data?.endDate
                                      ? moment(data?.endDate).format(
                                          'DD/MM/YYYY',
                                        )
                                      : 'Lifetime'}
                                  </Text>
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
                {isFormMode || isAddMode ? (
                  <Col span={24}>
                    <Row className="card-container" gutter={[16, 16]}>
                      <Col span={24}>
                        <Text sub2 className="text-normal">
                          Announce Description
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="announcementDetail"
                          rules={[
                            () => ({
                              validator: (_, value) => {
                                if (
                                  !editorState.getCurrentContent().hasText()
                                ) {
                                  return Promise.reject(
                                    'Please input Announce Detail.',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Row gutter={[16, 16]}>
                            <Col
                              span={24}
                              style={{
                                padding: '16px',
                                paddingBottom: '16px',
                                border: '1px solid #e8e8eb',
                                borderRadius: '2px',
                              }}
                            >
                              {/*Todo: Image can't load by directly call url without Authentication*/}
                              <Editor
                                onChange={() => {
                                  setIsChanged(true);
                                }}
                                editorState={editorState}
                                editorStyle={EditorStyle}
                                toolbarStyle={ToolbarStyle}
                                toolbar={{
                                  options: [
                                    'colorPicker',
                                    'fontFamily',
                                    'fontSize',
                                    'inline',
                                    'textAlign',
                                    'list',
                                    'link',
                                    'emoji',
                                    'history',
                                    'remove',
                                  ],
                                  inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                  },
                                  list: { inDropdown: false },
                                  textAlign: {
                                    inDropdown: false,
                                    options: ['left', 'center', 'right'],
                                  },
                                  link: { inDropdown: false },
                                  history: { inDropdown: false },
                                  image: {
                                    previewImage: true,
                                    uploadCallback: uploadImageCallback,
                                    defaultSize: {
                                      height: '100%',
                                      width: '100%',
                                    },
                                  },
                                }}
                                handleReturn={(event) => {
                                  // override behavior for enter key
                                  var newEditorState = null;
                                  if (event.keyCode === 13 && event.shiftKey) {
                                    // with shift, make a new block
                                    newEditorState =
                                      insertNewUnstyledBlock(editorState);
                                  } else if (
                                    event.keyCode === 13 &&
                                    !event.shiftKey
                                  ) {
                                    // without shift, just a normal line break
                                    newEditorState =
                                      RichUtils.insertSoftNewline(editorState);
                                  }
                                  if (newEditorState) {
                                    setEditorState(newEditorState);
                                    return true;
                                  }
                                  return false;
                                }}
                                onEditorStateChange={(value) =>
                                  setEditorState(value)
                                }
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row className="card-container" gutter={[16, 16]}>
                      <Col span={24}>
                        <Text sub2 className="text-normal">
                          Announce Description
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col
                            span={24}
                            style={{
                              padding: '16px',
                              border: '1px solid #e8e8eb',
                              borderRadius: '2px',
                            }}
                          >
                            <ShowAnnounceContent>
                              <span style={{ overflowWrap: 'break-word' }}>
                                {data?.announcementDetail
                                  ? htmlParser(data?.announcementDetail)
                                  : ''}
                              </span>
                            </ShowAnnounceContent>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {isFormMode || isAddMode ? (
                  <Col span={24}>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                      <Col flex>
                        <Button
                          className="button-outlined"
                          form="user-form"
                          onClick={() => {
                            if (isAddMode) {
                              history.push('/announce');
                            } else {
                              setIsFormMode((prevFormMode) => !prevFormMode);
                              setIsChanged(false);
                              form.resetFields();
                            }
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col flex>
                        <Button
                          type="primary"
                          className="button-primary"
                          onClick={() => form.submit()}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                      <Col flex>
                        <Text>
                          Last Publish:{' '}
                          {lastPublishDate
                            ? moment(lastPublishDate).format('DD/MM/YYYY HH:mm')
                            : 'Never'}{' '}
                        </Text>
                      </Col>
                      {features.announcePublish && (
                        <Col flex>
                          <Popconfirm
                            title="Are you sure you want to publish?"
                            okButtonProps={{
                              style: {
                                backgroundColor: '#004368',
                                borderColor: '#2d95a8',
                              },
                            }}
                            onConfirm={onPublish}
                            onCancel={() => {}}
                          >
                            <Button className="button-outlined" width="96px">
                              Publish
                            </Button>
                          </Popconfirm>
                        </Col>
                      )}

                      {features.announceEdit && (
                        <Col flex>
                          <Button
                            type="primary"
                            width="96px"
                            className="button-primary"
                            icon={<EditOutlined />}
                            onClick={() =>
                              setIsFormMode((prevFormMode) => !prevFormMode)
                            }
                          >
                            Edit
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default AnnounceDetail;
