import React, { useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import { Button, Tabs, Row, Col, message, Divider, Layout } from 'antd';
import { UserService } from '../services/api';
import 'antd/dist/antd.css';
import Personaldetail from './components/personaldetail.js';
import Workexperience from './components/workexperience.js';
import Education from './components/education.js';
import Trainingcourses from './components/trainingcourses.js';
import Address from './components/address.js';
import Certificate from './components/certificate.js';
import ComputerSkills from './components/computerskills';
import TaxReduction from './components/taxreduction.js';
import {
  ImageService,
  ProfileService as api,
  MasterDataService,
} from '../services/api';
import profileImage from '../assets/icons/profileimage.svg';
import profileInformationIcon from '../assets/icons/personalInformation.svg';
import addressIcon from '../assets/icons/address.svg';
import workExperiencesIcon from '../assets/icons/workExperiences.svg';
import educationIcon from '../assets/icons/education.svg';
import trainingCoursesIcon from '../assets/icons/trainingCourses.svg';
import certificatesIcon from '../assets/icons/certificates.svg';
import skillsIcon from '../assets/icons/skills.svg';
import { Spin } from 'antd';
import {
  useLocation,
  useHistory,
} from 'react-router-dom/cjs/react-router-dom.min';

import { LeftOutlined } from '@ant-design/icons';

import { Text } from '../components';
import EmployeeInformation from '../components/profile/employeeInformation.js';
import AddressInformation from '../components/profile/addressInformation.js';
import WorkExperienceInformation from '../components/profile/workExperienceInformation.js';
import EducationInformation from '../components/profile/educationInformation.js';
import TrainingCourseInformation from '../components/profile/trainingCourseInformation.js';
import CertificateInformation from '../components/profile/certificateInformation.js';
import SkillInformation from '../components/profile/skillInformation.js';
import { AppContext } from '../components/context/AppContext.js';
import moment from 'moment';
import { Circle } from '../components/Circle.js';

const { Content } = Layout;

const { TabPane } = Tabs;

const Profile = (props) => {
  const location = useLocation();
  const { currentPage } = props;
  const id = new URLSearchParams(location.search).get('id');

  var jwt = require('jsonwebtoken');
  var token = sessionStorage.getItem('access-token');
  var decode1 = jwt.decode(token);
  const userId = id ? id : decode1.userId;

  const { userState } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState('Personal Information');
  const [isPersonalInformationInEditMode, setIsPersonalInformationInEditMode] =
    useState(currentPage === 'add');
  const [isAddressInEditMode, setIsAddressInEditMode] = useState(false);
  const [isWorkExperiencesInEditMode, setIsWorkExperiencesInEditMode] =
    useState(false);
  const [isEducationInEditMode, setIsEducationInEditMode] = useState(false);
  const [isTrainingCoursesInEditMode, setIsTrainingCoursesInEditMode] =
    useState(false);
  const [isCertificatesInEditMode, setIsCertificatesInEditMode] =
    useState(false);
  const [isSkillsInEditMode, setIsSkillsInEditMode] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [personalDetail, setPersonalDetail] = useState({});
  const [address, setAddress] = useState({
    registration_address_1: '',
    registration_address_2: '',
    registration_district: '',
    registration_province: '',
    registration_country: '',
    registration_zip_code: '',
    current_address_1: '',
    current_address_2: '',
    current_district: '',
    current_province: '',
    current_country: '',
    current_zip_code: '',
    is_same_address: false,
  });
  const [workExperience, setWorkExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [trainingCourses, setTrainingCourses] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [computerSkills, setComputerSkills] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const history = useHistory(); // Initialize useHistory hook for navigation

  const changeTab = (activeKey) => {
    setActiveTab(activeKey);
  };

  const changeEditMode = () => {
    if (currentPage === 'add') {
      return;
    } else {
      switchEditMode(activeTab);
    }
  };

  const getEditMode = (activeTab) => {
    switch (activeTab) {
      case 'Personal Information':
        return isPersonalInformationInEditMode;
      case 'Address':
        return isAddressInEditMode;
      case 'Work Experiences':
        return isWorkExperiencesInEditMode;
      case 'Education':
        return isEducationInEditMode;
      case 'Training Courses':
        return isTrainingCoursesInEditMode;
      case 'Certificates':
        return isCertificatesInEditMode;
      case 'Skills':
        return isSkillsInEditMode;
      default:
        break;
    }
  };

  const switchEditMode = (activeTab) => {
    switch (activeTab) {
      case 'Personal Information':
        setIsPersonalInformationInEditMode(!isPersonalInformationInEditMode);
        break;
      case 'Address':
        setIsAddressInEditMode(!isAddressInEditMode);
        break;
      case 'Work Experiences':
        setIsWorkExperiencesInEditMode(!isWorkExperiencesInEditMode);
        break;
      case 'Education':
        setIsEducationInEditMode(!isEducationInEditMode);
        break;
      case 'Training Courses':
        setIsTrainingCoursesInEditMode(!isTrainingCoursesInEditMode);
        break;
      case 'Certificates':
        setIsCertificatesInEditMode(!isCertificatesInEditMode);
        break;
      case 'Skills':
        setIsSkillsInEditMode(!isSkillsInEditMode);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentPage === 'add') {
      setHasData(true);
    } else {
      if (
        (userState.currentRoleId === 2 && currentPage !== 'profile') ||
        (userState.currentRoleId !== 2 && currentPage === 'profile')
      ) {
        history.push('/user');
      } else {
        getGeneralInfo();
        getWorkExperience();
        getEducation();
        getTrainingCourses();
        getSkills();
        getCertificates();
      }
    }
  }, [userState]);

  const handleSubmit = (val) => {
    const updatedPersonalDetail = { ...val };
    updatedPersonalDetail.birthday = moment(
      updatedPersonalDetail.birthday,
    ).format('YYYY-MM-DD');
    updatedPersonalDetail.startJobDate = moment(
      updatedPersonalDetail.startJobDate,
    ).format('YYYY-MM-DD');
    updatedPersonalDetail.endProbationDate = moment(
      updatedPersonalDetail.endProbationDate,
    ).format('YYYY-MM-DD');
    if (updatedPersonalDetail.toeic === '') {
      updatedPersonalDetail.toeic = null;
    }
    if (!updatedPersonalDetail.resignationDate) {
      updatedPersonalDetail.resignationDate = null;
    } else {
      updatedPersonalDetail.resignationDate = moment(
        updatedPersonalDetail.resignationDate,
      ).format('YYYY-MM-DD');
    }
    setPersonalDetail(updatedPersonalDetail);
    setHasData(false);

    const jwt = require('jsonwebtoken');
    const token = sessionStorage.getItem('access-token');
    const decode1 = jwt.decode(token);
    const userId = id ? id : decode1.userId;
    updatedPersonalDetail.userId = userId;

    api.personalDetail.editPersonalDetail(
      updatedPersonalDetail,
      ({ data }) => {
        setHasData(true);
        message.success({
          content: 'Profile has been edited successfully',
        });
        getGeneralInfo();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error(`${response.data.message}`);
        } else {
          message.error(`Failed to edit profile`);
        }
      },
    );
  };

  const handleAddUser = (payload) => {
    UserService.getActiveUserCount(
      undefined,
      ({ data }) => {
        const resp = data;
        if (resp.maxAccount <= resp.userCount) {
          message.error('max users reached');
          return;
        }
      },
      (err) => {
        message.error(`${err.data.message}`);
      },
    );

    payload.username = payload.email;

    UserService.addUser(
      payload,
      ({ data }) => {
        message.success('Create user successfully');
        setTimeout(() => {
          history.push('/user');
        }, 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        } else {
          message.error('Error');
        }
      },
    );
  };

  const getWorkExperience = async () => {
    await api.workExperience.getWorkExperience(
      userId,
      ({ data }) => {
        const workEx = data;
        setWorkExperience(workEx);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setWorkExperience([]);
        }
      },
    );
  };

  const getEducation = async () => {
    await api.education.getEducation(
      userId,
      ({ data }) => {
        const eduList = data;
        setEducation(eduList);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setEducation([]);
        }
      },
    );
  };

  const getTrainingCourses = async () => {
    await api.trainingCourses.getTrainingCourses(
      userId,
      ({ data }) => {
        const trainingCourses = data;
        setTrainingCourses(trainingCourses);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setTrainingCourses([]);
        }
      },
    );
  };

  const getSkills = async () => {
    await api.computerSkills.getComputerSkills(
      userId,
      ({ data }) => {
        const skill = data;
        setComputerSkills(skill);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setComputerSkills([]);
        }
      },
    );
  };

  const getCertificates = async () => {
    await api.certificate.getCertificate(
      userId,
      ({ data }) => {
        const certList = data;
        setCertificate(certList);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setCertificate([]);
        }
      },
    );
  };

  const getGeneralInfo = async () => {
    setHasData(false);
    await api.personalDetail.getPersonalDetail(
      userId,
      async ({ data }) => {
        if (data.osLicensePicturePath) {
          await ImageService.image.getImage(
            data.osLicensePicturePath,
            (res) => {
              if (res.status) return false;
              const blobUrl = URL.createObjectURL(res.data);
              data.osImage = blobUrl;
            },
            (response) => {
              message.error('Failed to get OS license picture');
            },
          );
        }
        if (data.microsoftOfficeLicensePicturePath) {
          await ImageService.image.getImage(
            data.microsoftOfficeLicensePicturePath,
            (res) => {
              const blobUrl = URL.createObjectURL(res.data);
              data.msImage = blobUrl;
            },
            (response) => {
              message.error('Failed to get Microsoft Office license picture');
            },
          );
        }
        if (data.signaturePath) {
          await ImageService.image.getImage(
            data.signaturePath,
            (res) => {
              const blobUrl = URL.createObjectURL(res.data);
              data.signatureImage = blobUrl;
            },
            (response) => {
              message.error('Failed to get signature picture');
            },
          );
        }
        const dataValue = JSON.stringify(data);
        const setDataValue = JSON.parse(dataValue);
        setPersonalDetail(setDataValue);
        setHasData(true);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setPersonalDetail([]);
          setHasData(true);
        }
      },
    );

    api.address.getAddress(
      userId,
      ({ data }) => {
        const dataAddress = data;
        setAddress(dataAddress);
      },
      (response) => {
        if (response === undefined || response.status === 500) {
          setAddress([]);
        }
      },
    );
  };

  const handleSubmitAddress = (val) => {
    var jwt = require('jsonwebtoken');
    var token = sessionStorage.getItem('access-token');
    var decode1 = jwt.decode(token);
    const empIndex = id ? id : decode1.userId;
    const address = val;
    address.userId = empIndex;
    const payload = address;
    api.address
      .editAddress(payload, ({ data }) => {})
      .then(() => {
        message.success('Address has been edited successfully.');
        api.address.getAddress(
          empIndex,
          ({ data }) => {
            const dataAddress = data;
            setAddress(dataAddress);
          },
          (response) => {
            if (response === undefined || response.status === 500) {
              setAddress([]);
              message.error('Failed to edit address');
            }
          },
        );
      });
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 20px 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row
                gutter={[16, 16]}
                align="middle"
                style={{ height: '100%' }}
                wrap={false}
              >
                <Col
                  flex
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    console.log(currentPage);
                    if (currentPage == 'profile') {
                      history.goBack();
                    } else {
                      history.push('/user');
                    }
                  }}
                >
                  <LeftOutlined />
                </Col>
                <Col
                  flex
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text h4 className="text-normal">
                    {currentPage == 'add' ? 'Create user' : 'Profile'}
                  </Text>
                </Col>
              </Row>
            </Col>
            {currentPage !== 'add' && (
              <Col xs={{ span: 24 }} md={{ span: 20 }}>
                <Row gutter={[16, 16]} className="card-container">
                  <Col span={24}>
                    <Row gutter={[16, 16]} align="middle" justify="center">
                      <Col>
                        <Circle
                          style={{ width: '96px', height: '96px' }}
                          src={
                            personalDetail?.picturePath
                              ? personalDetail?.picturePath
                              : profileImage
                          }
                          onError={(e) => {
                            e.target.src = profileImage;
                          }}
                        ></Circle>
                      </Col>
                      <Col flex="auto">
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Text h4 className="text-normal">
                              {personalDetail.firstnameEn}
                              &nbsp;
                              {personalDetail.lastnameEn}
                              &nbsp;
                              {personalDetail?.nicknameEn
                                ? '(' + personalDetail.nicknameEn + ')'
                                : undefined}
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text sub4 className="text-secondary-red">
                              {personalDetail.empCode}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Divider style={{ margin: 0 }} />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Text sub4 className="text-normal">
                              Position (Department)
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text small12 className="text-normal">
                              {personalDetail.position}
                              {' ('}
                              {personalDetail.department}
                              {')'}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Text sub4 className="text-normal">
                              Contact
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text small12 className="text-normal">
                              {personalDetail.email}
                              {' ('}
                              {personalDetail.mobileNumber}
                              {')'}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
            {currentPage !== 'add' && (
              <Col xs={{ span: 24 }} md={{ span: 4 }}>
                <Row
                  className="card-level"
                  gutter={[8, 8]}
                  align="middle"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Col>
                    <Text h2 className="text-secondary-red">
                      Level
                    </Text>
                  </Col>
                  <Col>
                    <Text
                      h2
                      className="text-secondary-red"
                      style={{
                        fontSize: '72px',
                        lineHeight: '72px',
                        fontWeight: '400',
                      }}
                    >
                      {personalDetail.level}
                    </Text>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Tabs type="card" activeKey={activeTab} onChange={changeTab}>
                <TabPane tab="Personal Information" key="Personal Information">
                  <EmployeeInformation
                    currentPage={currentPage}
                    employeeInformation={personalDetail}
                    address={address}
                    isEditMode={isPersonalInformationInEditMode}
                    changeEditMode={changeEditMode}
                    onSubmit={
                      currentPage === 'add' ? handleAddUser : handleSubmit
                    }
                  />
                </TabPane>
                <TabPane
                  tab="Address"
                  key="Address"
                  disabled={currentPage === 'add'}
                >
                  <AddressInformation
                    currentPage={currentPage}
                    address={address}
                    isEditMode={isAddressInEditMode}
                    changeEditMode={changeEditMode}
                    onSubmit={handleSubmitAddress}
                  />
                </TabPane>
                <TabPane
                  tab="Work Experiences"
                  key="Work Experiences"
                  disabled={currentPage === 'add'}
                >
                  <WorkExperienceInformation
                    workExperience={workExperience}
                    userId={userId}
                    getWorkExperience={getWorkExperience}
                  />
                </TabPane>
                <TabPane
                  tab="Education"
                  key="Education"
                  disabled={currentPage === 'add'}
                >
                  <EducationInformation
                    education={education}
                    userId={userId}
                    getEducation={getEducation}
                  />
                </TabPane>
                <TabPane
                  tab="Training Courses"
                  key="Training Courses"
                  disabled={currentPage === 'add'}
                >
                  <TrainingCourseInformation
                    trainingCourse={trainingCourses}
                    userId={userId}
                    getTrainingCourses={getTrainingCourses}
                  />
                </TabPane>
                <TabPane
                  tab="Certificates"
                  key="Certificates"
                  disabled={currentPage === 'add'}
                >
                  <CertificateInformation
                    certificate={certificate}
                    getCertificates={getCertificates}
                    userId={userId}
                  />
                </TabPane>
                <TabPane
                  tab="Skills"
                  key="Skills"
                  disabled={currentPage === 'add'}
                >
                  <SkillInformation
                    skill={computerSkills}
                    userId={userId}
                    getSkills={getSkills}
                  />
                </TabPane>
                <TabPane
                  tab="Tax Reduction"
                  key="Tax Reduction"
                  disabled={currentPage === 'add'}
                >
                  <TaxReduction />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Content>
      </Layout>
      {/*<div>
      {hasData == false ? (
        <SpinBox>
          <StyledSpin size="large" />
        </SpinBox>
      ) : null}
      <Box>
        {currentPage !== 'add' && (
          <>
            <div
              style={{
                padding: '32px 0px 0px 0px',
                overflow: 'initial',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row
                    gutter={[16, 16]}
                    align="middle"
                    style={{ height: '100%' }}
                    wrap={false}
                  >
                    <Col
                      flex
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <LeftOutlined className="text-normal" picturePathval />
                    </Col>
                    <Col
                      flex
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Text h4 className="text-normal">
                        Profile
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 16]} align="stretch">
                    <Col xs={{ span: 24 }} md={{ span: 20 }}>
                      <div className="card-container">
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Row
                              gutter={[16, 16]}
                              align="middle"
                              justify="center"
                            >
                              <Col>
                                <Circle
                                  style={{ width: '96px', height: '96px' }}
                                  src={
                                    personalDetail?.picturePath
                                      ? personalDetail?.picturePath
                                      : profileImage
                                  }
                                >
                                  <CircleImage
                                    style={{
                                      width: '96px',
                                      height: '96px',
                                    }}
                                    small
                                    src={
                                      personalDetail?.picturePath
                                        ? personalDetail?.picturePath
                                        : profileImage
                                    }
                                  />
                                </Circle>
                              </Col>
                              <Col flex="auto">
                                <Row gutter={[8, 8]}>
                                  <Col span={24}>
                                    <Text h4 className="text-normal">
                                      {personalDetail.firstnameEn}
                                      &nbsp;
                                      {personalDetail.lastnameEn}
                                      &nbsp;
                                      {personalDetail?.nicknameEn
                                        ? '(' + personalDetail.nicknameEn + ')'
                                        : undefined}
                                    </Text>
                                  </Col>
                                  <Col span={24}>
                                    <Text sub4 className="text-secondary-red">
                                      {personalDetail.empCode}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Divider style={{ margin: 0 }} />
                          </Col>
                          <Col span={24}>
                            <Row gutter={[16, 16]}>
                              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Row gutter={[8, 8]}>
                                  <Col span={24}>
                                    <Text sub4 className="text-normal">
                                      Position (Department)
                                    </Text>
                                  </Col>
                                  <Col span={24}>
                                    <Text small12 className="text-normal">
                                      {personalDetail.position}
                                      {' ('}
                                      {personalDetail.department}
                                      {')'}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Row gutter={[8, 8]}>
                                  <Col span={24}>
                                    <Text sub4 className="text-normal">
                                      Contact
                                    </Text>
                                  </Col>
                                  <Col span={24}>
                                    <Text small12 className="text-normal">
                                      {personalDetail.email}
                                      {' ('}
                                      {personalDetail.mobileNumber}
                                      {')'}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 4 }}>
                      <div className="card-level" style={{ height: '100%' }}>
                        <Row
                          gutter={[8, 8]}
                          align="middle"
                          justify="center"
                          style={{ height: '100%' }}
                        >
                          <Col>
                            <Text h2 className="text-secondary-red">
                              Level
                            </Text>
                          </Col>
                          <Col>
                            <Text
                              h2
                              className="text-secondary-red"
                              style={{
                                fontSize: '72px',
                                lineHeight: '72px',
                                fontWeight: '400',
                              }}
                            >
                              {personalDetail.level}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
        <div>
          <Tabs
            type="card"
            tabBarStyle={{ fontFamily: "Figtree, 'Noto Sans Thai', sans-serif" }}
            activeKey={activeTab}
            onChange={changeTab}
          >
            <TabPane
              tab="Personal Information"
              key="Personal Information"
              style={{ marginTop: '64px' }}
            >
              <Personaldetail
                currentPage={currentPage}
                data={personalDetail}
                isInEditMode={isPersonalInformationInEditMode}
                changeEditMode={changeEditMode}
                onSubmit={currentPage === 'add' ? handleAddUser : handleSubmit}
              />
            </TabPane>
            <TabPane
              tab="Address"
              key="Address"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <Address
                data={address}
                onSubmit={handleSubmitAddress}
                isInEditMode={isAddressInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Work Experiences"
              key="Work Experiences"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <Workexperience
                data={workExperience}
                onSubmit={handleSubmitWorkEx}
                isInEditMode={isWorkExperiencesInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Education"
              key="Education"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <Education
                data={education}
                onSubmit={handleSubmitEdu}
                isInEditMode={isEducationInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Training Courses"
              key="Training Courses"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <Trainingcourses
                data={trainingCourses}
                onSubmit={handleSubmitTrain}
                isInEditMode={isTrainingCoursesInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Certificates"
              key="Certificates"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <Certificate
                data={certificate}
                onSubmit={handleSubmitCert}
                isInEditMode={isCertificatesInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Skills"
              key="Skills"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <ComputerSkills
                data={computerSkills}
                onSubmit={handleSubmitSkills}
                isInEditMode={isSkillsInEditMode}
                changeEditMode={changeEditMode}
              />
            </TabPane>
            <TabPane
              tab="Tax Reduction"
              key="Tax Reduction"
              disabled={currentPage === 'add'}
              style={{ marginTop: '64px' }}
            >
              <TaxReduction />
            </TabPane>
          </Tabs>
        </div>
      </Box>
    </div>*/}
    </div>
  );
};
export default Profile;
