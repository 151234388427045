import React, { Component } from 'react';
import styled from 'styled-components';
import { Popconfirm as AntdPopconfirm } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

export const Popconfirm = styled(AntdPopconfirm)`
  font-size: 12px;
  line-height: 24px;
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
