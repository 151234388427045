import { Button, Col, Form, Row } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { Text } from '../Text';
import FormInput from '../FormInput';
import { Input } from '../Input';
import { Select } from '../Select';
import { EditOutlined } from '@ant-design/icons';
import AddressList from '../../assets/address.json';
import CountryList from '../../assets/countries.json';
import { useForm } from 'antd/lib/form/Form';
import { Checkbox } from '../Checkbox';
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min';
import { AppContext } from '../context/AppContext';

const AddressInformation = (props) => {
  const { currentPage, address, isEditMode, changeEditMode, onSubmit } = props;

  const [registeredCountryOptions, setRegisteredCountryOptions] = useState([]);
  const [registeredProvinceOptions, setRegisteredProvinceOptions] = useState(
    [],
  );
  const [registeredDistrictOptions, setRegisteredDistrictOptions] = useState(
    [],
  );
  const [registeredSubDistrictOptions, setRegisteredSubDistrictOptions] =
    useState([]);
  const [registeredZipCodeOptions, setRegisteredZipCodeOptions] = useState([]);
  const [currentCountryOptions, setCurrentCountryOptions] = useState([]);
  const [currentProvinceOptions, setCurrentProvinceOptions] = useState([]);
  const [currentDistrictOptions, setCurrentDistrictOptions] = useState([]);
  const [currentSubDistrictOptions, setCurrentSubDistrictOptions] = useState(
    [],
  );
  const [currentZipCodeOptions, setCurrentZipCodeOptions] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedRegisteredCountry, setSelectedRegisteredCountry] = useState();
  const [selectedCurrentCountry, setSelectedCurrentCountry] = useState();

  const [form] = useForm();

  const { userState } = useContext(AppContext);

  useEffect(() => {
    form.setFieldsValue({
      registrationAddress1: address.registrationAddress1,
      registrationAddress2: address.registrationAddress2,
      registrationCountry: address.registrationCountry,
      registrationProvince: address.registrationProvince,
      registrationDistrict: address.registrationDistrict,
      registrationSubdistrict: address.registrationSubdistrict,
      registrationZipCode: address.registrationZipCode,
      currentAddress1: address.currentAddress1,
      currentAddress2: address.currentAddress2,
      currentCountry: address.currentCountry,
      currentProvince: address.currentProvince,
      currentDistrict: address.currentDistrict,
      currentSubdistrict: address.currentSubdistrict,
      currentZipCode: address.currentZipCode,
      isSameAddress: address.isSameAddress,
    });
    setSelectedRegisteredCountry(address.registrationCountry);
    setSelectedCurrentCountry(address.currentCountry);
  }, [address]);

  const getCountryOptions = (search, field) => {
    const searchStr = search ? search : '';
    const filtered = CountryList.filter(
      (country) =>
        country.name
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const countries = filtered?.map((country) => {
      return { value: country.name };
    });
    if (field === 'registered') setRegisteredCountryOptions(countries);
    else if (field === 'current') setCurrentCountryOptions(countries);
  };

  const getProvinceOptions = (search, field) => {
    const searchStr = search ? search : '';
    const filtered = AddressList.filter(
      (address) =>
        address.name_th
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const provinceOptions = filtered?.map((address) => {
      return { value: address.name_th };
    });
    if (field === 'registered') setRegisteredProvinceOptions(provinceOptions);
    else if (field === 'current') setCurrentProvinceOptions(provinceOptions);
  };

  const getSubdistrictOptions = (search, field) => {
    const searchStr = search ? search : '';
    const province =
      field === 'registered'
        ? form.getFieldValue('registrationProvince')
        : form.getFieldValue('currentProvince');
    const district =
      field === 'registered'
        ? form.getFieldValue('registrationDistrict')
        : form.getFieldValue('currentDistrict');
    if (!province || !district) {
      if (field === 'registered') setRegisteredSubDistrictOptions([]);
      else if (field === 'current') setCurrentSubDistrictOptions([]);
    } else {
      const filtered = AddressList.filter(
        (address) => address.name_th === province,
      );
      const filtered2 = filtered[0]?.amphure.filter(
        (address) => address.name_th === district,
      );
      const filtered3 = filtered2[0]?.tambon.filter(
        (address) =>
          address.name_th
            .trim()
            .toLowerCase()
            .search(searchStr.trim().toLowerCase()) >= 0,
      );
      const subdistrictOptions = filtered3?.map((address) => {
        return { value: address.name_th };
      });
      if (field === 'registered')
        setRegisteredSubDistrictOptions(subdistrictOptions);
      else if (field === 'current')
        setCurrentSubDistrictOptions(subdistrictOptions);
    }
  };

  const getDistrictOptions = (search, field) => {
    const searchStr = search ? search : '';
    const province =
      field === 'registered'
        ? form.getFieldValue('registrationProvince')
        : form.getFieldValue('currentProvince');
    if (!province) {
      if (field === 'registered') setRegisteredDistrictOptions([]);
      else if (field === 'current') setCurrentDistrictOptions([]);
    } else {
      const filtered = AddressList.filter(
        (address) => address.name_th === province,
      );
      const filtered2 = filtered[0]?.amphure.filter(
        (address) =>
          address.name_th
            .trim()
            .toLowerCase()
            .search(searchStr.trim().toLowerCase()) >= 0,
      );
      const districtOptions = filtered2?.map((address) => {
        return { value: address.name_th };
      });
      if (field === 'registered') setRegisteredDistrictOptions(districtOptions);
      else if (field === 'current') setCurrentDistrictOptions(districtOptions);
    }
  };

  const getZipCodeOptions = (search, field) => {
    const searchStr = search ? search : '';
    const province =
      field === 'registered'
        ? form.getFieldValue('registrationProvince')
        : form.getFieldValue('currentProvince');
    const district =
      field === 'registered'
        ? form.getFieldValue('registrationDistrict')
        : form.getFieldValue('currentDistrict');
    const subDistrict =
      field === 'registered'
        ? form.getFieldValue('registrationSubdistrict')
        : form.getFieldValue('currentSubdistrict');
    if (!province || !district || !subDistrict) {
      if (field === 'registered') setRegisteredZipCodeOptions([]);
      else if (field === 'current') setCurrentZipCodeOptions([]);
    } else {
      const filtered = AddressList.filter(
        (address) => address.name_th === province,
      );
      const filtered2 = filtered[0]?.amphure.filter(
        (address) => address.name_th === district,
      );
      const filtered3 = filtered2[0]?.tambon.filter(
        (address) => address.name_th === subDistrict,
      );
      const filtered4 = filtered3.filter(
        (address) =>
          address.zip_code
            .toString()
            .trim()
            .search(searchStr.toString().trim().toLowerCase()) >= 0,
      );
      const ZipCodeOptions = filtered4?.map((address) => {
        return { value: address.zip_code.toString() };
      });
      if (field === 'registered') setRegisteredZipCodeOptions(ZipCodeOptions);
      else if (field === 'current') setCurrentZipCodeOptions(ZipCodeOptions);
    }
  };

  const setRegisteredCountry = () => {
    getProvinceOptions(null, 'registered');
    form.setFieldsValue({
      registrationProvince: null,
      registrationSubdistrict: null,
      registrationDistrict: null,
      registrationZipCode: null,
    });
  };

  const setRegisteredProvince = () => {
    getDistrictOptions(null, 'registered');
    form.setFieldsValue({
      registrationSubdistrict: null,
      registrationDistrict: null,
      registrationZipCode: null,
    });
  };

  const setRegisteredDistrict = async () => {
    getSubdistrictOptions(null, 'registered');
    form.setFieldsValue({
      registrationSubdistrict: null,
      registrationZipCode: null,
    });
  };

  const setRegisteredSubdistrict = () => {
    getZipCodeOptions(null, 'registered');
    form.setFieldsValue({
      registrationZipCode: null,
    });
  };

  const setCurrentCountry = () => {
    getProvinceOptions(null, 'current');
    form.setFieldsValue({
      currentProvince: null,
      currentSubdistrict: null,
      currentDistrict: null,
      currentZipCode: null,
    });
  };

  const setCurrentProvince = () => {
    getDistrictOptions(null, 'current');
    form.setFieldsValue({
      currentSubdistrict: null,
      currentDistrict: null,
      currentZipCode: null,
    });
  };

  const setCurrentDistrict = async () => {
    getSubdistrictOptions(null, 'current');
    form.setFieldsValue({
      currentSubdistrict: null,
      currentZipCode: null,
    });
  };

  const setCurrentSubdistrict = () => {
    getZipCodeOptions(null, 'current');
    form.setFieldsValue({
      currentZipCode: null,
    });
  };

  const onFinish = (values) => {
    setIsChanged(false);
    onSubmit(values);
    changeEditMode();
  };

  return (
    <Form
      id="address"
      form={form}
      onFinish={onFinish}
      initialValues={{
        registrationAddress1: address.registrationAddress1,
        registrationAddress2: address.registrationAddress2,
        registrationCountry: address.registrationCountry,
        registrationProvince: address.registrationProvince,
        registrationDistrict: address.registrationDistrict,
        registrationSubdistrict: address.registrationSubdistrict,
        registrationZipCode: address.registrationZipCode,
        currentAddress1: address.currentAddress1,
        currentAddress2: address.currentAddress2,
        currentCountry: address.currentCountry,
        currentProvince: address.currentProvince,
        currentDistrict: address.currentDistrict,
        currentSubdistrict: address.currentSubdistrict,
        currentZipCode: address.currentZipCode,
        isSameAddress: address.isSameAddress,
      }}
    >
      <Row gutter={[16, 16]}>
        <Prompt
          when={isChanged}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24}>
              <Text sub2 className="text-normal">
                Registered Address Information
              </Text>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Address Line 1"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="registrationAddress1"
                      >
                        {isEditMode ? (
                          <Input
                            id="registrationAddress1"
                            placeholder="Enter address line 1"
                            onChange={() => {
                              setIsChanged(true);
                            }}
                          />
                        ) : (
                          <Text small12>
                            {address.registrationAddress1 || '-'}
                          </Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Address Line 2"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="registrationAddress2"
                      >
                        {isEditMode ? (
                          <Input
                            id="registrationAddress2"
                            placeholder="Enter address line 2"
                            onChange={() => {
                              setIsChanged(true);
                            }}
                          />
                        ) : (
                          <Text small12>
                            {address.registrationAddress2 || '-'}
                          </Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Country"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="registrationCountry"
                      >
                        {isEditMode ? (
                          <Select
                            showSearch
                            id="registrationCountry"
                            placeholder="Select country"
                            onSelect={(value) => {
                              setRegisteredCountry();
                              setIsChanged(true);
                              setSelectedRegisteredCountry(value);
                            }}
                            onSearch={(search) =>
                              getCountryOptions(search, 'registered')
                            }
                          >
                            {registeredCountryOptions?.map((country) => {
                              return (
                                <Select.Option value={country.value}>
                                  {country.value}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <Text small12>
                            {address.registrationCountry || '-'}
                          </Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
                {selectedRegisteredCountry === 'ไทย' && (
                  <Col span={24}>
                    <FormInput
                      title="District"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="registrationDistrict"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="registrationDistrict"
                              placeholder="Select district"
                              onSelect={setRegisteredDistrict}
                              disabled={
                                form.getFieldValue('registrationProvince') ===
                                null
                              }
                              onSearch={(search) =>
                                getDistrictOptions(search, 'registered')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {registeredDistrictOptions?.map((district) => {
                                return (
                                  <Select.Option value={district.value}>
                                    {district.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.registrationDistrict || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                )}
                {selectedRegisteredCountry === 'ไทย' && (
                  <Col span={24}>
                    <FormInput
                      title="ZipCode"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="registrationZipCode"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="registrationZipCode"
                              placeholder="Select zip code"
                              onSearch={(search) =>
                                getZipCodeOptions(search, 'registered')
                              }
                              disabled={
                                form.getFieldValue(
                                  'registrationSubdistrict',
                                ) === null
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {registeredZipCodeOptions?.map((zipcode) => {
                                return (
                                  <Select.Option value={zipcode.value}>
                                    {zipcode.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.registrationZipCode || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={12}>
              {selectedRegisteredCountry === 'ไทย' && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <FormInput
                      title="Province"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="registrationProvince"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="registrationProvince"
                              placeholder="Select province"
                              onSelect={setRegisteredProvince}
                              onSearch={(search) =>
                                getProvinceOptions(search, 'registered')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {registeredProvinceOptions?.map((province) => {
                                return (
                                  <Select.Option value={province.value}>
                                    {province.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.registrationProvince || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <FormInput
                      title="Subdistrict"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="registrationSubdistrict"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="registrationSubdistrict"
                              placeholder="Select subdistrict"
                              onSelect={setRegisteredSubdistrict}
                              disabled={
                                form.getFieldValue('registrationDistrict') ===
                                null
                              }
                              onSearch={(search) =>
                                getSubdistrictOptions(search, 'registered')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {registeredSubDistrictOptions?.map(
                                (subdistrict) => {
                                  return (
                                    <Select.Option value={subdistrict.value}>
                                      {subdistrict.value}
                                    </Select.Option>
                                  );
                                },
                              )}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.registrationSubdistrict || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24}>
              <Text sub2 className="text-normal">
                Current Address Information
              </Text>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                {isEditMode && (
                  <Col span={24}>
                    <FormInput
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="isSameAddress"
                          valuePropName="checked"
                        >
                          <Checkbox
                            id="isSameAddress"
                            onChange={(e) => {
                              if (e.target.checked) {
                                form.setFieldsValue({
                                  currentAddress1: form.getFieldValue(
                                    'registrationAddress1',
                                  ),
                                  currentAddress2: form.getFieldValue(
                                    'registrationAddress2',
                                  ),
                                  currentCountry: form.getFieldValue(
                                    'registrationCountry',
                                  ),
                                  currentProvince: form.getFieldValue(
                                    'registrationProvince',
                                  ),
                                  currentDistrict: form.getFieldValue(
                                    'registrationDistrict',
                                  ),
                                  currentSubdistrict: form.getFieldValue(
                                    'registrationSubdistrict',
                                  ),
                                  currentZipCode: form.getFieldValue(
                                    'registrationZipCode',
                                  ),
                                });
                                setSelectedCurrentCountry(
                                  form.getFieldValue('registrationCountry'),
                                );
                              }
                            }}
                          >
                            Use same address as registration
                          </Checkbox>
                        </Form.Item>
                      }
                    />
                  </Col>
                )}
                <Col span={24}>
                  <FormInput
                    title="Address Line 1"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="currentAddress1"
                      >
                        {isEditMode ? (
                          <Input
                            id="currentAddress1"
                            placeholder="Enter address line 1"
                            onChange={() => {
                              setIsChanged(true);
                            }}
                          />
                        ) : (
                          <Text small12>{address.currentAddress1 || '-'}</Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Address Line 2"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="currentAddress2"
                      >
                        {isEditMode ? (
                          <Input
                            id="currentAddress2"
                            placeholder="Enter address line 2"
                            onChange={() => {
                              setIsChanged(true);
                            }}
                          />
                        ) : (
                          <Text small12>{address.currentAddress2 || '-'}</Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FormInput
                    title="Country"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="currentCountry"
                      >
                        {isEditMode ? (
                          <Select
                            showSearch
                            id="currentCountry"
                            placeholder="Select country"
                            onSelect={(value) => {
                              setCurrentCountry();
                              setSelectedCurrentCountry(value);
                              setIsChanged(true);
                            }}
                            onSearch={(search) =>
                              getCountryOptions(search, 'current')
                            }
                          >
                            {currentCountryOptions?.map((country) => {
                              return (
                                <Select.Option value={country.value}>
                                  {country.value}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        ) : (
                          <Text small12>{address.currentCountry || '-'}</Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
                {selectedCurrentCountry === 'ไทย' && (
                  <Col span={24}>
                    <FormInput
                      title="District"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="currentDistrict"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="currentDistrict"
                              placeholder="Select district"
                              onSelect={setCurrentDistrict}
                              disabled={
                                form.getFieldValue('currentProvince') === null
                              }
                              onSearch={(search) =>
                                getDistrictOptions(search, 'current')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {currentDistrictOptions?.map((district) => {
                                return (
                                  <Select.Option value={district.value}>
                                    {district.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.currentDistrict || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                )}
                {selectedCurrentCountry === 'ไทย' && (
                  <Col span={24}>
                    <FormInput
                      title="ZipCode"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="currentZipCode"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="currentZipCode"
                              placeholder="Select zip code"
                              onSearch={(search) =>
                                getZipCodeOptions(search, 'current')
                              }
                              disabled={
                                form.getFieldValue('currentSubdistrict') ===
                                null
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {currentZipCodeOptions?.map((zipcode) => {
                                return (
                                  <Select.Option value={zipcode.value}>
                                    {zipcode.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>{address.currentZipCode || '-'}</Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={12}>
              {selectedCurrentCountry === 'ไทย' && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <FormInput
                      title="Province"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="currentProvince"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="currentProvince"
                              placeholder="Select province"
                              onSelect={setCurrentProvince}
                              onSearch={(search) =>
                                getProvinceOptions(search, 'current')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {currentProvinceOptions?.map((province) => {
                                return (
                                  <Select.Option value={province.value}>
                                    {province.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.currentProvince || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <FormInput
                      title="Subdistrict"
                      component={
                        <Form.Item
                          style={{ width: '100%', margin: '0' }}
                          name="currentSubdistrict"
                        >
                          {isEditMode ? (
                            <Select
                              showSearch
                              id="currentSubdistrict"
                              placeholder="Select subdistrict"
                              onSelect={setCurrentSubdistrict}
                              disabled={
                                form.getFieldValue('currentDistrict') === null
                              }
                              onSearch={(search) =>
                                getSubdistrictOptions(search, 'current')
                              }
                              onClick={() => {
                                setIsChanged(true);
                              }}
                            >
                              {currentSubDistrictOptions?.map((subdistrict) => {
                                return (
                                  <Select.Option value={subdistrict.value}>
                                    {subdistrict.value}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Text small12>
                              {address.currentSubdistrict || '-'}
                            </Text>
                          )}
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
        {isEditMode ? (
          <Col span={24}>
            <Row gutter={[8, 8]} justify="end" align="middle">
              <Col flex>
                <Button
                  className="button-outlined"
                  form="address"
                  onClick={() => {
                    form.resetFields();
                    changeEditMode();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col flex>
                <Button
                  form="address"
                  type="primary"
                  className="button-primary"
                  onClick={() => form.submit()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        ) : (
          userState?.currentRoleId === 2 && (
            <Col span={24} style={{ padding: 0 }}>
              <Row gutter={[8, 8]} justify="end" align="middle">
                <Col flex>
                  <Button
                    type="primary"
                    width="96px"
                    className="button-primary"
                    icon={<EditOutlined />}
                    onClick={() => {
                      getCountryOptions(null, 'registered');
                      getProvinceOptions(null, 'registered');
                      getDistrictOptions(null, 'registered');
                      getSubdistrictOptions(null, 'registered');
                      getZipCodeOptions(null, 'registered');
                      getCountryOptions(null, 'current');
                      getProvinceOptions(null, 'current');
                      getDistrictOptions(null, 'current');
                      getSubdistrictOptions(null, 'current');
                      getZipCodeOptions(null, 'current');
                      changeEditMode();
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            </Col>
          )
        )}
      </Row>
    </Form>
  );
};
export default AddressInformation;
