import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

export const Input = styled(AntdInput)`
  width: 100%;
  height: 2rem;
  //border: 1px solid #e8e8eb;
  -webkit-appearance: textfield; /* Remove spinner arrows for number input */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
  font-size: 12px;
`;
