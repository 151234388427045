import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const Switch = styled(AntdSwitch)`
  font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
  background-color: #f4364c;
  &.ant-switch-checked {
    background: #22b14c;
  }
`;
