//Modified flag

export const FlagEnum = {
  //Announcement
  announceViewPage: 1, //Primary Actions
  announceCreate: 2,
  announceEdit: 3,
  announceDelete: 4,
  announcePublish: 5,

  //Holiday
  holidayViewPage: 6, //Primary Actions
  holidayCreate: 7,
  holidayDelete: 8,

  //Leave
  leaveViewPage: 9, //Primary Actions

  //Medical Claim
  medicalClaimViewPage: 11, //Primary Actions
  medicalClaimApproveRejectRequest: 12,
  medicalClaimExportReport: 13,

  //Payslip
  payslipViewPage: 14, //Primary Actions
  payslipUpload: 15,
  payslipEditSettings: 16,

  //Project
  projectViewPage: 17, //Primary Actions
  projectCreate: 18,
  projectEdit: 19,
  projectDelete: 20,
  projectManageOTDate: 21, // Add edit delete OT date
  projectManageMember: 22, // Add edit delete view timesheet project member

  //Report
  reportViewPage: 23, //Primary Actions

  //Reference Letter
  referenceLetterViewPage: 24, //Primary Actions
  referenceLetterApproveOrReject: 25,

  //Role & Permission
  roleViewPage: 26, //Primary Actions
  roleCreate: 27,
  roleEdit: 28,
  roleDelete: 29,

  //Timesheet
  timesheetViewPage: 30, //Primary Actions

  //Settings
  settingsViewPage: 31, //Primary Actions
  settingsCreateRecord: 32,
  settingsEditRecord: 33,
  settingsDeleteRecord: 34,

  //User
  userViewPage: 35, //Primary Actions
  userCreate: 36,
  userEdit: 37,
  userExportReport: 38,
};
