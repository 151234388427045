import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import peopleIcon from '../assets/icons/peopleIcon.svg';
import peopleIconSmall from '../assets/icons/peopleIconSmall.svg';

import { Text } from './';

import Icon, {
  SoundFilled,
  SoundOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  SmileFilled,
  SmileOutlined,
  FilePptFilled,
  FilePptOutlined,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  MedicineBoxFilled,
  MedicineBoxOutlined,
  FileTextFilled,
  FileTextOutlined,
  ProjectFilled,
  ProjectOutlined,
  CalendarOutlined,
  CalendarFilled,
  ContactsOutlined,
  ContactsFilled,
  SettingOutlined,
  SettingFilled,
  BugOutlined,
  BugFilled,
  IdcardOutlined,
  IdcardFilled,
} from '@ant-design/icons';
import { FeatureFlags } from './context/FeatureFlagContext.js';

const { Sider } = Layout;

const Sidebar = styled(Sider)`
  &.ant-layout-sider {
    background-color: #1b1d36;
  }
  &.ant-layout-sider > .ant-layout-sider-children > .ant-menu {
    background-color: #1b1d36;
  }
  &.ant-layout-sider > .ant-layout-sider-children > .ant-menu > .ant-menu-item {
    background-color: #1b1d36;
  }
  &.ant-layout-sider > .ant-layout-sider-trigger {
    background-color: #1b1d36;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: #32344a;
    border-right: 4px solid #f4364c;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected .anticon {
    color: #f4364c !important;
  }
  top: 0,
  left: 0,
  background: '#1B1D36',
  minHeight: 100vh,
  height: 100%,
`;

const Navbar = ({ selectedNavbar, collapsed, setCollapsed }) => {
  const { features } = useContext(FeatureFlags);

  const items = [
    {
      path: '/announce',
      icon: SoundOutlined,
      iconActive: SoundFilled,
      label: 'Announce',
      flag: features?.announceViewPage,
    },
    {
      path: '/timesheet',
      icon: ClockCircleOutlined,
      iconActive: ClockCircleFilled,
      label: 'Timesheet',
      flag: features?.timesheetViewPage,
    },
    {
      path: '/leave',
      icon: SmileOutlined,
      iconActive: SmileFilled,
      label: 'Leave',
      flag: features?.leaveViewPage,
    },
    {
      path: '/payslip',
      icon: FilePptOutlined,
      iconActive: FilePptFilled,
      label: 'Pay Slip',
      flag: features?.payslipViewPage,
    },
    {
      path: '/reference-letter',
      icon: SafetyCertificateOutlined,
      iconActive: SafetyCertificateFilled,
      label: 'Reference Letter',
      flag: features?.referenceLetterViewPage,
    },
    {
      path: '/medical-claim',
      icon: MedicineBoxOutlined,
      iconActive: MedicineBoxFilled,
      label: 'Medical Claim',
      flag: features?.medicalClaimViewPage,
    },
    {
      path: '/report',
      icon: FileTextOutlined,
      iconActive: FileTextFilled,
      label: 'Report',
      flag: features?.reportViewPage,
    },
    {
      path: '/project',
      icon: ProjectOutlined,
      iconActive: ProjectFilled,
      label: 'Project',
      flag: features?.projectViewPage,
    },
    {
      path: '/holiday',
      icon: CalendarOutlined,
      iconActive: CalendarFilled,
      label: 'Holidays',
      flag: features?.holidayViewPage,
    },
    {
      path: '/user',
      icon: ContactsOutlined,
      iconActive: ContactsFilled,
      label: 'User',
      flag: features?.userViewPage,
    },
    {
      path: '/role',
      icon: IdcardOutlined,
      iconActive: IdcardFilled,
      label: 'Role & Permission',
      flag: features?.roleViewPage,
    },
    {
      path: '/settings',
      icon: SettingOutlined,
      iconActive: SettingFilled,
      label: 'Settings',
      flag: features?.settingsViewPage,
    },
    {
      path: '/helpdesk',
      icon: BugOutlined,
      iconActive: BugFilled,
      label: 'Help Desk',
      flag: true,
    },
  ];

  const filterMenuItems = (items) => {
    return items;
  };
  return (
    <Sidebar
      width={160}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        position: 'fixed',
        zIndex: '1011',
        height: 'calc(100vh - 48px)',
        overflowY: 'auto',
        scrollbarColor: '#FFFFFF #1b1d36',
      }}
    >
      <NavLink
        to="/"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <img
          src={collapsed ? peopleIconSmall : peopleIcon}
          width={collapsed ? '32px' : '120px'}
          height={collapsed ? '32px' : '24px'}
        />
      </NavLink>
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={[`navbar${selectedNavbar}`]}
      >
        {filterMenuItems(items).map(
          ({ path, icon, iconActive, label, flag }) =>
            flag && (
              <Menu.Item
                style={{
                  height: '28px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                key={`navbar${label}`}
                icon={
                  selectedNavbar === label ? (
                    <Icon component={iconActive} />
                  ) : (
                    <Icon component={icon} />
                  )
                }
              >
                <Text small12>{label}</Text>
                <NavLink to={path} />
              </Menu.Item>
            ),
        )}
        {/*items.map(({ path, icon, iconActive, label }) =>
          role !== 'ADMIN' &&
          (path === '/settings' ||
            path === '/role' ||
            path === '/role/roleForm/?roleId=') ? (
            <></>
          ) : (
            <Menu.Item
              key={`navbar${label}`}
              icon={
                selectedNavbar === label ? (
                  <Icon component={iconActive} />
                ) : (
                  <Icon component={icon} />
                )
              }
            >
              <Text small12>{label}</Text>
              <NavLink to={path} />
            </Menu.Item>
          ),
            )*/}
      </Menu>
    </Sidebar>
  );
};

export default Navbar;
