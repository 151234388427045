import React from 'react';
import { Authentication } from '../services/api';
import { subDomain } from '../services/redirect';
import { Button, Col, Form, Row, message } from 'antd';
import loginBg from '../assets/images/login_bg.png';
import peopleIconSmall from '../assets/icons/peopleIconSmall.svg';
import { FormInput, Text, Input } from '../components';
import { GoogleLogin } from '@react-oauth/google';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

const Login = () => {
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginWithEmail = async (values) => {
    const payload = {
      username: values.username,
      password: values.password,
    };

    setIsLoading(true);

    Authentication.signInWithEmail(
      payload,
      ({ data }) => {
        sessionStorage.setItem('access-token', data.accessToken);
        sessionStorage.setItem('refresh-token', data.refreshToken);
        setIsLoading(false);
        window.location = `${subDomain}/announce`;
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          const key = 'login';
          message.error({
            content: 'Login failed' + ' - ' + response.data.message,
            key,
          });
        }
      },
    );
  };

  const handleLoginWithGoogle = (response) => {
    setIsLoading(true);
    const payload = {
      token: response.credential,
      device: 'web',
    };
    Authentication.signInWithGoogle(
      payload,
      ({ data }) => {
        sessionStorage.setItem('access-token', data.accessToken);
        sessionStorage.setItem('refresh-token', data.refreshToken);
        setIsLoading(false);
        window.location = `${subDomain}/announce`;
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error('Login failed' + ' - ' + response.data.message);
        }
      },
    );
  };

  const responseGoogle = (response) => {
    if (response && response.status === 400) {
      message.error('Login with Google failed' + ' - ' + response.data.message);
    }
  };

  return (
    <div
      style={{
        background: 'linear-gradient(180deg, #1B1D36 15.53%, #131419 100%)',
        height: '100vh',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 12 }}
          style={{
            backgroundImage: `url(${loginBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '460px',
            height: '520px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px 0 0 10px',
          }}
        />
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          style={{
            display: 'flex',
            background: 'white',
            maxWidth: '460px',
            height: '520px',
            paddingTop: 20,
            borderRadius: '0 10px 10px 0',
          }}
          className="custom-col"
        >
          <Form
            onFinish={handleLoginWithEmail}
            form={form}
            style={{ maxWidth: '460px' }}
          >
            <Row gutter={[16, 16]} justify="center">
              <Col>
                <img src={peopleIconSmall} style={{ width: '72px' }}></img>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center">
              <Col>
                <Text h2 className="text-normal">
                  Welcome to PlayPeople
                </Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="center">
              <Col>
                <Text small12 className="text-grey">
                  Sign in to your account
                </Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ padding: 30 }}>
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={24}>
                    <FormInput
                      title="Email"
                      required
                      component={
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Email',
                            },
                            {
                              type: 'email',
                              message: 'Please input valid Email',
                            },
                          ]}
                          style={{ margin: 0 }}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={24}>
                    <FormInput
                      title="Password"
                      required
                      component={
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Password',
                            },
                          ]}
                          style={{ margin: 0 }}
                        >
                          <Input.Password placeholder="Password" />
                        </Form.Item>
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={24}>
                    <Form.Item style={{ marginBottom: '0' }}>
                      <Button
                        className="button-primary-red"
                        type="primary"
                        htmlType="submit"
                        style={{
                          height: '38px',
                          width: '100%',
                          borderRadius: '4px',
                        }}
                        loading={isLoading}
                      >
                        Sign in
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center">
                  <Text small12 className="text-normal">
                    or continue with
                  </Text>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={24}>
                    <GoogleLogin
                      onSuccess={(response) => handleLoginWithGoogle(response)}
                      onError={(response) => responseGoogle(response)}
                      locale="en_US"
                      width="420px"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </div>
      <div style={{ display: 'flex' }}>
        <Col span={24} style={{ marginTop: '32px' }}>
          <Row gutter={[16, 16]} justify="center">
            <Text small12 className="text-placeholder">
              © 2024 Playtorium Solutions Company Limited
            </Text>
          </Row>
          <Row gutter={[16, 16]} justify="center">
            <Text small12 className="text-placeholder">
              (Speed x Quality x Consistency)
            </Text>
          </Row>
        </Col>
      </div>
    </div>
  );
};
export default Login;
