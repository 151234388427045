import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Input,
  Row,
  Col,
  message,
  Popover,
  Popconfirm,
  Layout,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { ProjectService } from '../services/api';
import { AppContext } from '../components/context/AppContext';
import { NavLink } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Select, Tag, Text } from '../components';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import moment from 'moment';

const { Content } = Layout;

const { Search } = Input;

const Project = () => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [data, setData] = useState([]);
  const [dataMain, setDataMain] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [type, setType] = useState(0);
  const [isLoading, setIsLoading] = useState([]);
  const [searchWord, setSearchWord] = useState();
  const [status, setStatus] = useState(true);

  const defaultStringCompare = (property) => (a, b) =>
    String(a[property]).localeCompare(String(b[property]));

  const columns = [
    {
      key: 'projectNo',
      title: 'Project No.',
      dataIndex: 'projectNo',
      width: '100px',
      fixed: 'left',
      sorter: defaultStringCompare('projectNo'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Project Name',
      key: 'projectName',
      dataIndex: 'projectName',
      width: '360px',
      sorter: defaultStringCompare('projectName'),
      render: (name, record) => (
        <NavLink to={'/project/projectform/?id=' + record.id}>
          <Text sub4 capitalize underline className="text-primary">
            {name}
          </Text>
        </NavLink>
      ),
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customerName',
      width: '160px',
      sorter: defaultStringCompare('customerName'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Project Type',
      key: 'projectTypeName',
      dataIndex: 'projectTypeName',
      width: '100px',
      sorter: defaultStringCompare('projectTypeName'),
      render: (value) => (
        <Text small12 capitalize className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Duration',
      key: 'startDate',
      dataIndex: 'startDate',
      width: '180px',
      sorter: defaultStringCompare('startDate'),
      render: (value, record) => (
        <Text small12 className="text-normal">
          {record.startDate
            ? `${moment(record.startDate).format('DD/MM/YYYY')} ${
                record.endDate
                  ? ` - ${moment(record.endDate).format('DD/MM/YYYY')}`
                  : ' - Present'
              }`
            : '-'}
        </Text>
      ),
    },
    {
      title: 'Status',
      key: 'isActive',
      fixed: 'right',
      dataIndex: 'isActive',
      width: '80px',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.isActive - b.isActive,
      render: (value) => {
        return value ? (
          <Tag green>
            <Text small12>Active</Text>
          </Tag>
        ) : (
          <Tag grey>
            <Text small12>Inactive</Text>
          </Tag>
        );
      },
    },
    features?.projectDelete && {
      title: 'Action',
      fixed: 'right',
      align: 'center',
      width: '60px',
      render: (key, record) =>
        record.isDeletable ? (
          <>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this project? This action can't be undone."
              okButtonProps={{
                style: {
                  backgroundColor: '#004368',
                  borderColor: '#2d95a8',
                },
              }}
              onConfirm={() => {
                handleDeleteProject(record.id);
              }}
              onCancel={() => {}}
            >
              <DeleteOutlined className="text-secondary-red" />
            </Popconfirm>
          </>
        ) : (
          ''
        ),
    },
  ].filter(Boolean);

  const fetchProjectType = () => {
    ProjectService.getAllProjectType(
      ({ data }) => {
        data.unshift({
          id: 0,
          isActived: true,
          projectTypeName: 'All Type',
        });
        setProjectType(data);
      },
      (response) => {
        if (response) {
          message.error(
            `Failed to fetch project type: - ${response.data.message}`,
          );
        }
      },
    );
  };

  const fetchProjects = () => {
    setIsLoading(true);
    if (userState.currentRoleId !== 2) {
      ProjectService.fetchProjectList(
        {},
        ({ data }) => {
          setIsLoading(false);
          setDataMain(data);
          setData({
            ...data,
            projectList: data.projectList.filter((p) => p.isActive == true),
          });
        },
        (response) => {
          setIsLoading(false);
          setData(null);

          if (response) {
            message.error(`Failed to fetch project: ${response.data.message}`);
          }
        },
      );
    } else {
      ProjectService.fetchProjectListByUser(
        ({ data }) => {
          setIsLoading(false);
          setDataMain(data);
          setData({
            ...data,
            projectList: data.projectList.filter((p) => p.isActive == true),
          });
        },
        (response) => {
          setIsLoading(false);
          setData(null);

          if (response) {
            message.error(`Failed to fetch project: ${response.data.message}`);
          }
        },
      );
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchProjectType();
  }, [userState]);

  const handleDeleteProject = (id) => {
    setIsLoading(true);
    ProjectService.deleteProject(
      id,
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Project has been deleted successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          fetchProjects();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response) {
          message.error('Failed to delete project: ' + response.data.message);
        }
      },
    );
  };

  const onSearch = (value) => {
    setSearchWord(value);
    filterChange(type, value, status);
  };

  const onStatusFilterChange = (value) => {
    setStatus(value);
    filterChange(type, searchWord, value);
  };

  const onTypeFilterChange = (value) => {
    setType(value);
    filterChange(value, searchWord, status);
  };

  const filterChange = (type, search, status) => {
    setData({
      ...data,
      projectList: dataMain.projectList
        .filter((data) => {
          if (!search) {
            return true;
          } else {
            return (
              String(data.projectNo)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.projectName)
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              String(data.customerName)
                .toUpperCase()
                .includes(search.toUpperCase())
            );
          }
        })
        .filter((data) => {
          if (status !== null) {
            return data.isActive == status;
          } else {
            return true;
          }
        })
        .filter((data) => {
          if (type != 0) {
            return data.projectTypeId == type;
          } else {
            return true;
          }
        }),
    });
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Project
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <Select
                        defaultValue={0}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          onTypeFilterChange(value);
                        }}
                      >
                        {projectType.map((type) => {
                          if (type.isActived) {
                            return (
                              <Select.Option value={type.id}>
                                {type.projectTypeName}
                              </Select.Option>
                            );
                          }
                        })}
                      </Select>
                    </Col>
                    <Col flex>
                      <Select
                        defaultValue={true}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          onStatusFilterChange(value);
                        }}
                      >
                        <Select.Option value={null}>All Status</Select.Option>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Col>
                    <Col
                      flex="auto"
                      style={{
                        maxWidth: 320,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Search
                        placeholder="Search"
                        onChange={(e) => onSearch(e.target.value)}
                        allowClear={true}
                        style={{ width: '100%', maxWidth: 320 }}
                      />
                    </Col>
                    {features?.projectCreate && (
                      <Col>
                        <NavLink
                          to={`./project/projectform/`}
                          onClick={() => {
                            sessionStorage.removeItem('add-projectform-state');
                          }}
                        >
                          <Button
                            type="primary"
                            width="96px"
                            className="button-primary"
                            icon={<PlusOutlined />}
                          >
                            Create Project
                          </Button>
                        </NavLink>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {userState?.currentRoleId !== 2 && (
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24} span={1}>
                        <Text small12 className="text-primary">
                          All Project
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {data?.totalProject}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24}>
                        <Text small12 style={{ color: '#08B424' }}>
                          Active
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {data?.totalActiveProject}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={8}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24}>
                        <Text small12 className="text-secondary-red">
                          Inactive
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {data?.totalInActiveProject}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Table
                columns={columns}
                scroll={{
                  x: 300,
                  y:
                    userState.currentRoleId === 2
                      ? 'calc(100vh - 220px)'
                      : 'calc(100vh - 340px)',
                }}
                dataSource={data?.projectList}
                rowKey="id"
                size="small"
                loading={isLoading}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '30', '40'],
                  locale: { items_per_page: '/ page' },
                  position: ['bottomRight'],
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Project;
