import React, { Component, useContext } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Space } from 'antd';
import Icon, { EditOutlined, UserAddOutlined } from '@ant-design/icons';
import editIcon from '../../assets/icons/edit.svg';
import saveIcon from '../../assets/icons/saveBlue.svg';
import addUserIcon from '../../assets/icons/addUser.svg';
import { FeatureFlags } from '../../components/context/FeatureFlagContext';

const Headdetail = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 26px;
  font-weight: 500;
  color: #ffffff;
`;

const ProfileTabHeader = ({
  title,
  icon,
  isInEditMode,
  changeEditMode,
  formId,
  addUser,
  cancelAddUser,
}) => {
  const { features } = useContext(FeatureFlags);
  return (
    <div style={{ width: '100%', position: 'relative', marginTop: '2rem' }}>
      <Row
        justify="space-around"
        align="middle"
        style={{
          backgroundColor: '#004368',
          height: '80px',
          position: 'absolute',
          top: '38px',
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        <Col>
          <img src={icon} style={{ margin: '0 1rem 0 2rem' }} />
        </Col>
        <Col>
          <Headdetail>{title}</Headdetail>
        </Col>
        <Col flex="auto" />
        <Col style={{ marginRight: '40px' }}>
          {isInEditMode ? (
            <>
              <Space size={12}>
                <Button
                  className="button-outlined"
                  onClick={addUser ? cancelAddUser : changeEditMode}
                >
                  Cancel
                </Button>
                <Button
                  icon={addUser && <UserAddOutlined />}
                  form={formId}
                  htmlType="submit"
                  className="button-primary"
                  type="primary"
                >
                  {addUser ? 'Create User' : 'Submit'}
                </Button>
              </Space>
            </>
          ) : (
            features?.userEdit && (
              <Col>
                <Button
                  className="button-outlined"
                  icon={<EditOutlined />}
                  onClick={changeEditMode}
                >
                  Edit
                </Button>
              </Col>
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProfileTabHeader;
