import styled from 'styled-components';
import { Segmented as AntdSegmented } from 'antd';

export const Segmented = styled(AntdSegmented)`
  &.ant-segmented {
    background-color: #ffffff;
    border: 1px solid #eaeaea !important;
    border-radius: 5px;
  }

  &.ant-segmented:not(.ant-segmented-disabled):hover {
    background-color: #ffffff;
  }

  .ant-segmented-item-selected {
    background-color: #f4364c !important;
    color: #ffffff;
  }

  .ant-segmented-item {
    transition: #f4364c 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-segmented-thumb {
    background-color: #f4364c;
  }
`;
