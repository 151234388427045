import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Table, Select, Tag, Text } from '../components';
import { Layout, Input, Row, Col, message, Popconfirm, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { AnnounceService } from '../services/api';
import { TextFormatter } from '../components/TextFormatter';

import hbdBanner from '../assets/images/hbdBanner.svg';
import {
  HeartFilled,
  ClockCircleOutlined,
  ArrowRightOutlined,
  SmileOutlined,
  FilePptOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { FeatureFlags } from '../components/context/FeatureFlagContext.js';

const { Content } = Layout;

const Announce = () => {
  const { features } = useContext(FeatureFlags);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('active');
  const [type, setType] = useState('');
  const [search, setSearch] = useState('');

  const history = useHistory();

  const columns = [
    {
      title: 'Announce Name',
      dataIndex: 'announcementName',
      index: 'announcementName',
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.announcementName.localeCompare(b.announcementName),
      },
      render: (name, record) => (
        <a
          onClick={() =>
            history.push('/announce/announceDetail/?id=' + record.id)
          }
        >
          {name.length > 75 ? (
            <Text sub4 capitalize underline className="text-primary">
              {TextFormatter.normalText(name.substring(0, 74).concat('...'))}
            </Text>
          ) : (
            <Text sub4 capitalize underline className="text-primary">
              {TextFormatter.normalText(name)}
            </Text>
          )}
        </a>
      ),
    },
    features?.announceViewInformation && {
      title: 'Type',
      index: 'type',
      dataIndex: 'type',
      width: '160px',
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
      },
      render: (type) => (
        <Text small12 capitalize className="text-normal">
          {type}
        </Text>
      ),
    },
    features?.announceViewInformation && {
      title: 'Status',
      index: 'isActived',
      dataIndex: 'isActived',
      width: '160px',
      render: (isActived) =>
        isActived ? <Tag green>Active</Tag> : <Tag grey>Inactive</Tag>,
    },
    features?.announceDelete && {
      title: 'Action',
      index: 'action',
      align: 'center',
      width: '80px',
      render: (key, record) =>
        key && (
          <Popconfirm
            title="Are you sure you want to delete this announcement?"
            onConfirm={() => {
              handleDeleteAnnounce(record.id);
            }}
          >
            <DeleteOutlined className="text-secondary-red" />
          </Popconfirm>
        ),
    },
  ].filter(Boolean);

  useEffect(() => {
    handleFetchAnnouncementList();
  }, [search, type, status]);

  const handleFetchAnnouncementList = () => {
    const params = {
      status: status,
      type: type,
      search: search,
    };
    AnnounceService.fetchAnnouncementList(
      params,
      ({ data }) => {
        setData(data);
        setIsLoading(false);
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          if (response.data.message != 'empty array') {
            message.error('Cannot access data: ' + response.data.message);
          }
        }
      },
    );
  };

  const handleDeleteAnnounce = (id) => {
    AnnounceService.deleteAnnouncement(
      id,
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Announce has been deleted successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          handleFetchAnnouncementList();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Error: ' + response.data.message);
        }
      },
    );
  };

  return (
    <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
      <Content
        style={{
          margin: '32px 20px 0 20px',
          overflow: 'initial',
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ marginBottom: '16px' }}>
            <div
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                background: 'linear-gradient(90deg, #430098 0%, #C66B00 100%)',
              }}
            >
              <Row gutter={[16, 16]} align="middle" justify="center">
                <Col>{<img src={hbdBanner} style={{ height: '40px' }} />}</Col>
                <Col>
                  <Row gutter={[16, 16]} align="middle" justify="center">
                    <Col>
                      <HeartFilled
                        className="text-white"
                        style={{ color: '#E6C3AB' }}
                      />
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                      <Text small12 style={{ color: '#E6C3AB' }}>
                        Here's to another year of good health and happiness.
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col flex>
                <Text h4 className="text-normal">
                  Suggestion
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginBottom: '16px' }}>
            <Row gutter={[16, 16]}>
              <Col xs="auto" style={{ maxWidth: '200px' }}>
                <Row
                  className="card-container-bg"
                  gutter={[16, 16]}
                  style={{ margin: 'auto' }}
                >
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align="middle">
                      <Col>
                        <ClockCircleOutlined className="text-white" />
                      </Col>
                      <Col>
                        <Text sub4 className="text-white">
                          Timesheet
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <NavLink to="/timesheet">
                      <Row gutter={[4, 4]} align="middle">
                        <Col>
                          <Text sub4 underline className="text-white">
                            Go to Timesheet
                          </Text>
                        </Col>
                        <Col>
                          <ArrowRightOutlined
                            className="text-white"
                            style={{ fontSize: '10px' }}
                          />
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" style={{ maxWidth: '200px' }}>
                <Row
                  className="card-container"
                  gutter={[16, 16]}
                  style={{ margin: 'auto' }}
                >
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align="middle">
                      <Col>
                        <SmileOutlined className="text-secondary-red" />
                      </Col>
                      <Col>
                        <Text sub4 className="text-normal">
                          Leave
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <NavLink to="/leave">
                      <Row gutter={[4, 4]} align="middle">
                        <Col>
                          <Text sub4 underline className="text-secondary-red">
                            Go to Leave
                          </Text>
                        </Col>
                        <Col>
                          <ArrowRightOutlined
                            className="text-secondary-red"
                            style={{ fontSize: '10px' }}
                          />
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" style={{ maxWidth: '200px' }}>
                <Row
                  className="card-container"
                  gutter={[16, 16]}
                  style={{ margin: 'auto' }}
                >
                  <Col xs={24}>
                    <Row gutter={[8, 8]} align="middle">
                      <Col>
                        <FilePptOutlined className="text-secondary-red" />
                      </Col>
                      <Col>
                        <Text sub4 className="text-normal">
                          Pay Slip
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <NavLink to="/payslip">
                      <Row gutter={[4, 4]} align="middle">
                        <Col>
                          <Text sub4 underline className="text-secondary-red">
                            Go to Pay Slip
                          </Text>
                        </Col>
                        <Col>
                          <ArrowRightOutlined
                            className="text-secondary-red"
                            style={{ fontSize: '10px' }}
                          />
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col flex>
                <Text h4 className="text-normal">
                  Announce
                </Text>
              </Col>
              <Col flex="auto">
                <Row gutter={[8, 8]} justify="end">
                  {features?.announceViewInformation && (
                    <Col>
                      <Select
                        value={status}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          setStatus(value);
                        }}
                      >
                        <Select.Option value="both">All Status</Select.Option>
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="inactive">Inactive</Select.Option>
                      </Select>
                    </Col>
                  )}
                  {features?.announceViewInformation && (
                    <Col>
                      <Select
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          setType(value);
                        }}
                        defaultValue={''}
                      >
                        <Select.Option value="">All Type</Select.Option>
                        <Select.Option value="news">News</Select.Option>
                        <Select.Option value="social security">
                          Social security
                        </Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Col>
                  )}

                  <Col
                    flex="auto"
                    style={{
                      maxWidth: 320,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Search
                      placeholder="Search"
                      onSearch={(value) => {
                        setSearch(value);
                      }}
                      allowClear={true}
                      style={{ width: '100%', maxWidth: 320, height: '32px' }}
                      loading={isLoading}
                    />
                  </Col>
                  {features?.announceCreate && (
                    <Col>
                      <NavLink to={`./announce/announceDetail/`}>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          icon={<PlusOutlined />}
                        >
                          Create Announce
                        </Button>
                      </NavLink>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 500 }}
              size="small"
              loading={isLoading}
              pagination={{
                position: ['bottomRight'],
                pageSizeOptions: [10, 20, 30, 40],
                showSizeChanger: true,
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
export default Announce;
