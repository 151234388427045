import styled from 'styled-components';
import { Tag as AntdTag } from 'antd';

export const Tag = styled(AntdTag)`
  font-size: 12px;
  line-height: 24px;
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  border: none;
  padding: 2px 8px;
  margin: 0;
  color: ${(props) =>
    props.green
      ? '#219653'
      : props.grey
      ? '#878787'
      : props.red
      ? '#CA003C'
      : props.yellow
      ? '#D87A16'
      : props.blue
      ? '#0071FF'
      : undefined};
  background-color: ${(props) =>
    props.green
      ? '#B2F4D5'
      : props.grey
      ? '#EBEBEB'
      : props.red
      ? '#FFCACB'
      : props.yellow
      ? '#FAE3B7'
      : props.blue
      ? '#D9EAFF'
      : undefined};
`;
