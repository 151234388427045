export const TextFormatter = {
  // TODO this should be /Utilities
  normalText: (text) => {
    if (!text) return '';
    return text === 'null' ? '-' : text;
  },
  capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  },
};
