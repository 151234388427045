import { useForm } from 'antd/lib/form/Form';
import React, { useState, useEffect, useContext } from 'react';
import { ImageService, MasterDataService } from '../../services/api';
import { Button, Col, Form, message, Row } from 'antd';
import FormInput from '../FormInput';
import { Input } from '../Input';
import { Text } from '../Text';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { DatePicker } from '../DatePicker';
import { Select } from '../Select';
import Uploader from '../../pages/components/uploader';
import { Switch } from '../Switch';
import { Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ChangePasswordModal from '../../pages/components/changePasswordModal';
import { FeatureFlags } from '../context/FeatureFlagContext';

const EmployeeInformation = (props) => {
  const {
    currentPage,
    employeeInformation,
    isEditMode,
    changeEditMode,
    onSubmit,
  } = props;
  const [osLicensePicturePath, setOsLicensePicturePath] = useState();
  const [signaturePath, setSignaturePath] = useState();
  const [roleList, setRoleList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [osLicensePicture, setOsLicensePicture] = useState();
  const [signaturePicture, setSignaturePicture] = useState();
  const [isActived, setIsActived] = useState(true);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const [form] = useForm();

  const history = useHistory();

  const enableAllFields = currentPage !== 'profile';
  const { features } = useContext(FeatureFlags);

  useEffect(() => {
    MasterDataService.fetchRoleList({}, async ({ data }) => {
      setRoleList(data);
    });
    MasterDataService.fetchLevelList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setLevelList(data);
    });
    MasterDataService.fetchSupervisorList({}, async ({ data }) => {
      setSupervisorList(data);
    });
    MasterDataService.fetchDepartmentList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setDepartmentList(data);
    });
    MasterDataService.fetchPositionList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setPositionList(data);
    });
    MasterDataService.fetchSectionList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setSectionList(data);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      firstnameEn: employeeInformation.firstnameEn,
      lastnameEn: employeeInformation.lastnameEn,
      firstnameTh: employeeInformation.firstnameTh,
      lastnameTh: employeeInformation.lastnameTh,
      position: employeeInformation.position,
      positionId: employeeInformation.positionId,
      department: employeeInformation.department,
      departmentId: employeeInformation.departmentId,
      section: employeeInformation.section,
      sectionId: employeeInformation.sectionId,
      gender: employeeInformation.gender,
      email: employeeInformation.email,
      nicknameTh: employeeInformation.nicknameTh,
      nicknameEn: employeeInformation.nicknameEn,
      phoneNumber: employeeInformation.phoneNumber,
      mobileNumber: employeeInformation.mobileNumber,
      empCode: employeeInformation.empCode,
      citizenId: employeeInformation.citizenId,
      jlpt: employeeInformation.jlpt,
      toeic: employeeInformation.toeic,
      maritalStatus: employeeInformation.maritalStatus,
      carDrivingLicense: employeeInformation.carDrivingLicense,
      nationality: employeeInformation.nationality,
      notebookOwner: employeeInformation.notebookOwner,
      notebookYear: employeeInformation.notebookYear,
      notebookModel: employeeInformation.notebookModel,
      notebookSerialNo: employeeInformation.notebookSerialNo,
      lineId: employeeInformation.lineId,
      role: employeeInformation.role,
      roleId: employeeInformation.roleId,
      contractType: employeeInformation.contractType,
      level: employeeInformation.level,
      notebookOs: employeeInformation.notebookOs,
      birthday: employeeInformation.birthday
        ? moment(employeeInformation.birthday, 'YYYY-MM-DD')
        : null,
      startJobDate: employeeInformation.startJobDate
        ? moment(employeeInformation.startJobDate, 'YYYY-MM-DD')
        : null,
      endProbationDate: employeeInformation.endProbationDate
        ? moment(employeeInformation.endProbationDate, 'YYYY-MM-DD')
        : null,
      emergencyName: employeeInformation.emergencyName,
      emergencyRelationship: employeeInformation.emergencyRelationship,
      emergencyMobileNumber: employeeInformation.emergencyMobileNumber,
      emergencyAddress: employeeInformation.emergencyAddress,

      militaryStatus: employeeInformation.militaryStatus,
      osLicensePicturePath: employeeInformation.osLicensePicturePath,
      picturePath: employeeInformation.picturePath,
      updateDatetime: employeeInformation.updateDatetime,
      index: employeeInformation.index,
      userId: employeeInformation.userId,
      microsoftOfficeLicensePicturePath:
        employeeInformation.microsoftOfficeLicensePicturePath,
      signaturePath: employeeInformation.signaturePath,
      supervisorId: employeeInformation.supervisorId,
      resignationDate: employeeInformation.resignationDate
        ? moment(employeeInformation.resignationDate, 'YYYY-MM-DD')
        : null,
      isActived: employeeInformation.isActived ?? true,
      isBoi: employeeInformation.isBoi ?? false,
      bankAccountNo: employeeInformation.bankAccountNo,
    });
    if (
      employeeInformation.osLicensePicturePath &&
      employeeInformation.signaturePath
    ) {
      fetchImage(
        employeeInformation.osLicensePicturePath,
        employeeInformation.signaturePath,
      );
    }

    setIsActived(employeeInformation.isActived ?? true);
  }, [employeeInformation]);

  const onFinish = (values) => {
    values.osLicensePicturePath = osLicensePicturePath;
    values.signaturePath = signaturePath;
    setIsChanged(false);
    onSubmit(values);
    changeEditMode();
  };

  const handleOsLicensePicture = (fd, index) => {
    setOsLicensePicturePath(fd);
  };
  const handleSignaturePicture = (fd, index) => {
    setSignaturePath(fd);
  };

  const fetchImage = (osLicensePicturePath, signaturePath) => {
    ImageService.image.getImage(
      osLicensePicturePath,
      (res) => {
        const blobUrl = URL.createObjectURL(res.data);
        setOsLicensePicture(blobUrl);
      },
      (response) => {
        message.error('Failed to get OS license picture');
      },
    );
    ImageService.image.getImage(
      signaturePath,
      (res) => {
        const blobUrl = URL.createObjectURL(res.data);
        setSignaturePicture(blobUrl);
      },
      (response) => {
        message.error('Failed to get signature picture');
      },
    );
  };

  return (
    <Form
      id="personalInformation"
      form={form}
      onFinish={onFinish}
      initialValues={{
        firstnameEn: employeeInformation.firstnameEn,
        lastnameEn: employeeInformation.lastnameEn,
        firstnameTh: employeeInformation.firstnameTh,
        lastnameTh: employeeInformation.lastnameTh,
        position: employeeInformation.position,
        positionId: employeeInformation.positionId,
        department: employeeInformation.department,
        departmentId: employeeInformation.departmentId,
        section: employeeInformation.section,
        sectionId: employeeInformation.sectionId,
        gender: employeeInformation.gender,
        email: employeeInformation.email,
        nicknameTh: employeeInformation.nicknameTh,
        nicknameEn: employeeInformation.nicknameEn,
        phoneNumber: employeeInformation.phoneNumber,
        mobileNumber: employeeInformation.mobileNumber,
        empCode: employeeInformation.empCode,
        citizenId: employeeInformation.citizenId,
        jlpt: employeeInformation.jlpt,
        toeic: employeeInformation.toeic,
        maritalStatus: employeeInformation.maritalStatus,
        carDrivingLicense: employeeInformation.carDrivingLicense,
        nationality: employeeInformation.nationality,
        notebookOwner: employeeInformation.notebookOwner,
        notebookYear: employeeInformation.notebookYear,
        notebookModel: employeeInformation.notebookModel,
        notebookSerialNo: employeeInformation.notebookSerialNo,
        lineId: employeeInformation.lineId,
        role: employeeInformation.role,
        roleId: employeeInformation.roleId,
        contractType: employeeInformation.contractType,
        level: employeeInformation.level,
        notebookOs: employeeInformation.notebookOs,
        birthday: employeeInformation.birthday
          ? moment(employeeInformation.birthday, 'YYYY-MM-DD')
          : null,
        startJobDate: employeeInformation.startJobDate
          ? moment(employeeInformation.startJobDate, 'YYYY-MM-DD')
          : null,
        endProbationDate: employeeInformation.endProbationDate
          ? moment(employeeInformation.endProbationDate, 'YYYY-MM-DD')
          : null,
        emergencyName: employeeInformation.emergencyName,
        emergencyRelationship: employeeInformation.emergencyRelationship,
        emergencyMobileNumber: employeeInformation.emergencyMobileNumber,
        emergencyAddress: employeeInformation.emergencyAddress,
        militaryStatus: employeeInformation.militaryStatus,
        osLicensePicturePath: employeeInformation.osLicensePicturePath,
        picturePath: employeeInformation.picturePath,
        updateDatetime: employeeInformation.updateDatetime,
        index: employeeInformation.index,
        userId: employeeInformation.userId,
        microsoftOfficeLicensePicturePath:
          employeeInformation.microsoftOfficeLicensePicturePath,
        signaturePath: employeeInformation.signaturePath,
        supervisorId: employeeInformation.supervisorId,
        resignationDate: employeeInformation.resignationDate
          ? moment(employeeInformation.resignationDate, 'YYYY-MM-DD')
          : null,
        isActived: employeeInformation.isActived ?? true,
        isBoi: employeeInformation.isBoi ?? false,
        bankAccountNo: employeeInformation.bankAccountNo,
      }}
    >
      <Row gutter={[16, 16]}>
        <Prompt
          when={isChanged}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24} style={{ padding: 0 }}>
              <Text sub2 className="text-normal">
                Account Information
              </Text>
            </Col>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Employee ID"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="empCode"
                      rules={[
                        {
                          required: true,
                          message: 'Please input Employee ID',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="empCode"
                          placeholder="Enter Employee Code"
                          disabled={!enableAllFields}
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.empCode}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Email"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input Email',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="email"
                          placeholder="Enter Email"
                          disabled={!enableAllFields}
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.email}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Role"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: 0 }}
                      name="roleId"
                      rules={[
                        {
                          required: true,
                          message: 'Select role',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="roleId"
                          placeholder="Select role"
                          onChange={() => setIsChanged(true)}
                          disabled={!enableAllFields}
                        >
                          {roleList?.map((value) => {
                            return (
                              <Select.Option value={value.id}>
                                {value.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>{employeeInformation.role}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="User Status"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="isActived"
                      valuePropName="checked"
                    >
                      {isEditMode ? (
                        <Switch
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          id="isActived"
                          disabled={!enableAllFields}
                          onChange={(value) => {
                            if (value === true) {
                              form.setFieldValue('resignationDate', null);
                            }
                            setIsActived(value);
                          }}
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.isActived
                            ? 'Active'
                            : 'Inactive'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Password"
                  required
                  component={
                    currentPage === 'add' ? (
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Enter password',
                          },
                          () => ({
                            validator(_, value) {
                              if (value.length < 8) {
                                return Promise.reject(
                                  'Your password must be at least 8 characters',
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter password"
                          id="password"
                        />
                      </Form.Item>
                    ) : currentPage === 'profile' ? (
                      isEditMode ? (
                        <Button
                          className="button-primary"
                          type="primary"
                          onClick={handleOpenModal}
                          disabled={!enableAllFields}
                        >
                          Change Password
                        </Button>
                      ) : (
                        <Text small12 className="text-normal">
                          ******
                        </Text>
                      )
                    ) : isEditMode ? (
                      <Button
                        className="button-primary"
                        type="primary"
                        onClick={handleOpenModal}
                      >
                        Change Password
                      </Button>
                    ) : (
                      <Text small12 className="text-normal">
                        ******
                      </Text>
                    )
                  }
                />
              </Col>
              {!isActived && (
                <Col span={12}>
                  <FormInput
                    title="Resignation Date"
                    component={
                      <Form.Item
                        style={{ width: '100%', margin: '0' }}
                        name="resignationDate"
                      >
                        {isEditMode ? (
                          <DatePicker
                            id="resignationDate"
                            placeholder="Select resignation date"
                            format="DD/MM/YYYY"
                            disabled={!enableAllFields}
                            onChange={() => {
                              setIsChanged(true);
                            }}
                          />
                        ) : (
                          <Text small12>
                            {employeeInformation.resignationDate
                              ? moment(
                                  employeeInformation.resignationDate,
                                ).format('DD MMM YYYY')
                              : '-'}
                          </Text>
                        )}
                      </Form.Item>
                    }
                  />
                </Col>
              )}
            </Row>
          </Row>
        </Col>
        {/*May be move to new tab. Have to separate api and db */}
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24} style={{ padding: 0 }}>
              <Text sub2 className="text-normal">
                Contract Information
              </Text>
            </Col>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Position"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="positionId"
                      rules={[
                        {
                          required: true,
                          message: 'Select position',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          disabled={!enableAllFields}
                          id="positionId"
                          placeholder="Select position"
                          onChange={() => setIsChanged(true)}
                        >
                          {positionList?.map((position) => {
                            return (
                              <Select.Option value={position.id}>
                                {position.positionName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>{employeeInformation.position}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Department"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="departmentId"
                      rules={[
                        {
                          required: true,
                          message: 'Select department',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          disabled={!enableAllFields}
                          id="departmentId"
                          placeholder="Select department"
                          onChange={() => setIsChanged(true)}
                        >
                          {departmentList?.map((department) => {
                            return (
                              <Select.Option value={department.id}>
                                {department.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>
                          {employeeInformation.department || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Section"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="sectionId"
                      rules={[
                        {
                          required: true,
                          message: 'Select section',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          disabled={!enableAllFields}
                          id="sectionId"
                          placeholder="Select section"
                          onChange={() => setIsChanged(true)}
                        >
                          {sectionList?.map((section) => {
                            return (
                              <Select.Option value={section.id}>
                                {section.sectionName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>{employeeInformation.section}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Level"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="level"
                      rules={[
                        {
                          required: true,
                          message: 'Select level',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          disabled={!enableAllFields}
                          id="level"
                          placeholder="Select level"
                          onChange={() => setIsChanged(true)}
                        >
                          {levelList?.map((level) => {
                            return (
                              <Select.Option value={level.id}>
                                {level.levelName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>{employeeInformation.levelName}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Start Job Date"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="startJobDate"
                      rules={[
                        {
                          required: true,
                          message: 'Select start job date',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <DatePicker
                          id="startJobDate"
                          placeholder="Select start job date"
                          format="DD/MM/YYYY"
                          disabled={!enableAllFields}
                          onChange={(value) => {
                            const added120Days = value.clone().add(120, 'days');
                            form.setFieldsValue({
                              endProbationDate: added120Days,
                            });
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>
                          {employeeInformation.startJobDate
                            ? moment(employeeInformation.startJobDate).format(
                                'DD MMM YYYY',
                              )
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="End of Probation Date"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="endProbationDate"
                      rules={[
                        {
                          required: true,
                          message: 'Select end probation date',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, endProbationDate) {
                            if (
                              !endProbationDate ||
                              getFieldValue('startJobDate') <= endProbationDate
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                'End probation date must be after start job date',
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <DatePicker
                          id="endProbationDate"
                          format="DD/MM/YYYY"
                          placeholder="Select end probation date"
                          disabledDate={(current) => {
                            return current < form.getFieldValue('startJobDate');
                          }}
                          disabled={!enableAllFields}
                        />
                      ) : (
                        <Text small12>
                          {employeeInformation.endProbationDate
                            ? moment(
                                employeeInformation.endProbationDate,
                              ).format('DD MMM YYYY')
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Supervisor"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="supervisorId"
                      rules={[
                        {
                          required: true,
                          message: 'Select supervisor',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="supervisorId"
                          placeholder="Select supervisor"
                          onChange={() => setIsChanged(true)}
                          disabled={!enableAllFields}
                        >
                          {supervisorList?.map((supervisor) => {
                            return (
                              <Select.Option value={supervisor.userId}>
                                {supervisor.firstnameEn} {supervisor.lastnameEn}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      ) : (
                        <Text small12>
                          {employeeInformation.supervisorName || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Contract Type"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="contractType"
                      rules={[
                        {
                          required: true,
                          message: 'Select contract type',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          disabled={!enableAllFields}
                          id="contractType"
                          placeholder="Select contract type"
                          onChange={() => setIsChanged(true)}
                        >
                          <Select.Option value="Permanent">
                            Permanent
                          </Select.Option>
                          <Select.Option value="Contract">
                            Contract
                          </Select.Option>
                          <Select.Option value="Part Time">
                            Part Time
                          </Select.Option>
                        </Select>
                      ) : (
                        <Text small12>
                          {employeeInformation.contractType || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24} style={{ padding: 0 }}>
              <Text sub2 className="text-normal">
                Personal Information
              </Text>
            </Col>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="First Name (En)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="firstnameEn"
                      rules={[
                        {
                          required: true,
                          message: 'Enter firstname (En)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="firstnameEn"
                          placeholder="Enter firstname (En)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.firstnameEn}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Last Name (En)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="lastnameEn"
                      rules={[
                        {
                          required: true,
                          message: 'Enter lastname (En)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="lastnameEn"
                          placeholder="Enter lastname (En)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.lastnameEn}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="First Name (Th)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="firstnameTh"
                      rules={[
                        {
                          required: true,
                          message: 'Please input firstname (Th)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="firstnameTh"
                          placeholder="Enter firstname (Th)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.firstnameTh}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Last Name (Th)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="lastnameTh"
                      rules={[
                        {
                          required: true,
                          message: 'Enter lastname (Th)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="lastnameTh"
                          placeholder="Enter lastname (Th)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.lastnameTh}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Nickname (En)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="nicknameEn"
                      rules={[
                        {
                          required: true,
                          message: 'Enter nickname (En)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="nicknameEn"
                          placeholder="Enter nickname (En)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.nicknameEn}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Nickname (Th)"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="nicknameTh"
                      rules={[
                        {
                          required: true,
                          message: 'Enter nickname (Th)',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="nicknameTh"
                          placeholder="Enter nickname (Th)"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.nicknameTh}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Citizen Id"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="citizenId"
                      rules={[
                        {
                          required: true,
                          message: 'Please input citizenId',
                        },

                        () => ({
                          validator(_, value) {
                            function containsOnlyNumbers(str) {
                              return /^\d+$/.test(str);
                            }
                            if (
                              currentPage === 'add' &&
                              (!containsOnlyNumbers(value) ||
                                value.length !== 13)
                            ) {
                              return Promise.reject('Invalid citizen id.');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="citizenId"
                          placeholder="Enter citizen id"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                          disabled={!enableAllFields}
                        />
                      ) : (
                        <Text small12>{employeeInformation.citizenId}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Gender"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: 'Select gender',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="gender"
                          placeholder="Select gender"
                          onChange={() => setIsChanged(true)}
                        >
                          <Select.Option value="Male">Male</Select.Option>
                          <Select.Option value="Female">Female</Select.Option>
                        </Select>
                      ) : (
                        <Text small12>{employeeInformation.gender}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Nationality"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Enter nationality',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="nationality"
                          placeholder="Enter nationality"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.nationality}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Birthday"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="birthday"
                      rules={[
                        {
                          required: true,
                          message: 'Select birthday',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value.isAfter(moment())) {
                              return Promise.reject(
                                "Birthday can't be in the future",
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <DatePicker
                          placeholder="Select birthday"
                          id="birthday"
                          format="DD/MM/YYYY"
                        />
                      ) : (
                        <Text small12>
                          {employeeInformation.birthday
                            ? moment(employeeInformation.birthday).format(
                                'DD MMM YYYY',
                              )
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Phone"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="phoneNumber"
                      rules={[
                        () => ({
                          validator(_, value) {
                            function containsOnlyNumbers(str) {
                              return /^\d+$/.test(str);
                            }
                            if (
                              value &&
                              (!containsOnlyNumbers(value) || value.length > 10)
                            ) {
                              return Promise.reject('Invalid phone number.');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="phoneNumber"
                          placeholder="Enter phone"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>
                          {employeeInformation.phoneNumber || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Mobile Phone"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Enter mobile phone',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            function containsOnlyNumbers(str) {
                              return /^\d+$/.test(str);
                            }
                            if (
                              value &&
                              (!containsOnlyNumbers(value) || value.length > 10)
                            ) {
                              return Promise.reject('Invalid phone number.');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="mobileNumber"
                          placeholder="Enter mobile phone"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.mobileNumber}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Signature"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="signaturePath"
                    >
                      {isEditMode ? (
                        <Uploader
                          initialPath={employeeInformation.signaturePath}
                          callbackUpload={handleSignaturePicture}
                        />
                      ) : employeeInformation.signaturePath ? (
                        <img src={signaturePicture} width={102} height={102} />
                      ) : (
                        '-'
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Line Id"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="lineId"
                    >
                      {isEditMode ? (
                        <Input
                          id="lineId"
                          placeholder="Enter line id"
                          onChange={() => {
                            setIsChanged(true);
                          }}
                        />
                      ) : (
                        <Text small12>{employeeInformation.lineId || '-'}</Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24} style={{ padding: 0 }}>
              <Text sub2 className="text-normal">
                Other Information
              </Text>
            </Col>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="TOEIC Score"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="toeic"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (value > 990) {
                              return Promise.reject(
                                'TOEIC maximum score is 990',
                              );
                            } else if (value < 0) {
                              return Promise.reject(
                                `TOEIC score can't be negative`,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="toeic"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter TOEIC score"
                          disabled={!enableAllFields}
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.toeic || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="JLPT Score"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="jlpt"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (value > 100) {
                              return Promise.reject(
                                'JLPT maximum score is 100',
                              );
                            } else if (value < 0) {
                              return Promise.reject(
                                `JLPT score can't be negative`,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="jlpt"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter JLPT score"
                          disabled={!enableAllFields}
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.jlpt || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Marital Status"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="maritalStatus"
                      rules={[
                        {
                          required: true,
                          message: 'Select marital status',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="maritalStatus"
                          placeholder="Select marital status"
                          onChange={() => setIsChanged(true)}
                        >
                          <Select.Option value="Single">Single</Select.Option>
                          <Select.Option value="Married">Married</Select.Option>
                          <Select.Option value="Widowed">Widowed</Select.Option>
                          <Select.Option value="Divorced">
                            Divorced
                          </Select.Option>
                        </Select>
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.maritalStatus || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Military Status"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="militaryStatus"
                      dependencies={['gender']}
                    >
                      {isEditMode ? (
                        <Select
                          id="militaryStatus"
                          placeholder="Select military status"
                          onChange={() => setIsChanged(true)}
                          allowClear
                        >
                          <Select.Option value="Serving">Serving</Select.Option>
                          <Select.Option value="Completed">
                            Completed
                          </Select.Option>
                          <Select.Option value="Exempted">
                            Exempted
                          </Select.Option>
                        </Select>
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.militaryStatus || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Notebook Owner"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="notebookOwner"
                      rules={[
                        {
                          required: true,
                          message: 'Select notebook owner',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="notebookOwner"
                          placeholder="Select notebook owner"
                          onChange={() => setIsChanged(true)}
                        >
                          <Select.Option value="Employee">
                            Employee
                          </Select.Option>
                          <Select.Option value="Company">Company</Select.Option>
                        </Select>
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.notebookOwner || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Notebook OS"
                  required
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="notebookOs"
                      rules={[
                        {
                          required: true,
                          message: 'Select notebook OS',
                        },
                      ]}
                    >
                      {isEditMode ? (
                        <Select
                          id="notebookOs"
                          onChange={() => setIsChanged(true)}
                          placeholder="Select notebook OS"
                        >
                          <Select.Option value="Windows">Windows</Select.Option>
                          <Select.Option value="Macos">MacOs</Select.Option>
                          <Select.Option value="Linux">Linux</Select.Option>
                        </Select>
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.notebookOs || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Bank Account No."
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="bankAccountNo"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            function containsOnlyNumbers(str) {
                              return /^\d+$/.test(str);
                            }
                            if (
                              currentPage === 'add' &&
                              value &&
                              !containsOnlyNumbers(value)
                            ) {
                              return Promise.reject(
                                'Bank account must be numeric',
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="bankAccountNo"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter bank account no."
                          disabled={!enableAllFields}
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.bankAccountNo || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="OS License"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="osLicensePicturePath"
                    >
                      {isEditMode ? (
                        <Uploader
                          initialPath={employeeInformation.osLicensePicturePath}
                          callbackUpload={handleOsLicensePicture}
                        />
                      ) : employeeInformation.osLicensePicturePath ? (
                        <img src={osLicensePicture} width={102} height={102} />
                      ) : (
                        '-'
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Car driving license"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="carDrivingLicense"
                      valuePropName="checked"
                    >
                      {isEditMode ? (
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          id="carDrivingLicense"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.carDrivingLicense ? 'Yes' : 'No'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="BOI"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="isBoi"
                      valuePropName="checked"
                    >
                      {isEditMode ? (
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          id="isBoi"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.isBoi ? 'Yes' : 'No'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} className="card-container">
            <Col span={24} style={{ padding: 0 }}>
              <Text sub2 className="text-normal">
                Emergency Contact
              </Text>
            </Col>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Name"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="emergencyName"
                    >
                      {isEditMode ? (
                        <Input
                          id="emergencyName"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter emergency contact name"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.emergencyName || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Relationship"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="emergencyRelationship"
                    >
                      {isEditMode ? (
                        <Select
                          id="emergencyRelationship"
                          placeholder="Select emergency contact relationship"
                          onChange={() => setIsChanged(true)}
                        >
                          <Select.Option value="Father">Father</Select.Option>
                          <Select.Option value="Mother">Mother</Select.Option>
                          <Select.Option value="Friend">Friend</Select.Option>
                          <Select.Option value="Other">Other</Select.Option>
                        </Select>
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.emergencyRelationship || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12}>
                <FormInput
                  title="Mobile No."
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="emergencyMobileNumber"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            function containsOnlyNumbers(str) {
                              return /^\d+$/.test(str);
                            }
                            if (
                              value &&
                              (!containsOnlyNumbers(value) || value.length > 10)
                            ) {
                              return Promise.reject('Invalid phone number.');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      {isEditMode ? (
                        <Input
                          id="emergencyMobileNumber"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter emergency contact phone"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.emergencyMobileNumber || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
              <Col span={12}>
                <FormInput
                  title="Address"
                  component={
                    <Form.Item
                      style={{ width: '100%', margin: '0' }}
                      name="emergencyAddress"
                    >
                      {isEditMode ? (
                        <Input
                          id="emergencyAddress"
                          onChange={() => setIsChanged(true)}
                          placeholder="Enter emergency contact address"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {employeeInformation.emergencyAddress || '-'}
                        </Text>
                      )}
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
          </Row>
        </Col>

        {isEditMode ? (
          <Col span={24}>
            <Row gutter={[8, 8]} justify="end" align="middle">
              <Col flex>
                <Button
                  className="button-outlined"
                  form="personalInformation"
                  onClick={() => {
                    form.resetFields();
                    if (currentPage !== 'add') {
                      changeEditMode();
                    } else {
                      history.push('/user');
                    }
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col flex>
                <Button
                  form="personalInformation"
                  type="primary"
                  className="button-primary"
                  onClick={() => form.submit()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        ) : (
          features?.userEdit && (
            <Col span={24} style={{ padding: 0 }}>
              <Row gutter={[8, 8]} justify="end" align="middle">
                <Col flex>
                  <Button
                    type="primary"
                    width="96px"
                    className="button-primary"
                    icon={<EditOutlined />}
                    onClick={() => {
                      changeEditMode();
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            </Col>
          )
        )}
      </Row>
      <ChangePasswordModal
        visible={modalVisible}
        closeModal={handleCloseModal}
      />
    </Form>
  );
};
export default EmployeeInformation;
