import React, { useContext, useEffect, useState } from 'react';
import { FeatureFlags } from '../components/context/FeatureFlagContext';
import { AppContext } from '../components/context/AppContext';
import moment from 'moment';
import { MedicalClaimService, ReportService } from '../services/api';
import {
  DownloadOutlined,
  EyeOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Layout, Row, message } from 'antd';
import EditMedicalClaimModal from '../components/medicalClaim/editMedicalClaimModal';
import ExportMedicalClaimModal from '../components/medicalClaim/exportMedicalClaimModal';
import { getMedicalClaimPic, getUserPic } from '../services/image';
import { Text, Tag, Select, DatePicker, Table } from '../components';
import { NavLink } from 'react-router-dom';

const { Content } = Layout;

const MedicalClaim = () => {
  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const [data, setData] = useState();
  const [mainData, setMainData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isReportDownloading, setIsReportDownloading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [record, setRecord] = useState();
  const [remainingValue, setRemainingValue] = useState();
  const [usedValue, setUsedValue] = useState();
  const [year, setYear] = useState(moment().year());

  useEffect(() => {
    fetchMedicalClaimHistory();
  }, [userState, year]);

  const editModalClose = () => {
    setEditModalVisible(false);
    setRecord(null);
  };

  const editModalOpen = async (record) => {
    setEditModalVisible(true);
    setRecord(record);
  };

  const exportModalClose = () => {
    setExportModalVisible(false);
  };

  const onExportReport = async () => {
    setIsReportDownloading(true);
    const payload = {
      year: year,
    };
    await ReportService.fetchMedicalClaimReport(payload, (response) => {
      if (response) message.error(`Error: ${response.data.message}`);
    });
    setIsReportDownloading(false);
  };

  const fetchMedicalClaimHistory = () => {
    setIsLoading(true);
    const payload = {
      year: year,
    };
    if (userState.currentRoleId === 2) {
      MedicalClaimService.getMedicalClaimHistoryByUserId(
        payload,
        ({ data }) => {
          setData(data.medicalClaimHistory);
          setMainData(data.medicalClaimHistory);
          setIsLoading(false);
          setRemainingValue(
            data.medicalClaimRemains.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          );
          setUsedValue(
            data.medicalClaimUsed.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          );
        },
        (response) => {
          setIsLoading(false);
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    } else {
      MedicalClaimService.getAllMedicalClaimHistory(
        payload,
        ({ data }) => {
          setData(data);
          setMainData(data);
          setIsLoading(false);
        },
        (response) => {
          setIsLoading(false);
          if (response) message.error(`Error: ${response.data.message}`);
        },
      );
    }
  };

  const onStatusTypeFilterChange = (value) => {
    if (value === 'All') {
      setData(mainData);
    } else {
      setData(mainData.filter((record) => record.requestStatusName === value));
    }
  };

  const onYearFilterChange = (value) => {
    setYear(moment(value).year());
  };

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const columns = [
    {
      title: 'Doc No.',
      dataIndex: 'requestId',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) => a.medicalClaimRequestId - b.medicalClaimRequestId,
      render: (value, record) => (
        <Text small12 className="text-normal">
          CLM-{moment(record.createDatetime).format('YY')}
          {record.medicalClaimRequestId.toString().padStart(4, '0')}
        </Text>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createDatetime',
      width: '100px',
      defaultSortOrder: 'descend',
      sorter: defaultStringCompare('createDatetime'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    userState.currentRoleId !== 2 && {
      title: 'Emp No.',
      dataIndex: 'employeeId',
      width: '80px',
      sorter: defaultStringCompare('employeeId'),
      render: (_, record) => (
        <Text small12 className="text-normal">
          {record?.employeeId}
        </Text>
      ),
    },
    userState.currentRoleId !== 2 && {
      title: 'Name',
      dataIndex: 'firstnameEn',
      width: '200px',
      sorter: defaultStringCompare('firstnameEn'),
      render: (key, record) => (
        <Text
          small12
          className="text-normal"
        >{`${record.firstnameEn} ${record.lastnameEn}`}</Text>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'medicalClaimName',
      width: '200px',
      sorter: defaultStringCompare('medicalClaimName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value}
        </Text>
      ),
    },
    {
      title: 'Treatment Date',
      dataIndex: 'effectiveDate',
      width: '130px',
      sorter: defaultStringCompare('effectiveDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amountUse',
      width: '80px',
      align: 'right',
      render: (value) => (
        <Text small12 className="text-normal">
          {value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'requestStatusName',
      width: '150px',
      fixed: 'right',
      sorter: defaultStringCompare('requestStatusName'),
      render: (value) => (
        <Text small12 className="text-normal">
          {value == 'Pending' ? (
            <Tag yellow>Waiting For Approval</Tag>
          ) : value === 'Approved' ? (
            <Tag green>Approved</Tag>
          ) : value === 'Rejected' ? (
            <Tag red>Rejected</Tag>
          ) : value === 'Canceled by Approver' ? (
            <Tag grey>Canceled by Approver</Tag>
          ) : (
            <Tag grey>Canceled by User</Tag>
          )}
        </Text>
      ),
    },
    {
      title: 'Action',
      width: '60px',
      align: 'center',
      fixed: 'right',
      render: (key, record) => (
        <a
          onClick={() => {
            editModalOpen(record);
          }}
        >
          {record.requestStatusName === 'Pending' ? (
            <FormOutlined className="text-primary-blue" />
          ) : (
            <EyeOutlined className="text-primary-blue" />
          )}
        </a>
      ),
    },
  ].filter(Boolean);

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Medical Claim
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <DatePicker
                        style={{ width: '160px' }}
                        defaultValue={moment()}
                        picker="year"
                        format="YYYY"
                        disabledDate={(current) =>
                          current > moment().endOf('year')
                        }
                        onChange={(value) => {
                          onYearFilterChange(value);
                        }}
                      />
                    </Col>
                    <Col flex>
                      <Select
                        defaultValue={'All'}
                        style={{ width: '160px' }}
                        onChange={onStatusTypeFilterChange}
                      >
                        <Select.Option value="All">All Status</Select.Option>
                        <Select.Option value="Approved">Approved</Select.Option>
                        <Select.Option value="Pending">
                          Waiting For Approval
                        </Select.Option>
                        <Select.Option value="Rejected">Rejected</Select.Option>
                        <Select.Option value="Canceled by User">
                          Canceled by User
                        </Select.Option>
                        <Select.Option value="Canceled by Approver">
                          Canceled by Approver
                        </Select.Option>
                      </Select>
                    </Col>
                    {userState?.currentRoleId === 2 && (
                      <Col>
                        <NavLink to={`/medical-claim/request`}>
                          <Button
                            type="primary"
                            width="96px"
                            className="button-primary"
                            icon={<PlusOutlined />}
                          >
                            Create Request
                          </Button>
                        </NavLink>
                      </Col>
                    )}
                    {features?.medicalClaimExportReport && (
                      <Col>
                        <Button
                          loading={isReportDownloading}
                          type="primary"
                          width="96px"
                          className="button-primary"
                          onClick={onExportReport}
                          icon={<DownloadOutlined />}
                        >
                          Export
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {userState.currentRoleId === 2 && (
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24} span={1}>
                        <Text small12 className="text-secondary-red">
                          Remaining value in {moment(new Date()).format('YYYY')}
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {remainingValue} Baht
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row className="card-container" style={{ margin: 'auto' }}>
                      <Col xs={24}>
                        <Text small12 className="text-secondary-red">
                          Used value in {moment(new Date()).format('YYYY')}
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Text sub4 className="text-normal">
                          {usedValue} Baht
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Table
                columns={columns}
                scroll={{
                  x: 300,
                  y:
                    userState.currentRoleId === 2
                      ? 'calc(100vh - 360px)'
                      : 'calc(100vh - 240px)',
                }}
                dataSource={data}
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
      <EditMedicalClaimModal
        open={editModalVisible}
        onCancel={editModalClose}
        onFetch={fetchMedicalClaimHistory}
        record={record}
      />
      <ExportMedicalClaimModal
        open={exportModalVisible}
        onCancel={exportModalClose}
      />
    </div>
  );
};
export default MedicalClaim;
