import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';

export const Select = styled(AntdSelect)`
  .ant-select-item-option-content {
    font-size: 12px;
    line-height: 20px;
    font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
  }
  .ant-select-selection-item {
    font-size: 12px;
    line-height: 20px;
    font-family: Figtree, 'Noto Sans Thai', sans-serif !important;
  }
`;
