import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { FormInput, Input, Select } from '../..';
import { AppContext } from '../../context/AppContext';
import { MasterDataService, ProfileService } from '../../../services/api';

const SkillModal = (props) => {
  const { record, modalVisible, closeModal, userId, getSkills } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [skillList, setSkillList] = useState([]);

  const { userState } = useContext(AppContext);

  const [form] = useForm();

  useEffect(() => {
    getSkillList();
    form.setFieldsValue({
      skillId: record?.skillId,
      description: record?.description,
    });
  }, [record]);

  const getSkillList = async () => {
    MasterDataService.fetchComputerSkillList({}, async ({ data }) => {
      data = data.filter((value) => {
        return value.isActived;
      });
      setSkillList(data);
    });
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (!record.id) {
      const payload = {
        userId: userId,
        updateBy: userId,
        skillId: values?.skillId,
        description: values?.description,
      };
      await ProfileService.computerSkills.addComputerSkills(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getSkills();
          message.success('Skill has been created successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(`Failed to add skill: ${response.data.message}`);
          }
        },
      );
    } else {
      const payload = {
        userId: userId,
        updateBy: userId,
        computerSkillId: record?.id,
        skillId: values?.skillId,
        description: values?.description,
      };
      await ProfileService.computerSkills.editComputerSkills(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getSkills();
          message.success('Skill has been edited successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(`Failed to edit skill: ${response.data.message}`);
          }
        },
      );
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await ProfileService.computerSkills.deleteComputerSkills(
      record.id,
      ({ data }) => {
        setIsLoading(false);
        getSkills();
        message.success('Skill has been deleted successfully');
        closeModal();
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error(`Failed to delete skill: ${response.data.message}`);
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={modalVisible}
      centered
      onCancel={closeModal}
      footer={
        userState?.currentRoleId === 2 && (
          <Row gutter={[16, 16]} justify="space-between">
            {record?.id ? (
              <Col>
                <Button
                  disabled={isLoading}
                  className="button-red"
                  onClick={() => handleDelete()}
                >
                  Delete
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  className="button-outlined"
                  onClick={() => closeModal()}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            )}

            <Col>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          skillId: record?.skillId,
          description: record?.description,
        }}
        requiredMark={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              {userState.currentRoleId === 2
                ? record?.id
                  ? 'Edit Skill'
                  : 'Create Skill'
                : 'Skill Information'}
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 8]} style={{ width: '100%', margin: 0 }}>
            <Col span={24}>
              <FormInput
                title="Skill"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="skillId"
                    rules={[
                      {
                        required: true,
                        message: 'Select skill',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Select id="skillId" placeholder="Select skill">
                        {skillList?.map((skill) => {
                          return (
                            <Select.Option value={skill.id}>
                              {skill.skillType}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.skillType}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Description"
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="description"
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input id="description" placeholder="Enter description" />
                    ) : (
                      <Text small12>{record?.description}</Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
export default SkillModal;
