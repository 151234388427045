import moment from 'moment';
import React from 'react';

export const change0to24 = (hour, format) => {
  // format should be like HH:mm:ss, HH:mm, HH.mm,
  // should use only H,m,s
  if (moment(hour, format).get('hour') === 0) {
    return format
      .replaceAll('HH', '24')
      .replaceAll('m', '0')
      .replaceAll('s', '0');
  }
  return hour;
};

export const formatDaysAndHours = (dateTime) => {
  return (
    <>
      {Math.floor(dateTime / 8)} Day
      {Math.floor(dateTime / 8) > 1 && 's'}
      {dateTime % 8 > 0 ? (
        <>
          {' '}
          {Math.floor(dateTime % 8)} hr
          {Math.floor(dateTime % 8) > 1 && 's'}
        </>
      ) : (
        ''
      )}
    </>
  );
};
