import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  InputNumber,
  TreeSelect,
  Switch,
  Row,
  Col,
} from 'antd';
import { validateSearch } from 'rc-mentions/lib/util';
import Delete from '../../assets/icons/delete.svg';
import ProfileTabHeader from './profileTabHeader';
import DetailBox from './detailBox';

const MainBox = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
`;
const Subbox = styled.div`
  // height: 6.1875rem;
  // border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e3e3e3;
`;
const Datepicker = styled(DatePicker)`
  width: 100%;
  // max-width:14rem;
  // margin-left:2rem;
`;
const Flexbox = styled.div`
  height: 100%;
  width: 100%;
  // background: ${(props) =>
    props.one ? 'blue' : props.two ? 'red' : 'yellow'};
  display: ${(props) => (props.one ? 'flex' : 'block')};
  flex-wrap: wrap;
  align-content: ${(props) => (props.one ? 'center' : 'none')};
  justify-content: ${(props) => (props.one ? 'none' : 'center')};
  box-sizing: border-box;
  flex-direction: ${(props) => (props.one ? 'row' : 'column')};
  // padding-top: 0.6875rem;
  // padding-bottom: 0.6875rem;
  position: relative;
`;
const Bluecircle = styled.div`
  height: ${(props) => (props.small ? '1.375rem' : '2rem')};
  width: ${(props) => (props.small ? '1.375rem' : '2rem')};
  border-radius: 50%;
  background: ${(props) =>
    props.small
      ? 'white'
      : 'linear-gradient(90deg, #013640 -69.09%, #2A8B9D 111.79%)'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;
const Greycircle = styled.div`
  height: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  width: ${(props) => (props.small ? '1.110625rem' : '1.615625rem')};
  border-radius: 50%;
  background: ${(props) => (props.small ? 'white' : '#D2D2D2')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.small ? 'none' : '0.1921875rem')};
`;
const Verticalline = styled.div`
  position: absolute;
  height: 5rem;
  margin-top: -2.3rem;
  margin-left: 0.9375rem;
  width: 0.125rem;
  background: #e1e1e1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
`;
const Horizontalbox = styled.div`
  display: flex;
  @media (max-width: 712px) {
    flex-direction: column;
  }
`;
const Flexformbox = styled.div`
  width: 50%;
  display: flex;
  padding-right: 2rem;

  box-sizing: border-box;
  margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Line = styled.div`
  // margin-top:2.0625rem;
  margin-bottom: 1.5rem;
  height: 0.0625rem;
  background: #e3e3e3;
  width: 100%;
`;
const Label = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 12px;
  font-weight: 400;
  width: 9.59875rem;
  min-width: 9.59875rem;
  color: black;
  @media (max-width: 1424px) {
    width: 100%;
    min-width: none;
  }
`;
const Formitem = styled(Form.Item)`
  width: 100%;
  margin: 0;
`;
const Inputdata = styled(Input)`
  // max-width:14rem;
  width: 100%;
  height: 2rem;
  // margin-left:2rem;
`;
const Headdetail = styled.div`
  font-family: 'Figtree, ' Noto Sans Thai ', sans-serif';
  font-size: 26px;
  font-weight: 600;
  color: #20abc5;
`;
const Text = styled.div`
  font-size: ${(props) => (props.big ? '1.125rem' : '0.875rem')};
  font-weight: ${(props) => (props.big ? '500' : '300')};
  color: ${(props) => (props.grey ? '#737373' : '#232323')};
`;
const Buttonedit = styled(Button)`
  width: 72px;
  height: 30px;
  background: #2d95a8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-item: center;
  color: white;
`;

const FlexBox = styled.div`
  width: 50%;
  display: flex;
  align-items: baseline;
  padding-right: 2rem;

  box-sizing: border-box;
  // margin-bottom: 1.5rem;
  @media (max-width: 1424px) {
    flex-direction: column;
  }
  @media (max-width: 712px) {
    padding-right: 0;
    width: 100%;
  }
`;
const Textdata = styled.div`
  width: 100%;
  font-size: 12px;
`;

class Trainingcourses extends Component {
  state = {
    trainList: this.props.data.trainingCourses?.trainingCourses,
    trainListForShow: this.props.data.trainingCourses?.trainingCourses,
    deletedList: [],
    // isInEditMode: false,
  };
  formRef = React.createRef();

  addNewRow = () => {
    this.setState((prevState) => ({
      trainList: prevState.trainList
        ? [
            ...prevState.trainList,
            {
              trainingDescription: '',
              startDate: '',
              endDate: '',
              expense: '',
            },
          ]
        : [],
    }));
  };

  clickOnDelete(record) {
    this.setState({
      trainList: this.state.trainList.filter((r) => r !== record),
      deletedList: [...this.state.deletedList, record.id],
    });
  }

  onFinish = (values) => {
    this.props.onSubmit(values, this.state.deletedList);
    this.props.changeEditMode();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isInEditMode !== prevProps.isInEditMode) {
      this.setState({
        trainList: this.props.data.trainingCourses?.trainingCourses,
        trainListForShow: this.props.data.trainingCourses?.trainingCourses,
      });
    }
  }

  renderEditView = () => {
    return (
      <MainBox>
        <Form
          id="trainingCourses"
          ref={this.formRef}
          onFinish={this.onFinish}
          autoComplete="off"
        >
          <Form.List name="trainList">
            {(fields, { add, remove }) => {
              fields = [];
              let array = this.state.trainList ? this.state.trainList : [];
              if (array.length >= 0) {
                for (var i = 0; i < array.length; i++) {
                  fields.push({
                    name: i,
                    key: i,
                    fieldKey: i,
                  });
                }
              }
              let removefunc = (a, b) => {
                this.clickOnDelete(a);
                remove(b);
              };
              return (
                <div>
                  {fields?.map((field) => (
                    <MainBox>
                      <Box>
                        <Row gutter={24} style={{ marginBottom: '1rem' }}>
                          <Col span={12}>
                            <div
                              style={{ color: 'black', fontSize: '1.125em' }}
                            >
                              {field.fieldKey + 1}.
                            </div>
                          </Col>

                          <Col span={12} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '0',
                                display: 'flex',
                              }}
                            >
                              <Flexformbox style={{ width: '100%' }}>
                                <Button
                                  style={{
                                    color: '#EB5757',
                                    fontSize: '0.8750em',
                                    border: 'none',
                                  }}
                                  onClick={() => {
                                    removefunc(
                                      array[field.fieldKey],
                                      field.name,
                                    );
                                  }}
                                >
                                  <img src={Delete}></img>&nbsp;Delete
                                </Button>
                              </Flexformbox>
                            </div>
                          </Col>
                        </Row>

                        <Horizontalbox>
                          <Formitem
                            hidden
                            {...field}
                            name={[field.name, 'trainingCourseId']}
                            fieldKey={[field.fieldKey, 'id']}
                            initialValue={array[field.fieldKey].id}
                          ></Formitem>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Training Courses</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'courseName']}
                              fieldKey={[field.fieldKey, 'courseName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Training Course',
                                },
                              ]}
                              initialValue={array[field.fieldKey].courseName}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>
                        <Horizontalbox>
                          <Flexformbox style={{ width: '100%' }}>
                            <Label>Training Description</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'trainingDescription']}
                              fieldKey={[field.fieldKey, 'trainingDescription']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Training Description',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].trainingDescription
                              }
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Start Date</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'startDate']}
                              fieldKey={[field.fieldKey, 'startDate']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Start Date',
                                },
                              ]}
                              initialValue={
                                array[field.fieldKey].startDate == ''
                                  ? ''
                                  : moment(
                                      array[field.fieldKey].startDate,
                                      'YYYY-MM-DD',
                                    )
                              }
                            >
                              <Datepicker
                                format="DD/MM/YYYY"
                                // onChange={(value) => {
                                //   this.onStartDateChange(value, field.fieldKey);
                                // }}
                              />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>End Date</Label>
                            <Form.Item
                              dependencies={[
                                ['trainList', field.fieldKey, 'startDate'],
                                ['trainList', field.fieldKey, 'endDate'],
                              ]}
                              noStyle
                            >
                              {() => {
                                return (
                                  <Formitem
                                    {...field}
                                    name={[field.name, 'endDate']}
                                    fieldKey={[field.fieldKey, 'endDate']}
                                    dependencies={[
                                      [
                                        'trainList',
                                        field.fieldKey,
                                        'startDate',
                                      ],
                                      ['trainList', field.fieldKey, 'endDate'],
                                    ]}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input End Date',
                                      },
                                      () => ({
                                        validator: (_, value) => {
                                          if (
                                            moment(
                                              this.formRef.current.getFieldValue(
                                                [
                                                  'trainList',
                                                  field.fieldKey,
                                                  'startDate',
                                                ],
                                              ),
                                            ) > moment(value)
                                          ) {
                                            return Promise.reject(
                                              'End Date must be greater than Start Date.',
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }),
                                    ]}
                                    initialValue={
                                      array[field.fieldKey].endDate == ''
                                        ? ''
                                        : moment(
                                            array[field.fieldKey].endDate,
                                            'YYYY-MM-DD',
                                          )
                                    }
                                  >
                                    <Datepicker format="DD/MM/YYYY" />
                                  </Formitem>
                                );
                              }}
                            </Form.Item>
                          </Flexformbox>
                        </Horizontalbox>

                        <Horizontalbox>
                          <Flexformbox left>
                            <Label>Institution / Instructor</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'institution']}
                              fieldKey={[field.fieldKey, 'institution']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Institution',
                                },
                              ]}
                              initialValue={array[field.fieldKey].institution}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                          <Flexformbox>
                            <Label>Expense (Baht)</Label>
                            <Formitem
                              {...field}
                              name={[field.name, 'expense']}
                              fieldKey={[field.fieldKey, 'expense']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Expense',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (
                                      isNaN(value) ||
                                      isNaN(parseFloat(value))
                                    ) {
                                      return Promise.reject(
                                        'Please input only number',
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              initialValue={array[field.fieldKey].expense}
                            >
                              <Inputdata />
                            </Formitem>
                          </Flexformbox>
                        </Horizontalbox>
                      </Box>
                    </MainBox>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={this.addNewRow}>
                      Create Courses
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </MainBox>
    );
  };
  renderDefaultView = () => {
    return (
      <MainBox>
        <Box>
          {this.props.data.trainingCourses?.trainingCourses?.map((val, idx) => (
            <div>
              <Subbox
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '0.6875rem',
                  paddingTop: idx !== 0 ? '0.6875rem' : '',
                }}
              >
                <Flexbox one>
                  <div>
                    <Text big>{val.courseName}</Text>
                    <Text grey>{val.institution}</Text>
                    <Text style={{ marginTop: '10px', color: '#232323' }}>
                      {val.trainingDescription}
                    </Text>
                  </div>
                </Flexbox>
                <Flexbox
                  two
                  style={{ justifyContent: 'flex-end', textAlign: 'right' }}
                >
                  <div>
                    <Text grey>
                      {moment(val.startDate, 'YYYY-MM-DD').format(
                        'DD MMM YYYY',
                      )}
                      &nbsp;-&nbsp;
                      {moment(val.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                    </Text>
                    <Text grey>{`${val.expense.toLocaleString(
                      'en-US',
                    )} Baht`}</Text>
                  </div>
                </Flexbox>
              </Subbox>
            </div>
          ))}
        </Box>

        <Horizontalbox /* style={{ marginTop: '1rem' }} */>
          <FlexBox Left>
            <Label>Update Date</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateDatetime"
            >
              <Text grey>
                {this.props.data.trainingCourses?.updateUserInfo?.updateDatetime
                  ? moment(
                      this.props.data.trainingCourses?.updateUserInfo
                        ?.updateDatetime,
                      'YYYY-MM-DD',
                    ).format('DD MMM YYYY')
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
          <FlexBox>
            <Label>Update By</Label>
            <Form.Item
              style={{ width: '100%', margin: '0' }}
              name="updateUserInfo"
            >
              <Text grey>
                {this.props.data.trainingCourses?.updateUserInfo?.updateDatetime
                  ? `${this.props.data.trainingCourses?.updateUserInfo?.firstnameEn} ${this.props.data.trainingCourses?.updateUserInfo?.lastnameEn}`
                  : '-'}
              </Text>
            </Form.Item>
          </FlexBox>
        </Horizontalbox>
      </MainBox>
    );
  };

  render() {
    return (
      <DetailBox>
        {this.props.isInEditMode
          ? this.renderEditView()
          : this.renderDefaultView()}
      </DetailBox>
    );
  }
}
export default Trainingcourses;
