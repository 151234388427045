import { Button, Divider, Form, Modal, Switch, message, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Text, Input, FormInput } from '../../components';
import { useForm } from 'antd/lib/form/Form';

const CreateOrEditSettingsModal = (props) => {
  const {
    createOrEditModalVisible,
    modalMode,
    handleCancelModal,
    settingType,
    selectedRecord,
    fetchDataSource,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isActived, setIsActived] = useState(false);

  const [form] = useForm();

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue({
        ...selectedRecord,
      });
    }
  }, [selectedRecord]);

  const handleAddRecord = (values) => {
    const payload = values;
    settingType.addingApi(
      payload,
      ({ data }) => {
        message.success('Record has been created successfully.');
        fetchDataSource();
        setIsLoading(false);
        form.resetFields();
        handleCancelModal();
      },
      (response) => {
        if (response)
          message.error(`Failed to add record: ${response.data.message}`);
      },
    );
  };

  const handleEditRecord = (values) => {
    const key = settingType.primaryKey;
    const payloadKey = settingType.payloadPrimaryKey;
    const payload = {
      ...values,
    };
    payload[payloadKey] = selectedRecord[key];
    settingType.updateApi(
      payload,
      ({ data }) => {
        message.success('Record has been updated successfully.');
        fetchDataSource();
        setIsLoading(false);
        form.resetFields();
        handleCancelModal();
      },
      (response) => {
        if (response)
          message.error(`Failed to update record: ${response.data.message}`);
      },
    );
  };

  const handleFinishForm = (values) => {
    setIsLoading(true);
    switch (modalMode) {
      case 'create':
        handleAddRecord(values);
        break;
      case 'edit':
        handleEditRecord(values);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      width="450px"
      open={createOrEditModalVisible}
      footer={null}
      onCancel={handleCancelModal}
    >
      <Form form={form} onFinish={handleFinishForm}>
        <Row>
          <Text h4 className="text-normal">
            {modalMode === 'create' ? 'Create Record' : 'Edit Record'}
          </Text>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          {settingType.payloadFields.map((field) => (
            <Col span={24}>
              <FormInput
                title={field.label}
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name={field.key}
                    rules={[
                      {
                        required: true,
                        message: `Please input ${field.label}`,
                      },
                    ]}
                    hidden={!field.label}
                  >
                    <Input
                      disabled={modalMode === 'edit' && field.uneditable}
                    />
                  </Form.Item>
                }
              />
            </Col>
          ))}
          {modalMode === 'edit' && (
            <Col span={24}>
              <FormInput
                title="Status"
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="isActived"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      className="switch-primary"
                    />
                  </Form.Item>
                }
              />
            </Col>
          )}
        </Row>
      </Form>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button className="button-outlined" onClick={handleCancelModal}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          className="button-primary"
          type="primary"
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CreateOrEditSettingsModal;
