import React, { Component, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Row,
  message,
  Form,
  Modal,
  Col,
  Popconfirm,
  Layout,
  Divider,
} from 'antd';
import moment from 'moment';
import { redirectTo } from '../services/redirect';
import { HolidayService } from '../services/api';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { AppContext } from '../components/context/AppContext';

import { Table, Select, FormInput, DatePicker, Input } from '../components';
import { Text } from '../components';
import { FeatureFlags } from '../components/context/FeatureFlagContext';

const { Content } = Layout;

const Holiday = () => {
  const { userState } = useContext(AppContext);
  const { features } = useContext(FeatureFlags);
  const [data, setData] = useState();
  const [holidayForm, setHolidayForm] = useState({
    holidayName: '',
    holidayDate: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const [allYear, setAllYear] = useState();
  const [addHolidayModalVisible, setAddHolidayModalVisible] = useState(false);

  useEffect(() => {
    fetchHoliday();
    fetchYear();
  }, [userState, year]);

  const defaultStringCompare = (property) => (a, b) =>
    a[property].localeCompare(b[property]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'holidayDate',
      width: '120px',
      sorter: defaultStringCompare('holidayDate'),
      render: (value) => (
        <Text small12 className="text-normal">
          {moment(value).format('DD/MM/YYYY')}
        </Text>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'dateName',
      sorter: defaultStringCompare('dateName'),
      render: (name) => (
        <Text small12 className="text-normal">
          {name}
        </Text>
      ),
    },
    features?.holidayDelete && {
      title: 'Action',
      align: 'center',
      width: '80px',
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to delete this holiday？"
          okText="OK"
          cancelText="Cancel"
          okButtonProps={{
            style: {
              backgroundColor: '#004368',
              borderColor: '##004368',
            },
          }}
          onConfirm={() => {
            handleDeleteHoliday(record.id);
          }}
          onCancel={() => {}}
        >
          <DeleteOutlined className="text-secondary-red" />
        </Popconfirm>
      ),
    },
  ].filter(Boolean);

  const fetchYear = () => {
    HolidayService.getAll(({ data }) => {
      setAllYear([...new Set(data.map((r) => r.holidayYear))]);
    });
  };

  const fetchHoliday = () => {
    setIsLoading(true);
    HolidayService.getYear(
      year,
      ({ data }) => {
        setIsLoading(false);
        setData(data);
      },
      (response) => {
        setIsLoading(false);
        if (response) {
          message.error(`Failed to fetch holidays: ${response.data.message}`);
        }
      },
    );
  };

  const handleDeleteHoliday = (id) => {
    setIsLoading(true);
    HolidayService.deleteHoliday(
      { holidayId: id },
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Holiday has been deleted successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          fetchHoliday();
        }, secondsToGo * 1000);
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Failed to delete holiday: ' + response.data.message);
        }
      },
    );
  };

  const showAddHolidayModal = () => {
    setAddHolidayModalVisible(true);
  };

  const closeAddHolidayModal = () => {
    setAddHolidayModalVisible(false);
    setHolidayForm({
      holidayName: '',
      holidayDate: '',
    });
  };

  const handleAddHoliday = () => {
    setIsLoading(true);
    if (moment() > holidayForm.holidayDate) {
      message.warning('Warning: Date is in the past.');
    }
    HolidayService.addHoliday(
      {
        holidayDate: holidayForm.holidayDate.format('YYYY-MM-DD'),
        startDatetime:
          holidayForm.holidayDate.format('YYYY-MM-DD') + ' 09:00:00',
        endDatetime: holidayForm.holidayDate.format('YYYY-MM-DD') + ' 18:00:00',
        dateName: holidayForm.holidayName,
        description: 'วันหยุด',
      },
      ({ data }) => {
        let secondsToGo = 1;
        message.success('Holiday has been created successfully.');
        const timer = setInterval(() => {
          secondsToGo -= 1;
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          fetchHoliday();
        }, secondsToGo * 1000);
        closeAddHolidayModal();
      },
      (response) => {
        if (response && response.status === 400) {
          message.error('Failed to create holiday: ' + response.data.message);
        }
      },
    );
  };

  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Holidays
                  </Text>
                </Col>
                <Col flex="auto">
                  <Row gutter={[8, 8]} justify="end">
                    <Col flex>
                      <Select
                        defaultValue={year}
                        style={{ width: '160px' }}
                        onChange={(value) => {
                          setYear(value);
                        }}
                      >
                        {allYear?.map((item) => (
                          <Select.Option value={item}>{item}</Select.Option>
                        ))}
                      </Select>
                    </Col>
                    {features?.holidayCreate && (
                      <Col>
                        <Button
                          type="primary"
                          width="96px"
                          className="button-primary"
                          onClick={showAddHolidayModal}
                          icon={<PlusOutlined />}
                        >
                          Create Holiday
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                scroll={{ x: 500, y: 'calc(100vh - 240px)' }}
                dataSource={data}
                rowKey="id"
                size="small"
                loading={isLoading}
                pagination={{
                  position: ['bottomRight'],
                  pageSizeOptions: [10, 20, 30, 40],
                  showSizeChanger: true,
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Layout>

      <Modal
        open={addHolidayModalVisible}
        closable
        width={450}
        footer={null}
        onCancel={closeAddHolidayModal}
      >
        <Form>
          <Row>
            <Text h4 className="text-normal">
              Create Holiday
            </Text>
          </Row>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormInput
                title="Holiday Name"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please add holiday name.',
                      },
                    ]}
                  >
                    <Input
                      id="holidayName"
                      placeholder="Name"
                      value={holidayForm.holidayName}
                      onChange={(event) => {
                        setHolidayForm({
                          ...holidayForm,
                          holidayName: event.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Date"
                required
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please add holiday date.',
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Date"
                      defaultValue={''}
                      format="DD/MM/YYYY"
                      value={holidayForm.holidayDate}
                      onChange={(value) =>
                        setHolidayForm({
                          ...holidayForm,
                          holidayDate: moment(value, 'DD/MM/YYYY'),
                        })
                      }
                    />
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Form>
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button className="button-outlined" onClick={closeAddHolidayModal}>
            Cancel
          </Button>
          <Button
            className="button-primary"
            type="primary"
            onClick={handleAddHoliday}
            loading={isLoading}
            disabled={
              holidayForm.holidayName === '' || holidayForm.holidayDate === ''
            }
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Holiday;
