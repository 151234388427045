import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { Row, Form, Divider, message, Button, Modal, Input } from 'antd';
import { ReportService } from '../../services/api';

const Break = styled(Divider)`
  margin: 12px 0;
`;

const ButtonBlock = styled.div`
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  justify-content: end;
  align-items: center;
`;

const HeadDetail = styled.div`
  font-family: 'Kanit';
  font-size: 18px;
  font-weight: 500;
`;

const NormalButton = styled(Button).attrs((props) => ({
  type: 'primary',
}))`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  background-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  border-color: ${(props) =>
    props.grey ? '#C6C6C6 !important' : '#004368 !important'};
  color: ${(props) => (props.grey ? '#737373' : 'white')};
  font-family: Kanit;
  font-style: Regular;
  &:hover {
    background-color: #d77501 !important;
    border-color: #d77501 !important;
  }
  &:active {
    background-color: #e77d00 !important;
    border-color: #e77d00 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid white !important;
    background-color: #f5f5f5 !important;
  }
`;

const ExportMedicalClaimModal = (props) => {
  const medicalClaimFormRef = createRef();
  const { open, onCancel } = props;

  const [isLoading, setIsLoading] = useState(false);

  const onFinishMedicalClaimExport = (value) => {
    setIsLoading(true);
    const payload = value;
    ReportService.fetchMedicalClaimReport(payload, (response) => {
      if (response) message.error(`Error: ${response.data.message}`);
    });
    setIsLoading(false);
    onCancel();
  };

  const year = new Date().getFullYear();

  return (
    <Modal
      width="450px"
      bodyStyle={{
        maxHeight: '800px',
        overflowY: 'auto',
      }}
      open={open}
      centered
      footer={null}
      onCancel={onCancel}
      maskClosable
    >
      <Form ref={medicalClaimFormRef} onFinish={onFinishMedicalClaimExport}>
        <Row>
          <HeadDetail>Export</HeadDetail>
        </Row>
        <Break />
        <Form.Item
          name="year"
          label="Year"
          rules={[
            {
              required: true,
              message: 'Please select year',
            },
          ]}
          initialValue={year}
        >
          <Input placeholder="input year" defaultValue={year} />
        </Form.Item>
        <Break />
        <Row style={{ justifyContent: 'end' }}>
          <ButtonBlock width="100px">
            <Button
              loading={isLoading}
              width="100px"
              onClick={() => medicalClaimFormRef.current.submit()}
            >
              Download
            </Button>
          </ButtonBlock>
        </Row>
      </Form>
    </Modal>
  );
};

export default ExportMedicalClaimModal;
