import React from 'react';
import { Row, Col, Layout } from 'antd';

import { Text } from '../components';

const { Content } = Layout;

const HelpDesk = (props) => {
  const {} = props;
  return (
    <div style={{ width: 'auto' }}>
      <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Content
          style={{
            margin: '32px 20px 0 20px',
            overflow: 'initial',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col flex>
                  <Text h4 className="text-normal">
                    Help Desk
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className="card-container" gutter={[16, 16]}>
                <Col span={24}>
                  <Text sub4 className="text-normal">
                    Issue Reporting If you encounter any problems while using
                    the system, please feel free to get in touch with:
                  </Text>
                </Col>
                <Col span={24}>
                  <Text small12 className="text-normal">
                    Mr. Sahawas Kawapapong (Ray)
                  </Text>
                </Col>
                <Col span={24}>
                  <Text small12 className="text-normay">
                    Phone: +66 841141147
                  </Text>
                </Col>
                <Col span={24}>
                  <Text small12 className="text-norma">
                    Google Chat: sahawas@playtorium.co.th
                  </Text>
                </Col>
                <Col span={24}>
                  <Text small12 className="text-secondary-red">
                    Feel free to reach out if you need further assistance. 😊
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default HelpDesk;
