import { Button, Col, Divider, Form, Modal, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { Checkbox, DatePicker, FormInput, Input, Select } from '../..';
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import { MasterDataService, ProfileService } from '../../../services/api';
import CountryList from '../../../assets/countries.json';

const EducationModal = (props) => {
  const { record, modalVisible, closeModal, userId, getEducation } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isStudying, setIsStudying] = useState(false);
  const [institutionList, setInstitutionList] = useState([]);
  const [educationMajorList, setEducationMajorList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const { userState } = useContext(AppContext);

  const [form] = useForm();

  const getCountryOptions = (search, field) => {
    const searchStr = search ? search : '';
    const filtered = CountryList.filter(
      (country) =>
        country.name
          .trim()
          .toLowerCase()
          .search(searchStr.trim().toLowerCase()) >= 0,
    );
    const countries = filtered?.map((country) => {
      return { value: country.enName };
    });
    setCountryList(countries);
  };

  const fetchInstitutionList = () => {
    MasterDataService.fetchInstitutionList(
      undefined,
      (resp) => {
        resp.data = resp.data.filter((value) => {
          return value.isActived;
        });
        setInstitutionList(resp.data);
      },
      (err) => {
        console.error('Failed to fetch institution list: ', err);
      },
    );
  };

  const fetchEducationMajorList = () => {
    MasterDataService.fetchEducationMajorList(
      undefined,
      (resp) => {
        resp.data = resp.data.filter((value) => {
          return value.isActived;
        });
        setEducationMajorList(resp.data);
      },
      (err) => {
        console.error('Failed to fetch education major list: ', err);
      },
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      majorId: record?.majorId,
      institutionId: record?.institutionId,
      degree: record?.degree,
      country: record?.country,
      grade: record?.grade,
      remark: record?.remark,
      startDate: record?.id
        ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : record?.startDate,
      graduateDate: record?.id
        ? record?.graduateDate
          ? moment(record?.graduateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          : null
        : null,
      isStudying: record?.isStudying,
    });
    setIsStudying(record?.isStudying);
    fetchEducationMajorList();
    fetchInstitutionList();
    getCountryOptions();
  }, [record]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (!record.id) {
      const payload = {
        userId: userId,
        updateBy: userId,
        majorId: values?.majorId,
        institutionId: values?.institutionId,
        degree: values?.degree,
        country: values?.country,
        grade: values?.grade,
        remark: values?.remark,
        startDate: record?.id
          ? moment(values?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          : values?.startDate,
        graduateDate: record?.id
          ? moment(values?.graduateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          : values?.graduateDate,
        isStudying: values?.isStudying,
      };
      await ProfileService.education.addEducation(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getEducation();
          message.success('Education has been created successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(`Failed to add education: ${response.data.message}`);
          }
        },
      );
    } else {
      const payload = {
        userId: userId,
        updateBy: userId,
        educationId: record?.id,
        majorId: values?.majorId,
        institutionId: values?.institutionId,
        degree: values?.degree,
        country: values?.country,
        grade: values?.grade,
        remark: values?.remark,
        startDate: record?.id
          ? moment(values?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
          : values?.startDate,
        graduateDate: record?.id
          ? record?.graduateDate
            ? moment(record?.graduateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : null
          : null,
        isStudying: values?.isStudying,
      };
      await ProfileService.education.editEducation(
        payload,
        ({ data }) => {
          setIsLoading(false);
          getEducation();
          message.success('Education has been edited successfully');
          form.resetFields();
          closeModal();
        },
        (response) => {
          if (response && response.status === 400) {
            setIsLoading(false);
            message.error(`Failed to edit education: ${response.data.message}`);
          }
        },
      );
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await ProfileService.education.deleteEducation(
      record.id,
      ({ data }) => {
        setIsLoading(false);
        getEducation();
        message.success('Education has been deleted successfully');
        closeModal();
      },
      (response) => {
        if (response && response.status === 400) {
          setIsLoading(false);
          message.error(`Failed to delete education: ${response.data.message}`);
        }
      },
    );
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '640px',
        overflowY: 'auto',
      }}
      open={modalVisible}
      centered
      onCancel={closeModal}
      footer={
        userState?.currentRoleId === 2 && (
          <Row gutter={[16, 16]} justify="space-between">
            {record?.id ? (
              <Col>
                <Button
                  disabled={isLoading}
                  className="button-red"
                  onClick={() => handleDelete()}
                >
                  Delete
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  className="button-outlined"
                  onClick={() => closeModal()}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Col>
            )}

            <Col>
              <Button
                loading={isLoading}
                type="primary"
                className="button-primary"
                onClick={() => form.submit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          major: record?.major,
          institutionId: record?.institutionId,
          degree: record?.degree,
          country: record?.country,
          grade: record?.grade,
          remark: record?.remark,
          startDate: record?.id
            ? moment(record?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.startDate,
          graduateDate: record?.id
            ? moment(record?.graduateDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
            : record?.graduateDate
            ? record?.graduateDate
            : null,
          isStudying: record?.isStudying,
        }}
        requiredMark={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text h4 className="text-normal">
              {userState.currentRoleId === 2
                ? record?.id
                  ? 'Edit Education'
                  : 'Create Education'
                : 'Education Information'}
            </Text>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Row gutter={[16, 8]} style={{ width: '100%', margin: 0 }}>
            <Col span={24}>
              <FormInput
                title="Major"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="majorId"
                    rules={[
                      {
                        required: true,
                        message: 'Select major',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Select id="majorId" placeholder="Select major">
                        {educationMajorList?.map((major) => {
                          return (
                            <Select.Option value={major.id}>
                              {major.educationMajorName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.major}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Institution"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="institutionId"
                    rules={[
                      {
                        required: true,
                        message: 'Select institution',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Select
                        id="institutionId"
                        placeholder="Select institution"
                      >
                        {institutionList?.map((institution) => {
                          return (
                            <Select.Option value={institution.id}>
                              {institution.institutionName}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.institution}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Country"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: 'Select country',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Select id="country" placeholder="Select country">
                        {countryList?.map((country) => {
                          return (
                            <Select.Option value={country.value}>
                              {country.value}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.country}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Degree"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="degree"
                    rules={[
                      {
                        required: true,
                        message: 'Select degree',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Select id="degree" placeholder="Select institution">
                        <Select.Option value="High School">
                          High School
                        </Select.Option>
                        <Select.Option value="Bachelor’s Degree">
                          Bachelor’s Degree
                        </Select.Option>
                        <Select.Option value="Master’s Degree">
                          Master’s Degree
                        </Select.Option>
                        <Select.Option value="Doctoral Degree">
                          Doctoral Degree
                        </Select.Option>
                        <Select.Option value="Certificate / Degree Obtained">
                          Certificate / Degree Obtained
                        </Select.Option>
                      </Select>
                    ) : (
                      <Text small12 className="text-normal">
                        <Text small12 className="text-normal">
                          {record?.degree}
                        </Text>
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Grade"
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="grade"
                    rules={[
                      () => ({
                        validator: (_, value) => {
                          function containsOnlyNumbers(str) {
                            return /^\d+(\.\d+)?$/.test(str);
                          }
                          if (!containsOnlyNumbers(value)) {
                            return Promise.reject('Allow only numeric grade');
                          } else if (value < 0 || value > 4) {
                            return Promise.reject(
                              'Grade is valid between 0 and 4',
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input id="grade" placeholder="Enter grade" />
                    ) : (
                      <Text small12>{record?.grade}</Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Start Date"
                required
                component={
                  <Form.Item
                    style={{ margin: '0' }}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: 'Select start date',
                      },
                    ]}
                  >
                    {userState?.currentRoleId === 2 ? (
                      <DatePicker
                        format="DD/MM/YYYY"
                        id="startDate"
                        placeholder="Select start date"
                      />
                    ) : (
                      <Text small12 className="text-normal">
                        {record?.startDate
                          ? moment(record?.startDate).format('DD MMM YYYY')
                          : '-'}
                      </Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
            <Col span={12}>
              <FormInput
                title="Graduation Date"
                component={
                  <Col style={{ padding: 0 }}>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="graduateDate"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (
                              moment(form.getFieldValue('startDate')) >
                              moment(value)
                            ) {
                              return Promise.reject(
                                ' Graduation date must be greater than start date.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {userState?.currentRoleId === 2 ? (
                        <DatePicker
                          disabled={isStudying}
                          format="DD/MM/YYYY"
                          id="graduateDate"
                          placeholder="Select graduation date"
                        />
                      ) : (
                        <Text small12 className="text-normal">
                          {record?.graduateDate
                            ? moment(record?.graduateDate).format('DD MMM YYYY')
                            : '-'}
                        </Text>
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ margin: '0' }}
                      name="isStudying"
                      valuePropName="checked"
                    >
                      <Checkbox
                        id="isStudying"
                        onChange={(e) => {
                          setIsStudying(e.target.checked);
                          form.setFieldValue('graduateDate', null);
                        }}
                      >
                        <Text small12>Currently studying</Text>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                }
              />
            </Col>
            <Col span={24}>
              <FormInput
                title="Remark"
                component={
                  <Form.Item
                    style={{ width: '100%', margin: '0' }}
                    name="remark"
                  >
                    {userState?.currentRoleId === 2 ? (
                      <Input id="remark" placeholder="Enter remark" />
                    ) : (
                      <Text small12>{record?.remark}</Text>
                    )}
                  </Form.Item>
                }
              />
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
export default EducationModal;
